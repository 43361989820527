export const turnOffButton = (element) => {
    if (!element) {
      return 
    }
    element.style.opacity = 0.5
    element.disabled = true
  }
  
export const turnOnButton = (element) => {
    if (!element){
      return
    }
    element.style.opacity = 1.0
    element.disabled = false
  }