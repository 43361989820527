import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Dropdown } from "react-bootstrap";

import Pickup from "../../components/Pickup/Pickup";
import PickupDate from "../../components/PickupDate/PickupDate";
import TrackOrder from "../../components/TrackOrder/TrackOrder";
import TrackOrderNow from "../../components/TrackOrderNow/TrackOrderNow";
import DeliveryModal from "../DeliveryModal/DeliveryModal";
import { useHistory, useLocation } from "react-router-dom";
import cartImg1 from "../../assets/svgs/e_cart.svg";
import "./Rightbar.css";
import "../../assets/css/modal.css";
import arrowup from "../../assets/svgs/arrow-up.svg";
import { getOutletInformation} from "../../actions/autocompleteActions";
import { useSelector, useDispatch } from "react-redux";
import CartRender from "./CartRender";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PopUp from "../Modals/Popup";
import OutletInfo from "./OutletInfo"; //OUTLET COMPONENT FOR DETAILS REGARDING DELIVERY CHARGES
import { getSelectedOutlet } from "./outletFunctions";
import OrderPlaced from "../OrderPlaced/OrderPlaced";
import Paynow from "../PaymentMethod/Paynow";

import "../../assets/css/form.css"

import {
  clearCart,
  clearPayment,
  // clearCartSingle,
  placeOrder,
  getTaxes,
  submitOrder,
  updateOrder,
  submitPayment,
  getPreparationTime
} from "../../actions/productActions";
import PaymentMethod from "../PaymentMethod/PaymentMethod";




const RightSidebar = (props) => {
  const [showCartTotal, setShowCartTotal] = useState(false);
  const show = props.show;
  const [orderPlaced, setorderPlaced] = useState(true);

  //STATE VARIABLES TO SET THE DEFAULTS THE FIRST TIME USER OPENS UP THE SIDEBAR
  const hideDelivery = props.business_location ? props.business_location[0].hide_delivery_for_web : 0
  const hidePickup = props.business_location ? props.business_location[0].hide_pickup_for_web : 0
  const [method, setMethod] = useState(1);
  const [methodText, setMethodText] = useState("Delivery");
  //----------------------------------------------------------------------------

  const [address, setAddress] = useState(null);
  const [outlate, setOutlate] = useState(null);
  const [load, setLoad] = useState(false);
  const [taxload, setTaxLoad] = useState(false);
  const [tax, setTax] = useState(null);
  const [methodToggle, setToggle] = useState(false) //Remove_Later
  const [showPickup, setShowPickup] = useState(false);
  const [showPickupDate, setShowPickupDate] = useState(false);
  const [showtrackOrder, setShowTrackOrder] = useState(false);
  const [showTrackNow, setShowTracknow] = useState(false);
  const [order, setOrder] = useState([]);
  const history = useHistory();
  const [showDelivery, setShowDelivery] = useState(false);
  const [lshow, setLshow] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [cartData, setCartData] = useState(null);
  const [SRVChargesRate, setSRVChargesRate] = useState(0);

  //ORDER PLACED STATES *****************************************
  const [bshowOrderSuccess, setShowOrderSuccess] = useState(false)

  //PAYMENT METHOD STATES ***************************************
  const [bShowPayment, setShowPayment] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [bShowPaynow, setShowPaynow] = useState(false)
  const [paynowNumber, setPaynowNumber] = useState(localStorage.paynow_number === "null" ? null : localStorage.paynow_number)
  const [amountRoundingMethod, setAmountRoundingMethod] = useState(localStorage.amount_rounding_method === undefined ? null : localStorage.amount_rounding_method)

  //OUTLETS AND DELIVERY CHARGE STATES **************************
  const [bShowOutlet, setShowOutlet] = useState(false) //USED TO SHOW THE OUTLET MODAL FOR DELIVERY
  
  const [deliveryInformation, setDeliveryInformation] = useState({
    deliveryCharge:0.0,
    deliveryOutName:"",
  })

  const [estPrepTime,setEstPrepTime] = useState(props.est_prep_time !== undefined && props.est_prep_time !== null ? props.est_prep_time[0] : undefined)
  // const estPrepTime = undefined;
  // const estPrepTime = props.est_prep_time !== undefined ? props.est_prep_time[0] : null
  // const estPrepTimeHours = null
  const estPrepTimeHours = estPrepTime !== undefined ? props.est_prep_time[0].est_prep_time_hours : null
  const estPrepTimeMinutes = estPrepTime !== undefined ? props.est_prep_time[0].est_prep_time_minutes : null

  console.log(bshowOrderSuccess)

  console.log(props.payment)

  console.log(amountRoundingMethod);

  // round off to the nearest

  const amount_rounding = (number, multiple = 0) => {

    number = parseFloat((number.toFixed(2)) + '0'); // cut off extra value.

    let rounded_number = number // input value

    let nearestTo = parseFloat(multiple); // nearest to eg. 0.05

    if(multiple > 0) {

        let x = parseFloat((number.toFixed(2)) + '0'); // input value

        rounded_number = (nearestTo) * Math.round(x/nearestTo)

    }

    var output = {
        number: parseFloat((rounded_number.toFixed(2)) + '0'),
        diff: parseFloat(((rounded_number - number).toFixed(2)) + '0')
    }

    return output;
  }

  useEffect(()=>{
    if (props.show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [props.show])

  useEffect(()=>{
    if (hideDelivery === 1) {
      setMethod(2)
      setMethodText("Pickup")
    } else {
      setMethod(1)
      setMethodText("Delivery")
    }
  }, [hideDelivery])

  useEffect(() => {
    
    let outlet = props.outlates?.business_location?.filter(outlates => {
      return outlates.id === parseInt(localStorage.outlate)
    })
    console.log('outletoutletoutlet', outlet)
    if (outlet){
      if (outlet.length > 0) {
        let oldOrder = order
        oldOrder["out_id"] = outlet[0].id
        oldOrder["out_name"] = outlet[0].name
        setOrder(oldOrder)
      }
    }
  }, [props.outlates])

  // useEffect(() => {
  //   if (
  //     props.loading === false &&
  //     props.add_to_cart &&
  //     props.add_to_cart.data &&
  //     Object.keys(props.add_to_cart.data).length === 0
  //   ) {
  //     props.updateCart(null);
  //   }

  //   if (
  //     props.loading === false &&
  //     !orderPlaced &&
  //     props.order &&
  //     props.order.success &&
  //     Object.keys(props.order.data).length > 0
  //   ) {
  //     setorderPlaced(true);

  //     props.closeRightbar();
  //     history.push("/payment/" + props.order.data.id);
  //   }

  // }, [history, orderPlaced, props, method]);

  // useEffect(() => {
  //   setToggle(!methodToggle)
  //   localStorage.removeItem("date")
  //   localStorage.removeItem("time")
  //   order.date = undefined
  //   order.time = undefined
  // }, [method])
  
  useEffect(() => {
    if (!load) {
      props.getTaxes();
      // props.check_dinein_detail();
      setLoad(true);
    }

    if (
      props.loading === false &&
      props.tax !== null &&
      taxload === false
    ) {
      setTax(props.tax);
      setTaxLoad(true);
    }


    // console.log(props.tax);

  }, [load, props, order, taxload]);

  useEffect(()=>{
    console.log("ORDER SUCESSSSSSSSSSSSSSSSSSSS")
    //PREVENT SUCCESS MODAL FROM SHOWING TWICE IF ON THE PAYMENT PAGE
    if (props.payment && ( paymentMethod === "cash" || paymentMethod ==="paynow" )&& history.location.pathname !== "/payment") { 
      clearCart()
      setShowOrderSuccess(true)
    }
  }, [props.payment])

  useEffect(() => {
    let tempCartData = JSON.parse(localStorage.getItem("cart"));
    let gstRate = 0;
    let srvChargeRate = 0;

    if (tax) {
      tax.forEach(eachTax => {

        switch (eachTax.name) {
          case "GST":
            gstRate = eachTax.amount;
            break;
          // case "Service Charge":
          //   srvChargeRate = eachTax.amount;
          //   setSRVChargesRate(srvChargeRate);
          //   break;
          default:
            break;
        }

      });
    }

    if (tempCartData?.length) {
      let arrayTotal = tempCartData.reduce(function (acc, val) {
        return parseFloat(acc) + parseFloat(val.price);
      }, 0);


      tempCartData.sub = arrayTotal;

      // console.log("SERVICECHARGE RATE");
      // console.log(SRVChargesRate);

      if (method === 1) {

        arrayTotal = parseFloat(arrayTotal); //TOTAL PRICE OF SELECTED FOOD ITEMS

        //let srvCharge = (arrayTotal) * (srvChargeRate / 100);
        let srvCharge = deliveryInformation.deliveryCharge ? parseFloat(deliveryInformation.deliveryCharge) : 0 //DELIVERYINFORMATION FROM OUTLETINFO.JS
        let gst = (arrayTotal) * (gstRate / 100);

        let billTotal = arrayTotal + srvCharge + gst;

        tempCartData.srvCharge = srvCharge;
        tempCartData.gst = gst;
        tempCartData.amountRoundingDiff = 0;
        tempCartData.sum = parseFloat(billTotal).toFixed(2);
        tempCartData.sum_wo_tax = parseFloat(arrayTotal).toFixed(2);
      } else {
        console.log(amountRoundingMethod);
        arrayTotal = parseFloat(arrayTotal);
        let gst = (arrayTotal) * (gstRate / 100);

      let billTotal = arrayTotal + gst;
      let finalBillTotal = amount_rounding(billTotal, amountRoundingMethod);
      console.log(amount_rounding(billTotal, amountRoundingMethod));
      tempCartData.srvCharge = 0;
      tempCartData.gst = gst;
      tempCartData.amountRoundingDiff = parseFloat(finalBillTotal.diff).toFixed(2);
      tempCartData.sum = parseFloat(finalBillTotal.number).toFixed(2);
      tempCartData.sum_wo_tax = parseFloat(arrayTotal).toFixed(2);
      }

    }

    setCartData(tempCartData);
    console.log(tempCartData)

    var serviceFee = [];
    serviceFee.push({
      'cart_count': tempCartData?.length,
      'd_charge': (tempCartData?.srvCharge?tempCartData.srvCharge:0).toFixed(2),
      'gst_charge': (tempCartData?.gst?tempCartData.gst:0).toFixed(2),
      'cart_sum_price': (tempCartData?.sum)?tempCartData?.sum:0,
      'cart_sum_wo_tax_price': (tempCartData?.sum_wo_tax)?tempCartData?.sum_wo_tax:0,
    });

    localStorage.setItem('serviceFee', JSON.stringify(serviceFee));

    // setCartData(JSON.parse(localStorage.getItem("cart")));
    if (
      props.loading === false &&
      props.add_to_cart &&
      props.add_to_cart.data &&
      Object.keys(props.add_to_cart.data).length === 0
    ) {
      props.updateCart(null);
    }

    if (
      props.loading === false &&
      !orderPlaced &&
      props.order &&
      props.order.success &&
      Object.keys(props.order.data).length > 0
    ) {
      setorderPlaced(true);

      props.closeRightbar();
      history.push("/payment/" + props.order.data.id);
      // history.push("/payment/" + props.order.data.id);
    }
  }, [history, orderPlaced, props, tax, method, deliveryInformation.deliveryCharge, amountRoundingMethod]);

  
  useEffect(()=>{ //WHEN ADDRESS FROM DELIVERY MODAL CHANGES(WHEN DATA FROM ADDRESS IS SUPPLIED), TRIGGER THIS USE EFFECT
   


    // UNCOMMENT LATER***************************************************************************************
    // if (props.auth.isAuthenticated){ //CHECK IF USER IS LOGGED IN TO AVOID RUN-TIME ERROR
    //   const userDetails = JSON.parse(props.auth.user).data.user 
      
    //   if (address) {
    //     props.getOutletInformation({
    //       "latitude": address.latitude,
    //       "longtitude":  address.longtitude,
    //       "full_address": address.full_address,
    //       "customer_name": userDetails.fname,
    //       "customer_phone": userDetails.contactNo,
    //       "customer_remark": ""
    //     })
    //   }
    // }
    console.log(address)

    if (address) {
      if (props.auth?.isAuthenticated){
        const userData = JSON.parse(localStorage.headers).data.user
        console.log(userData)
        props.getOutletInformation({
          "latitude": address.latitude,
          "longtitude":  address.longtitude,
          "full_address": address.full_address,
          "customer_name": userData.fname, //CHANGE TO REAL NAME LATER,
          "customer_phone": userData.contactNo, //CHANGE TO REAL PHONE NUMBER LATER,
          "customer_remark": address.note
        })
      }
    }
    
  }, [address, props.auth.isAuthenticated]) 

  useEffect(()=>{ //WATCH FOR IF CART DATA CHANGES
    // setDeliveryCharge(cartData.)
    console.log('CHECK cartData', cartData)
    if(cartData !== null){
      setEstPrepTime(props.est_prep_time !== undefined && props.est_prep_time !== null ? props.est_prep_time[0] : undefined)
    }
    if(cartData == null){
      setEstPrepTime(undefined)
    }
  }, [cartData])
  
  const location = useLocation()
  useEffect(()=>{
    props.getPreparationTime();
  
  },[])


  // console.log(order.time);
  const clearCart = () => {
    props.clearCart();
    // setEstPrepTime(null);
    //  localStorage.removeItem("order_type");
    // localStorage.removeItem("products");
    localStorage.removeItem("cart");
    localStorage.removeItem("cartID");
  };

  // const cartId = () => {
  //   if (!props.cartData) {
  //     return false;
  //   }

  //   let cartString = "";
  //   let cdata = props.cartData.cart;
  //   if (cdata && Object.keys(cdata).length > 0) {
  //     Object.keys(cdata).map((key, index) => {
  //       cartString += cdata[key].id + ",";
  //     });
  //     return cartString;
  //   } else {
  //     return false;
  //   }
  // };

  const cartId = () => {
    if (!cartData) {
      return false;
    }

    let cartString = "";
    let cdata = cartData;

    if (cdata && Object.keys(cdata).length > 0) {
      Object.keys(cdata).map((key, index) => {
        if (!isNaN(key)) {
          cartString += cdata[key].id + ",";
        }
      });
      return cartString;
    } else {
      return false;
    }
  };

  const ProcessCheckOut = () => {
    // console.log("----------------------------------------------------");
    // console.log(order);
    let cart_id = cartId();

    if (!cart_id) {
      setPopupText("Your cart is empty!");
      setLshow(true);
      return;
    }

    if (!localStorage.login) {
      setPopupText("Login is required to make payment");
      setLshow(true);
      return;
    }

    if (!paymentMethod && method === 2) {
      setPopupText("Please select a payment method!")
      setLshow(true);
      return 
    }

    if (localStorage.login && localStorage.cart) {
      if (!localStorage.d_address && method === 1) {
        setPopupText("Select Address!");
        setLshow(true);
        return;
      }
      if (!localStorage.outlate && method === 2) {
        setPopupText("Select Outlet!");
        setLshow(true);
        return;
      }
      if (!order?.time && !localStorage.date && method===2) {
        setPopupText("Select time and date!");
        setLshow(true);
        return;
      } if (!deliveryInformation.deliveryOutName && method===1) {
        setPopupText("Select delivery Outlet!");
        setLshow(true);
        return;
      }

      let products = cartData?.map((product, index) => {
        return (
          {
            product_type: "single",
            unit_price: product.product_price,
            line_discount_type: '',
            line_discount_amount: '',
            item_tax: 0.00, //fix
            tax_id: "", //fix
            sell_line_note: "", //fix
            modifier: (product.variation_id).split(','),
            modifier_price: product.variation_price,
            modifier_set_id: (product.variation_set_id).split(','),
            modifier_quantity: (product.variation_quantity).split(','),
            product_id: product.product_id,
            variation_id: product.product_variation_id,
            enable_stock: 0,
            quantity: parseFloat(product.quantity),
            product_unit_id: product.unit_id,
            base_unit_multiplier: 1,
            unit_price_before_discount: product.unit_price,
            unit_price_inc_tax: product.unit_price,
            weight: "",
            printed: product.printed
          });
      });

      const orderData = {
        business_id: 2,
        token: "",
        user_id: 2,
        location_id: 2,
        sub_type: "",
        pax: 2,
        seated: 1,
        contact_id: 2, //fix
        search_product: "",
        pay_term_number: "",
        pay_term_type: "",
        res_table_id: [2],
        res_waiter_id: "",
        sell_price_tax: "includes", //fix
        type: "sell", //fix temp
        type_for_api: "Dinein",
        products: products,
        discount_type: "percentage", //fix
        discount_amount: 0,
        additional_discount_method: "",
        service_charges: (SRVChargesRate / 100),
        tax_rate_id: '',   //justin check
        tax_calculation_amount: '', //fix
        rp_redeemed: 0, //fix
        rp_redeemed_amount: 0, //fix
        shipping_details: "", //fix
        shipping_address: "", //fix
        shipping_status: "", //fix
        delivered_to: "", //fix
        shipping_charges: 0.00,//fix
        advance_balance: 0.0000,//fix
        payment: [],
        sale_note: "", //fix
        staff_note: "", //fix
        change_return: 0, //received - total
        additional_notes: "", //fix
        is_suspend: 0,//fix
        recur_interval: 1,//fix
        recur_interval_type: "days",//fix
        recur_repetitions: "",//fix
        subscription_repeat_on: "", //fix
        size: "all",//fix
        is_enabled_stock: "", //fix
        is_credit_sale: 0, //fix
        total_before_tax: cartData.sub,
        final_total: cartData.sum,
        discount_type_modal: "percentage", //justin check
        order_tax_modal: "",//justin check
        shipping_details_modal: "",//fix
        shipping_address_modal: "",//fix
        shipping_charges_modal: 0.00,//fix
        shipping_status_modal: "",//fix
        delivered_to_modal: "",//fix
        status: "draft",//fix
        pending_order: 1,
        sent: 0
      }

      localStorage.setItem("time", order?.time);
      localStorage.setItem("order_type", method);
      localStorage.setItem("products", JSON.stringify([products]));
      // localStorage.removeItem("cart");
      // localStorage.removeItem("cartID");
    }
    // console.log("Process");

    if (method === 1) { //DELIVERY CHECK OUT
      const userData = JSON.parse(localStorage.headers).data.user
      if (address) {
        let data = {
          d_address: JSON.stringify(address),
          cart_id: cartId(),
          order_type: 1
        };

        localStorage.setItem("deliveryInformation", 
        JSON.stringify({
          ...deliveryInformation, 
          customerLat: address.latitude,
          customerLong: address.longtitude,
          customerAddress: address.full_address,
          customerName: userData.fname,
          customerPhone: userData.contactNo,
          note: address.note
        }))
        props.placeOrder(data);
        setorderPlaced(false);
        console.log("delivery");
        console.log("ProcessCheckOut");

        props.closeRightbar();
        history.push("/payment");
      } else {
        setPopupText("Select address and time!");
        setLshow(true);
      }
      
      
      // console.log("delivery");
      // console.log("ProcessCheckOut");

      return;

    } else if (order.out_name) { //PICK UP CHECK OUT
      let data = {
        ...order,
        cart_id: cartId(),
      };

      props.placeOrder(data);
      setorderPlaced(false);
      // console.log("pickup");
      // console.log("ProcessCheckOut");

      props.closeRightbar();
      

      if (paymentMethod === "cash" || paymentMethod === "paynow") {

        let user = JSON.parse(localStorage.headers).data.user

        let products = cartData?.map((product, index) => {
          return (
            {
              product_type: "single",
              unit_price: product.product_price,
              line_discount_type: '',
              line_discount_amount: '',
              item_tax: 0.00, //fix
              tax_id: "", //fix
              sell_line_note: "", //fix
              modifier: (product.variation_id).split(','),
              modifier_price: product.variation_price,
              modifier_set_id: (product.variation_set_id).split(','),
              modifier_quantity: (product.variation_quantity).split(','),
              product_id: product.product_id,
              variation_id: product.product_variation_id,
              enable_stock: 0,
              quantity: parseFloat(product.quantity),
              product_unit_id: product.unit_id,
              base_unit_multiplier: 1,
              unit_price_before_discount: product.unit_price,
              unit_price_inc_tax: product.unit_price,
              weight: "",
              printed: product.printed
            });
        });

        console.log(cartData)

        let date = new Date()
        date = new Date(date.toLocaleString("en-us", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}))
        let currentDate = date.toISOString().split("T")[0]
            

        var submit_data = {
          "business_id": localStorage.business_id,
          "user_id": user?.id,
          "location_id": localStorage.location_id,
          "sub_type": "",
          "contact_id": user?.contact_id,
          "search_product": "",
          "pay_term_number": "",
          "pay_term_type": "",
          "res_table_id": "",
          "res_waiter_id": "",
          "sell_price_tax": "includes",
          "type": "sell",
          "type_for_api": "Pickup",
          "products": products,
          "discount_type": "percentage",
          "discount_amount": 0,
          "additional_discount_method": '',
          "service_charges": 0,
          "tax_rate_id": 1,
          "tax_calculation_amount": 7,
          "rp_redeemed": 0,
          "rp_redeemed_amount": 0,
          "shipping_details": "",
          "shipping_address": "",
          "shipping_status": "",
          "delivered_to": "",
          "shipping_charges": 0,
          "advance_balance": 0,
          "payment": [
              {
                  "payment_id": "",
                  "amount": 0.0,
                  "method": paymentMethod === "paynow" ? "card" : "cash",
                  "card_type": paymentMethod === "paynow" ? "paynow" : "credit"
              }
          ],
          "sale_note": "",
          "staff_note": "",
          "change_return": 0,
          "additional_notes": "",
          "is_suspend": 0,
          "recur_interval": 1,
          "recur_interval_type": "days",
          "recur_repetitions": "",
          "subscription_repeat_on": "",
          "size": "all",
          "is_enabled_stock": "",
          "is_credit_sale": 0,
          "total_before_tax": parseFloat(cartData.sum_wo_tax),
          "final_total": parseFloat(cartData.sum_wo_tax) + parseFloat(cartData.gst),
          "amount_rounding_method": amountRoundingMethod,
          "round_off_amount": cartData.amountRoundingDiff,
          "discount_type_modal": "percentage",
          "discount_amount_modal": "0",
          "order_tax_modal": "",
          "shipping_details_modal": "",
          "shipping_address_modal": "",
          "shipping_charges_modal": 0,
          "shipping_status_modal": "",
          "delivered_to_modal": "",
          "status": "final",
          "stripe_token": "",
          "first_name": "",
          "last_name": "",
          "gender": user.gender === "Male" ? 1 : 0,
          "billing_address": "",
          "city": "",
          "zip_code": "",
          "country": "",
          "phone": user?.contactNo,
          "email": user?.email,
          "accept_term_and_cond": 1,
          "occasional_offers": 0, //CHANGE LATER
          "d_address": "",
          "d_date": currentDate , // {* CHANGE LATERS}
          "d_time": data.time,
          "outlet": data.out_id,
          "pickup_completed": 0,
          "delivery_charges" : 0.0
        };
        
        props.submitPayment(submit_data)


      } else {
        history.push("/payment");
      }
      
      // return;

    } else {
      setPopupText("Select Outlet and time!");
      setLshow(true);
    }


  };
  const changeMethod = (key) => {
    if (key === 1) {
      setMethodText("Delivery");
    } else {
      setMethodText("Pick Up");
    }
    setMethod(key);

    localStorage.setItem("order_type", key);
  };
  const showPickFunction = () => {
    // console.log("____Show pick up function_______");
    setShowPickup(true);
  };
  const pickupDateFunction = () => {
    setShowPickupDate(true);
    setShowPickup(false);
  };
  const showTrackOrderNowFunction = () => {
    setShowTrackOrder(false);
    setShowTracknow(true);
  };

  const trackOrderPage = () => {
    setShowTracknow(true);
    history.push("/track-order");
  };

  const trackOrderFunction = () => {
    //console.log("____Show trackOrderFunction_______");
    setShowPickupDate(false);
    setShowTrackOrder(true);
  };

  //FUNCTION FOR MODAL POPUP(DELIVERY)
  const showDelivary = () => {
    setShowDelivery(true);
  };
  //----------------------------------


  //FUNCTIONS FOR MODAL POPUP(PICKUP)
  const showOutlet = () => {
    setShowOutlet(true)
  }

  const closeOutlet = () => {
    setShowOutlet(false)
  }
  //----------------------------------
  

  // const PlaceOrders = () => {
  //   console.log("some data");
  //   console.log(order);

  //   setShowPickupDate(false);
  //   // let data = {
  //   //   ...order,
  //   //   cart_id: cartId(),
  //   // };

  //   // props.placeOrder(data);
  // };

  return (
    <div>
      <PopUp
        text={popupText}
        show={lshow}
        closeModal={() => setLshow(false)}
      />
      <DeliveryModal
        showOutlet={showOutlet}
        address={address}
        show={showDelivery}
        loginRef={props.loginRef}
        setAddress={setAddress}
        closeModal={() => setShowDelivery(false)}
        showPickupDate={() => pickupDateFunction()}
      />

      <Pickup
        setPaynowNumber={setPaynowNumber}
        setOrder={setOrder}
        order={order}
        show={showPickup}
        closeModal={() => setShowPickup(false)}
        showPickupDate={() => pickupDateFunction()}
        setPaymentMethod={setPaymentMethod}
        setAmountRoundingMethod={setAmountRoundingMethod}
      />

      <PickupDate
        setOrder={setOrder}
        order={order}
        show={showPickupDate}
        method={method}
        address={address}
        closeModal={() => setShowPickupDate(false)}
        setShowPayment={setShowPayment}
      />

      <TrackOrder
        show={showtrackOrder}
        closeModal={() => setShowTrackOrder(false)}
        trackOrderNow={() => showTrackOrderNowFunction()}
      />

      <OutletInfo 
      showOutlet={bShowOutlet} 
      closeOutlet={closeOutlet}
      showPickupDate={() => pickupDateFunction()}
      setDeliveryInformation={setDeliveryInformation}
      deliveryInformation={deliveryInformation}
      />

      <PaymentMethod
      showPayment={bShowPayment}
      setPaymentVisible={setShowPayment}
      setPaymentMethod={setPaymentMethod}
      setAmountRoundingMethod={setAmountRoundingMethod}
      setShowPaynow={setShowPaynow}
      paynowNumber={paynowNumber}
      outletInfo={order}
      />

      <Paynow showPaynow={bShowPaynow} setShowPaynow={setShowPaynow} paynowNumber={paynowNumber}/>

      <TrackOrderNow
        show={showTrackNow}
        closeModal={() => setShowTracknow(false)}
        trackOrder={() => trackOrderPage()}
      />

      <OrderPlaced
        clearPayment={props.clearPayment}
        show={bshowOrderSuccess}
        closeModal={() => setShowOrderSuccess(false)}
        TrackOrder={()=> setShowTracknow(true)}
      />

      <div className={`modal_form mycart-dropdown ${show ? "open" : ""}`} id="myCart">
        <div className="head">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle id="dropdown-item-button" className="btn  dropdown-toggle border-0" style={{ backgroundColor: "red" }}>
              {methodText}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ paddingRight: '0', backgroundColor: "black" }}>
              
              { hideDelivery === 0 ?
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => changeMethod(1)}
                  className={`${hidePickup === 0 ? 'border-bottom' : ''}`} style={{ backgroundColor: "black", color: "white" }}
                >
                  Delivery
                </Dropdown.Item> :
                ""

              }

              { hidePickup === 0 ?
                <Dropdown.Item 
                  eventKey="2" 
                  onClick={() => changeMethod(2)} 
                  style={{ maxWidth: '2px', backgroundColor: "black", color: "white" }}
                >
                  Pick Up
                </Dropdown.Item> :
                ""

              }
              
            </Dropdown.Menu>
          </Dropdown>
          <h2 style={{ paddingTop: '4%' }}>Your cart</h2>
          <div className="action">
            <p className="close" onClick={props.closeRightbar}>
              <span aria-hidden="true">×</span>
            </p>
          </div>
        </div>
        <div className="is" id={`${method === 2 && estPrepTime !== undefined ? 'pickup' : 'delivery'}`}>
        {/* <div className={`is`}> */}
          {method === 1 ? (
            <div className="panel-ember">
              <div className="form-group">
                <label className="control-label">Deliver to</label>

                <span
                  className="btn secondry d-block f14"
                  onClick={() => showDelivary()}
                  data-testid="select_credentials"
                >
                  {address
                    ? address.full_address
                    : " Add my address"}
                  <i className="fa fa-angle-right right"></i>
                </span>
                </div>

                <div className="form-group">
                <label className="control-label">Select outlet</label>

                <span
                  
                  style={ address && props?.auth?.isAuthenticated
                  ? {opacity:1.0, cursor:"pointer", pointerEvents:"auto"}
                  : {opacity:0.4, cursor:"not-allowed", pointerEvents:"none"}
                  }
                  className="btn secondry d-block f14"
                  onClick={() => {
                    
                    showOutlet()
                    
                  }}
                >
                  {deliveryInformation.deliveryOutName ? deliveryInformation.deliveryOutName : "Select your outlet"}
                  <i className="fa fa-angle-right right"></i>
                </span>
                </div>


              <div className="form-group no">
                <label className="control-label">Delivery Time</label>
                <div className="row xsm">
                  <div className="col-12">
                    <span
                      className="btn secondry d-block f14"
                      style={{
                        pointerEvents:"none",
                        cursor:"pointer"
                      }}
                    >
                      Now
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="panel-ember">
              <div className="form-group">
                <label className="control-label">Select outlet</label>
                <span
                  className="btn secondry d-block f14"
                  onClick={() => showPickFunction()}
                >
                  { localStorage.out_name ? localStorage.out_name : 
                    order.out_name ? order.out_name : "Select Outlet"}
                  <i className="fa fa-angle-right right"></i>
                </span>
              </div>

              <div className="form-group">
                <label className="control-label">Pickup Time</label>

                <div className="row xsm">
                  <div className="col-5">
                    <span
                      onClick={() => pickupDateFunction()}
                      className="btn secondry d-block f14"
                    >
                      {order.date ? order.date : "Select date"}
                    </span>
                  </div>
                  <div className="col-7">
                    <span
                      onClick={() => pickupDateFunction()}
                      className="btn secondry d-block f14"
                    >
                      {order.time ? order.time : "Select Time"}
                    </span>
                  </div>
                </div>
              </div>


              { method === 2 ? (
              <div className="form-group no">
                <label className="control-label">Payment method</label>

                <div className="row xsm">
                  <div className="col-12">
                    <span
                      onClick={() => {setShowPayment(true)}}
                      className="btn secondry d-block f14"
                    >
                      { paymentMethod ? paymentMethod[0].toUpperCase() + paymentMethod.substring(1, paymentMethod.length) : "Select payment" }
                    </span>
                  </div>
                </div>
              </div> ) : ""
              }

            </div>
          )}

          <div className="cart-gutter">
            <div className="cart-head">
              <span className="item">
                {/* {props.cartData && props.cartData.count} Item(s)added */}
                {cartData && cartData.length} Item(s)added
              </span>
              <button onClick={clearCart} className="btn primary action">
                Clear Items
              </button>
            </div>
            <div className="cart-body">
              {/* {
                props.cartData &&
                props.cartData.cart &&
                props.cartData.count > 0 && (
                  <CartRender
                    updateCart={props.updateCart}
                    cdata={props.cartData.cart}
                  />
                )
              } */}
              {
                cartData &&
                cartData.length > 0 && (
                  <CartRender
                    updateCart={cartData}
                    cdata={cartData}
                  />
                )
              }
            </div>
          </div>
          {(!cartData || cartData.count === 0) && (
            <div style={{ padding: "40% 0", display: "flex", justifyContent: "center" }}

            >
              <div className="empty_cart">
                <img
                  alt="cart"
                  className="align-self-center"
                  width="90px"
                  src={cartImg1}

                />
                <h5 style={{ paddingTop: '5%' }}>Your cart is empty</h5>
                <p>Add something from the menu!</p>
              </div>
            </div>
          )}
        </div>
          {
            method === 2 && estPrepTime !== undefined?
            <div className={'cart-prep-time'}>
              <div className={'cart-prep-time text-container'}>
                <div className={'cart-prep-time text'}>
                  Estimated preparation time
                </div>
              </div>
              <div className={'cart-prep-time value-container'}>
                <div className={'cart-prep-time value'}>
                  {
                    estPrepTimeHours !== null 
                    ?
                    `${estPrepTimeHours} hr ${estPrepTimeMinutes} min`
                    // `${estPrepTimeHours} hours ${estPrepTimeMinutes} minutes`
                    :
                    `${estPrepTimeMinutes} minutes`
                  }
                </div>
              </div>
            </div>:
            null
          }
        <div className={`cart-foot ${showCartTotal ? "open" : ""}`}>
          <div className="cart-table">
            <table>
              <tbody>
                <tr>
                  <td>Sub Total:</td>
                  <td data-testid="sub_total">$ {cartData && parseFloat(cartData.sub).toFixed(2)}</td>
                </tr>

                {method === 1 ? (<tr>
                  <td>Delivery Charge:</td>
                  <td data-testid="final_delivery_charge">$ {cartData?.srvCharge?.toFixed(2)}</td>
                </tr>) : (<tr></tr>)}

                {/* <tr>
                  <td>Delivery charge:</td>
                  <td>
                    {method === 1
                      ? props.cartData && props.cartData.d_charge
                      : 0}
                  </td>
                </tr> */}
                <tr>
                  <td>GST</td>
                  <td data-testid="GST">$ {cartData && parseFloat(cartData.gst).toFixed(2)}</td>
                </tr>
                {method === 2 ? (<tr>
                  <td>Rounding:</td>
                  <td data-testid="rounding">$ {cartData && cartData.amountRoundingDiff}</td>
                </tr>) : (<tr></tr>)}
              </tbody>
            </table>
          </div>

          <button
            className="toggle-action"
            onClick={() => setShowCartTotal(!showCartTotal)}
            data-testid="show_price_details"
          >
            {/* <i className="fa fa-angle-up"></i> */}
            <img src={arrowup} alt="arrow-up" />
          </button>
          <div className="ins">
            <button
              disabled={( method===2 ? (order?.date && order?.time)?false:true : false )}
              onClick={() => {
                // setPopupText("Check out successfully!");
                // setLshow(true);
                // setTimeout(() => {
                //   ProcessCheckOut();
                // }, 1500);
                ProcessCheckOut();
              }}
              className="btn primary"
            >
              <span>Check out</span>
              <span>|</span>
              <span data-testid="final_total">
                ${" "}
                {method === 1
                  ? cartData && cartData.sum
                  : cartData &&
                  cartData.sum}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={props.closeRightbar}
        className={`app-overlay ${show ? "open" : ""}`}
      ></div>
    </div>
  );
};

RightSidebar.propTypes = {
  closeRightbar: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  add_to_cart: state.products.add_to_cart,
  order: state.products.order,
  loading: state.products.loading,
  tax: state.products.tax,
  product: state.products.product,
  outlates: state.products.outlates,
  postal: state.postal,
  auth: state.auth,
  payment: state.products.payment,
  business_location: state.products.business_location,
  est_prep_time: state.products.est_prep_time
});
export default withTranslation()(
  // connect(mapStateToProps, { clearCartSingle, clearCart, placeOrder, getTaxes, submitOrder, updateOrder })(
  connect(mapStateToProps, { clearCart, placeOrder, getTaxes, submitOrder, updateOrder, getOutletInformation, submitPayment, clearPayment, getPreparationTime })(
    RightSidebar
  )
);
