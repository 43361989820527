import axios from "axios";
import {
  GET_BUSINESS_LOCATION,
  GET_PREPARATION_TIME,
  GET_MENUS,
  PRODUCT,
  PRODUCT_VARIATION,
  GET_PRODUCTS_BY_MENU,
  GET_MENUS_BY_ID,
  ADD_TO_CART,
  OUTLATES,
  ORDER,
  TAX,
  RE_ORDERS,
  ORDERS,
  ORDER_HISTORY,
  CATEGORY,
  REGISTER,
  CLEAR_STATE,
  PAYMENT,
  DINE_IN_DETAIL,
  DINE_IN_ORDER,
  GET_ERRORS,
  TRACK_ORDER,
  PLACE_ORDER_PRODUCT,
  LOADING,
  SET_STRIPE_LALAMOVE_KEY,
  GET_STATUS_DELIVERY_STORE
} from "./types";
import { setLoading } from "./commonActions";
import { getError } from "./errorActions";

export const getCategories = () => (dispatch) => { 
  let data = {
    business_id: localStorage.business_id,
    business_location_id: localStorage.location_id,
    service_tax_flag: '1',
  }
  
  dispatch(setLoading());
  axios
  .post("/get_all_categories_do",data)
  .then((res) =>
  
    dispatch({
      type: CATEGORY,
      payload: res.data.categories,
    })
  )
  .catch((err) => {
    // console.log("Error: " + err)
    dispatch(sendError(err))
  });
  
}

export const getPreparationTime = () => (dispatch) => {
  let dataObj = {
    business_id: localStorage.business_id,
    location_id: localStorage.location_id,
  }
  dispatch(setLoading());
  axios.post("check_qr_pickup_preparation_time", dataObj).then((response)=>{
    dispatch({
      type: GET_PREPARATION_TIME,
      payload: response.data
    })
  })
}
  
export const getBusinessLocation = () => (dispatch) => {

  dispatch(setLoading());
  axios.post("get_business_location_for_web", {business_location_id: localStorage.location_id}).then((response)=>{
    dispatch({
      type: GET_BUSINESS_LOCATION,
      payload: response.data
    })
  })
  
}

export const getStatusDeliveryStore = (data) => (dispatch) => {
  dispatch(setLoading());
  axios.post("/check_status_delivery_store", {
      "location_id": data.location_id
  }).then((response)=>{
      dispatch({
          type: GET_STATUS_DELIVERY_STORE,
          payload: response.data
      })
  }).catch((err)=>{
      dispatch(getError(err))
  })
} 


export const getProducts = () => (dispatch) => {
  let data = {
    business_id: localStorage.business_id,
    business_location_id: localStorage.location_id,
    category_id: '0',
  }
  dispatch(setLoading());
  axios
  .post("/get_category_products_do",data)
  .then((response) =>
    dispatch({  
      type: PRODUCT,
      payload: response.data,
    })
  )
  .catch((err) => dispatch(getError(err)));
}

export const getStripeAndLalamoveKey = () => (dispatch) => {
  console.log("GET KEY");
  let data = {
    params: {
      business_id: localStorage.business_id,
      location_id: localStorage.location_id
    }
  }
  dispatch(setLoading());
  axios
  .get("/get_stripe_and_lalamove_key", data)
  .then((response) => {
    console.log(response);
    dispatch({  
      type: SET_STRIPE_LALAMOVE_KEY,
      payload: response.data,
    })
    return response.data;
  })
  .catch((err) => dispatch(getError(err)));
}

export const submitPayment = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post("/save_pickup_delivery", data)
    .then((res) => {
        console.log(res);

        if(res.data?.errorMessage) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data?.errorMessage,
          });
          alert(res.data?.errorMessage);
        }
        else {
          dispatch({
            type: PAYMENT,
            payload: res,
          })
        }
      }
    )
    .catch((err) => {
      console.log(err);

      dispatch(getError(err));
      if(err.errorMessage) {
        alert(err.errorMessage);
      }
    });
};

export const clearPayment = () => (dispatch) => {
  dispatch({
    type: PLACE_ORDER_PRODUCT,
    payload: null
  })
  
  
  dispatch({
    type: PAYMENT,
    payload: null,
  });
};

export const ClearState = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};

export const Signup = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post("/register", data)
    .then((res) =>
      dispatch({
        type: REGISTER,
        payload: res.data,
      })
    )
    .catch((err) => dispatch(getError(err)));
};

export const Reorder = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get("/orders/reorder/" + id)
    .then((res) => {
      dispatch({
        type: RE_ORDERS,
        payload: res.data.data.original ? res.data.data.original : null,
      });
    })
    .catch((err) => dispatch(getError(err)));
};

export const MyOrders = () => (dispatch) => {
  dispatch(setLoading());
  // axios
  //   .get("/orders")
  //   .then((res) => {
  //     dispatch({
  //       type: ORDERS,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => dispatch(getError(err)));

  axios
    .post("/track_order_history", 
      {
        'business_id': localStorage.business_id,
        'location_id': localStorage.location_id,
        'contact_id': localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user?.contact_id):0
      }
    )
    .then((res) => {
      dispatch({
        type: ORDERS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(getError(err)));
};
export const SingleOrder = (id) => (dispatch) => {
  dispatch(setLoading());
  // axios
  //   .get("/orders/" + id)
  //   .then((res) => {
  //     dispatch({
  //       type: ORDER,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => dispatch(getError(err)));

  var cart_data = [];

  if(localStorage.serviceFee) {
    cart_data = JSON.parse(localStorage.serviceFee)[0];

    console.log(cart_data);
  }

  dispatch({
    type: ORDER,
    payload: {
      'data': cart_data
    },
  });
};

export const placeOrder = (data) => (dispatch) => {
  dispatch(setLoading());
  dispatch({
    type: ORDER,
    payload: data,
  });
  // axios
  //   .post("/orders", data)
  //   .then((res) => {
  //     dispatch({
  //       type: ORDER,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => dispatch(getError(err)));
};

export const getOutlates = () => (dispatch) => {
  // console.log(localStorage);

  dispatch(setLoading());
  axios
    .post("/find_pickup_store", {'business_id': localStorage.business_id})
    .then((res) => {
      // console.log(res);

      dispatch({
        type: OUTLATES,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(getError(err)));
};

// export const cartUpdate = (id, data) => (dispatch) => {
//   dispatch(setLoading());

//   axios
//     .put("/cart/" + id, data)
//     .then((res) => {
//       dispatch({
//         type: ADD_TO_CART,
//         payload: res.data.data.original ? res.data.data.original : null,
//       });
//     })
//     .catch((err) => dispatch(getError(err)));
// };

// export const mycart = () => (dispatch) => {
//   dispatch(setLoading());
//   axios
//     .get("/cart")
//     .then((res) => {
//       dispatch({
//         type: ADD_TO_CART,
//         payload: res.data,
//       });
//     })
//     .catch((err) => dispatch(getError(err)));
// };

export const clearCart = () => (dispatch) => {
  dispatch(setLoading());
  dispatch({
    type: ADD_TO_CART,
    payload: null,
  });

  // dispatch(setLoading());

  // axios
  //   .delete("/cart/clear")
  //   .then((res) => {
  //     dispatch({
  //       type: ADD_TO_CART,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => dispatch(getError(err)));
};

// export const clearCartSingle = (cartId) => (dispatch) => {
//   dispatch(setLoading());

//   axios
//     .delete("/cart/" + cartId)
//     .then((res) => {
//       dispatch({
//         type: ADD_TO_CART,
//         payload: res.data,
//       });
//     })
//     .catch((err) => dispatch(getError(err)));
// };
export const cartLocal = (data) => (dispatch) => {
  dispatch(setLoading());
  dispatch({
    type: ADD_TO_CART,
    payload: data,
  });
  // axios
  //   .post("/cart", data)
  //   .then((res) => {
  //     dispatch({
  //       type: ADD_TO_CART,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((err) => dispatch(getError(err)));

}
// export const addTocart = (data) => (dispatch) => {
//   dispatch(setLoading());
//   console.log("HDUDHUHDUUDHU djdjsi")
//   axios
//     .post("/cart", data)
//     .then((res) => {
//       {console.log("HDUDHUHDUUDHU djdjsi")
//       dispatch({
//         type: ADD_TO_CART,
//         payload: res.data.data ? res.data.data : null,
//       });}
      
//     })
//     .catch((err) => dispatch(getError(err)));
// };

export const getProductsByCategory = (categoryID) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/menus/${categoryID}`)
    .then((res) =>
      dispatch({
        type: GET_PRODUCTS_BY_MENU,
        payload: res.data,
      })
    )
    .catch((err) => dispatch(getError(err)));
};
export const getMenubyId = (menuID) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/menus/${menuID}`)
    .then((res) =>
      dispatch({
        type: GET_MENUS_BY_ID,
        payload: res.data,
      })
    )
    .catch((err) => dispatch(getError(err)));
};

export const getMenus = () => (dispatch) => {
  dispatch(setLoading());
  return axios
    .get(`/menus`)
    .then((res) => {
      dispatch({
        type: GET_MENUS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(getError(err)));
};

export const getProductVariation = (product_id) => (dispatch) => {
  // console.log("fhdshds");
  // console.log(product_id);

  let data = {
    business_id: localStorage.business_id,
    user_id: localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user):0,
    product_id: product_id,
  }
  dispatch(setLoading());
  axios
  .post("/get_product_modifier_do",data)
  .then((response) => {
    console.log(response);
    dispatch({
      type: PRODUCT_VARIATION,
      payload: response.data,
    })
  }
    
    
  )
  .catch((err) => dispatch(getError(err)));
}

export const getTaxes = () => (dispatch) => {
  let data = {
    business_id: localStorage.business_id,
    business_location_id: localStorage.location_id,
    service_tax_flag: '1',
  }
  dispatch(setLoading());
  axios
  .post("/get_all_categories_do",data)
  .then((response) =>
  {console.log(response);dispatch({
      type: TAX,
      payload: response.data.tax,
    })}
    
  )
  .catch((err) => dispatch(getError(err)));
}

export const submitOrder = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
  .post("/save_digital_ordering",data)
  .then((response) =>
    dispatch({
      type: DINE_IN_ORDER,
      payload: response.data,
    })
  )
  .catch((err) => dispatch(getError(err)));
}

export const updateOrder = (data) => (dispatch) => {
  dispatch(setLoading());
  axios
  .post("/update_digital_ordering",data)
  .then((response) =>
    dispatch({
      type: DINE_IN_ORDER,
      payload: response.data,
    })
  )
  .catch((err) => dispatch(getError(err)));
}

export const orderHistory = () => (dispatch) => {
  let data = {
    business_id: localStorage.business_id,
    location_id: localStorage.location_id,
    contact_id: localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user?.contact_id):0
  }
  dispatch(setLoading());
  axios
  .post("/track_order_history",data)
  .then((response)=>
    dispatch({
      type: ORDER_HISTORY,
      payload: response.data.sells,
    })
  )
  .catch((err) => dispatch(getError(err)));
}

export const trackOrder = (load = true) => (dispatch) => {
  console.log(localStorage.getItem("business_id"))

  let data = {
    business_id: localStorage.getItem('business_id'),
    location_id: localStorage.getItem('location_id'),
    contact_id: localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user?.contact_id):0
  }

  if (load){
    dispatch(setLoading())
  }
  

  axios.post("/track_order", data).then((response)=>{
    dispatch({
      type: TRACK_ORDER,
      payload: response.data.sells,
      loading: false
    })
  }).catch((err)=>{
    dispatch(getError(err))
  })
}

export const sendError = (error) => {
  return {
    type: GET_ERRORS,
    payload: error,
  };
};

export const placeDeliveryOrder = (data) => dispatch => {
  dispatch({
    type: LOADING
  })
  axios.post("/placeOrder", data).then((response)=>{
    dispatch({
      type: PLACE_ORDER_PRODUCT,
      payload: response
    })
  }).catch((error)=>{
    dispatch(getError(error))
  })

}