import React from 'react';
import Carousel1 from '../../components/Carousel/Carousel';
import Carousel from 'react-bootstrap/Carousel';
import image3 from '../../assets/svgs/image5.svg';
import './promo.css';
function Promo(){
    return(
        <div>
<Carousel1 style={{marginBottom:"1%"}}/>

<div style={{marginTop:"1%",marginBottom:"1%"}} className="row">
 <Carousel className="column" style={{marginRight:"1%"}}>
      <Carousel.Item >
        <img className="d-block w-100" src={image3} alt="First slide" />
        <Carousel.Caption>
        
          <h5>1 for 1 sashimi</h5>
          <span >From March 1 to April 1</span>
        </Carousel.Caption>
      </Carousel.Item>
     
     
    </Carousel>
    <Carousel className="column">
      <Carousel.Item>
        <img className="d-block w-100" src={image3} alt="First slide" />
        <Carousel.Caption>
          <h5>1 for 1 sashimi</h5>
          <span>From March 1 to April 1</span>
        </Carousel.Caption>
      </Carousel.Item>
     
     
    </Carousel>
   </div>
   <Carousel1 />
</div>
    )
}

export default Promo;