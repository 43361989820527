import {
  GET_BUSINESS_LOCATION,
  LOADING,
  GET_MENUS,
  OUTLATES,
  PRODUCT,
  PRODUCT_VARIATION,
  GET_MENUS_BY_ID,
  ADD_TO_CART,
  ORDER,
  DELETE_CART,
  ORDERS,
  ORDER_HISTORY,
  RE_ORDERS,
  REGISTER,
  GET_ERRORS,
  CLEAR_STATE,
  SET_REGISTERED_USER,
  SET_CURRENT_USER,
  PAYMENT,
  CATEGORY,
  TRACK_ORDER,
  TAX,
  GET_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE,
  PLACE_ORDER_PRODUCT,
  SET_STRIPE_LALAMOVE_KEY,
  GET_PREPARATION_TIME,
  GET_STATUS_DELIVERY_STORE
} from "../actions/types";
const initialState = {
  business_location: null,
  get_menus: null,
  child_products: null,
  product_hierarchy: null,
  product: null,
  product_id: null,
  variation: null,
  loading: false,
  outlates: null,
  add_to_cart: null,
  get_menus_by_id: null,
  order: null,
  orders: null,
  order_history: null,
  reorders: null,
  signup: null,
  errors: null,
  payment: null,
  tax: null,
  category: null,
  track_order: null,
  place_order: null,
  stripeLalaMoveKeys: [],
  est_prep_time:null,
  outlet_status:false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case CATEGORY:
      return {
        ...state,
        category: action.payload,
        errors: null,
        loading: false,
      };

      case TAX:
      return {
        ...state,
        tax: action.payload,
        errors: null,
        loading: false,
      };

    case PAYMENT:
      return {
        ...state,
        payment: action.payload,
        errors: null,
        loading: false,
      };

    case CLEAR_STATE:
      return {
        ...state,
        signup: null,
        errors: null,
        loading: false,
      };
    
    case GET_BUSINESS_LOCATION:
      return {
        ...state,
        business_location: action.payload
      }
    case GET_STATUS_DELIVERY_STORE:
      return {
        ...state,
        outlet_status: action.payload,
        loading: false,
      }
    case GET_PREPARATION_TIME:
      return {
        ...state,
        est_prep_time: action.payload
      }

    case PRODUCT:
      return {
        ...state,
        product: action.payload,
        errors: null,
        loading: false,
      };

      case PRODUCT_VARIATION:
      return {
        ...state,
        variation: action.payload,
        errors: null,
        loading: false,
      };

    case GET_MENUS:
      return {
        ...state,
        get_menus: action.payload,
        loading: false,
      };

    case GET_MENUS_BY_ID:
      return {
        ...state,
        get_menus_by_id: action.payload,
        loading: false,
      };

    case ADD_TO_CART:
      return {
        ...state,
        add_to_cart: action.payload,
        loading: false,
      };

    case DELETE_CART:
      return {
        ...state,
        delete_cart: action.payload,
        loading: false,
      };

    case OUTLATES:
      return {
        ...state,
        outlates: action.payload,
        loading: false,
      };

    case ORDER:
      return {
        ...state,
        order: action.payload,
        loading: false,
      };

    case ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };

    case ORDER_HISTORY:
      return {
        ...state,
        order_history: action.payload,
        loading: false,
      };

    case RE_ORDERS:
      return {
        ...state,
        reorders: action.payload,
        loading: false,
      };

    case REGISTER:
      return {
        ...state,
        signup: action.payload,
        loading: false,
      };
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
      
    case SET_REGISTERED_USER:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };

      case GET_CUSTOMER_PROFILE:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };

      case UPDATE_CUSTOMER_PROFILE:
        return{
          ...state,
          payload: action.payload,
          loading: false,
        };

    case SET_CURRENT_USER:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case TRACK_ORDER:
      return {
        ...state,
        track_order: action.payload,
        loading: false
      }
    case PLACE_ORDER_PRODUCT:
      return {
        ...state,
        place_order: action.payload,
        loading: false
      }
    case SET_STRIPE_LALAMOVE_KEY:
      return {
        ...state,
        stripeLalaMoveKeys: action.payload
      }

    default:
      return state;
  }
};
