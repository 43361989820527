import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../assets/css/icon.css";
import "../../assets/css/bundle.css";
import "../../assets/css/component.css";
import "../../assets/css/btn.css";
import "./OrderPlaced.css";
import Rider from '../Rider/Rider';
import thumb5 from "../../assets/images/thumb5.png";
import close from "../../assets/svgs/close.svg";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

// const OrderPlaced = ({TrackOrder, payment, regRef, show, closeModal }) => {
const OrderPlaced = (props) => {

  const history = useHistory();

  const myRef = useRef();
  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const orderButton = (myref) => {
   
    // if (localStorage.login) {
    //   props.TrackOrder();
    // } else {
    //   props.regRef.current.dispatchEvent(
    //     new MouseEvent("click", {
    //       view: window,
    //       bubbles: true,
    //       cancelable: true,
    //       buttons: 1,
    //     })
    //   );
    //   props.closeModal();
    // }
  };

  
const [isRider,setRider]=React.useState(false);
  return (
    <Modal
      show={props.show}
      className="rounded-lg"
      dialogClassName="modal-smd"
      centered
    >
      <Modal.Header className="alpha">
        <button
          type="button"
          className="close-modal-btn mr-3 close-z"
          data-dismiss="modal"
          aria-label="Close"
          onClick={
            ()=>{
              props.clearPayment()
              window.location.href = '/?business_id=' + localStorage.business_id + '&location_id=' + localStorage.location_id
            }
          }
        >
          <img src={close} width="20" alt="close" />
        </button>
      </Modal.Header>
      <Modal.Body className="pd20">
        <div className="ember radius lg">
          <figure>
            <img src={thumb5} alt="" />
          </figure>
          <div className="caption">
            {/* <span className="title">Set A ( 50% off )</span> */}
            {/* <span className="dec">On your next order</span> */}
          </div>
        </div>
        <button
          // onClick={() => setRider(true)}
          onClick={() => {
            props.closeModal()
            props.TrackOrder()
          }}
          className="btn regular xlg fw-right mt-5"
        >
          Order successfully placed <i className="double-check-icon"> </i>
        </button>
        {/* <Rider show={isRider} onHide={() => setRider(false)}/> */}
        <div className="opm-data">
          <span>
            Order Sent :  {props.payment?.data?.order_sent}
            {/* {orders &&
              orders.created_at &&
              formatAMPM(new Date(orders.created_at))} */}
          </span>
          <span data-testid="reference_no">Ref ID : {props.payment?.data?.ref_no}
            {/* {orders && orders.id && orders.id} */}
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};
OrderPlaced.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
// export default OrderPlaced;


const mapStateToProps = (state) => ({
  payment: state.products.payment
});
export default withTranslation()(
  connect(mapStateToProps)(OrderPlaced)
);