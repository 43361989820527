import {
    RESET_USER_PASSWORD_SUCCESS,
    CONFIRM_VERIFICATION_SUCCESS, 
    SEND_REQUEST_USER, CLEAR_USER_STATE, 
    DONE_LOADING,LOADING, 
    USER_LOADING, 
    CHECK_EMAIL_SUCCESS, 
    SEND_REQUEST_USER_EMAIL, 
    SET_ERROR_EMAIL_USER, 
    USER_REQUEST_SUCCESS, 
    USER_REQUEST_ERROR, 
    CREATE_VERIFICATION_CODE,
    UPDATE_USER_PASSWORD_SUCCESS
} from '../actions/types';

import axios from "axios"

let source = null

export const load = () => dispatch => {
    dispatch({
        type: USER_LOADING
    });
};

export const doneLoading = () => dispatch => {
    dispatch({
        type: DONE_LOADING
    })
}

export const clearUserState = () => dispatch => {
    dispatch({
        type: CLEAR_USER_STATE
    })
}

export const signup = (data) => dispatch => {
    dispatch({
        type: SEND_REQUEST_USER
    })

    axios.post("auth/customer_signup", data).then((response)=>{
        dispatch({
            type: USER_REQUEST_SUCCESS,
            payload: response
        })
    }).catch((error)=>{
        dispatch({
            type: USER_REQUEST_ERROR,
            payload: error
        })
    })
}

export const checkEmail = (email) => dispatch => {
    
    dispatch({
        type:SEND_REQUEST_USER_EMAIL
    })

    if (source !== null) {
        source.cancel("CANCEL REQUEST")
    }
    
    source = axios.CancelToken.source()

    axios.post("/checkEmail", {
        "email": email
      }, {
        cancelToken: source.token
      }).then((response)=>{
          console.log(response.data.message)
        if (response.data.message === "true") {
            dispatch({
                type: CHECK_EMAIL_SUCCESS,
                payload: true
            })
        } else {
            dispatch({
                type: CHECK_EMAIL_SUCCESS,
                payload: false
            })
        }
        
      }).catch((error)=>{
        dispatch({
            type: SET_ERROR_EMAIL_USER,
            payload: error
        })
      })
}


export const createVerification = (email) => dispatch => {
    dispatch({
        type:SEND_REQUEST_USER
    })

    axios.post("/password/create", {"email": email}).then((res)=>{
        dispatch({
            type: CREATE_VERIFICATION_CODE,
            payload: res
        })
    }).catch((error)=>{
        dispatch({
            type: USER_REQUEST_ERROR,
            payload: error
        })
    })
}

export const confirmVerification = (code) => dispatch => {
    dispatch({
        type:SEND_REQUEST_USER
    })

    axios.get(`/password/find/${code}`).then((res)=>{
        dispatch({
            type:CONFIRM_VERIFICATION_SUCCESS,
            payload: res
        })
    }).catch((err)=>{
        dispatch({
            type:USER_REQUEST_ERROR,
            payload: err
        })
    })

}

export const submitReset = (data) => dispatch => {
    dispatch({
        type: SEND_REQUEST_USER
    })

    axios.post("/password/reset", data).then((response)=>{
        dispatch({
            type: RESET_USER_PASSWORD_SUCCESS,
            payload: response
        })
    }).catch((error)=>{
        dispatch({
            type: USER_REQUEST_ERROR,
            payload: error
        })
    })
}

export const updatePassword = (data) => dispatch => {

    dispatch({
        type: SEND_REQUEST_USER
    })

    axios.post('/auth/change_customer_password', data).then(response => {
        // setPopupTxt("Password is successfully updated!!!");
        // setLshow(true);
        dispatch({
            type: UPDATE_USER_PASSWORD_SUCCESS,
            payload: response
        })
        
      }).catch(error => {
        dispatch({
            type: USER_REQUEST_ERROR,
            payload: error
        })
    });
}
