// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/svgs/about-1.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/svgs/about-2.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".img-text {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  right: 0;\n  top: 0;\n  font-size: 48px;\n  text-transform: uppercase;\n  color: aliceblue;\n  font-weight: 500;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.list-container {\n  margin: 200px 0;\n}\n\n.image-div {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 100%;\n  width: 100%;\n}\n\n.image-div2 {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  height: 100%;\n  width: 100%;\n}\n\n.gallery {\n  margin-top: 100px;\n  font-weight: bold;\n  font-size: 48px;\n  line-height: 56px;\n  text-transform: uppercase;\n  color: #8f8b8b;\n}\n", "",{"version":3,"sources":["webpack://src/pages/About-us/About-us.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,YAAY;EACZ,QAAQ;EACR,MAAM;EACN,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yDAAuD;EACvD,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yDAAuD;EACvD,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".img-text {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  right: 0;\n  top: 0;\n  font-size: 48px;\n  text-transform: uppercase;\n  color: aliceblue;\n  font-weight: 500;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.list-container {\n  margin: 200px 0;\n}\n\n.image-div {\n  background-image: url(\"../../assets//svgs/about-1.svg\");\n  height: 100%;\n  width: 100%;\n}\n\n.image-div2 {\n  background-image: url(\"../../assets//svgs/about-2.svg\");\n  height: 100%;\n  width: 100%;\n}\n\n.gallery {\n  margin-top: 100px;\n  font-weight: bold;\n  font-size: 48px;\n  line-height: 56px;\n  text-transform: uppercase;\n  color: #8f8b8b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
