export const SET_REGISTERED_USER = "SET_REGISTERED_USER";

//Login User Constants
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_USER_BY_ID = 'GET_USER_BY_ID';

export const CHECK_USER_BY_ID = 'CHECK_USER_BY_ID';

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE";

export const UPDATE_CUSTOMER_PROFILE = "UPDATE_CUSTOMER_PROFILE";

//Loading Constants
export const LOADING = "LOADING";
export const DONE_LOADING = "DONE_LOADING";
export const USER_LOADING = "USER_LOADING";
//Errors Constants
export const GET_ERRORS = "GET_ERRORS";

//Localized Messages
export const GET_LOCALIZED_MESSAGES = "GET_LOCALIZED_MESSAGES";

// Menus or product
export const PRODUCT = "PRODUCT";
export const PRODUCT_VARIATION = "PRODUCT_VARIATION";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const TRACK_ORDER = "TRACK_ORDER"
export const GET_MENUS = "GET_MENUS";
export const GET_PRODUCTS_BY_MENU = "GET_PRODUCTS_BY_MENU";
export const GET_PREPARATION_TIME="GET_PREPARATION_TIME";
export const GET_MENUS_BY_ID = "GET_MENUS_BY_ID";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const DELETE_CART = "DELETE_CART";
export const ORDER = "ORDER";
export const CLEAR_STATE = "CLEAR_STATE";
export const TAX = "TAX";
export const PAYMENT = "PAYMENT";
export const CATEGORY = "CATEGORY";
export const DINE_IN_ORDER = 'DINE_IN_ORDER';
export const DINE_IN_DETAIL = 'DINE_IN_DETAIL';
export const PLACE_ORDER_PRODUCT = 'PLACE_ORDER_PRODUCT'






export const REGISTER = "REGISTER";


export const ORDERS = "ORDERS";

export const RE_ORDERS = "RE_ORDERS";




export const OUTLATES = "OUTLATES";

export const GET_BUSINESS_LOCATION = "GET_BUSINESS_LOCATION";
export const PAGE_LOAD = "PAGE_LOAD"

// get Postal Code
export const GET_POSTAL_REQUEST = "GET_POSTAL"
export const GET_POSTAL_FAIL = "GET_POSTAL_FAIL"
export const GET_POSTAL_SUCCESS = "GET_POSTAL_SUCCESS"
export const SET_ADDRESS = "SET_ADDRESS"

// OUTLET DETAILS
export const GET_OUTLET_DETAILS = "GET_OUTLET_DETAILS"
export const GET_OUTLET_DETAILS_SUCCESS = "GET_OUTLET_DETAILS_SUCCESS"
export const GET_OUTLET_DETAILS_FAIL = "GET_OUTLET_DETAILS_FAIL"
export const GET_STATUS_DELIVERY_STORE = "GET_STATUS_DELIVERY_STORE"
export const GET_STATUS_DELIVERY_STORE_SUCCESS = "GET_STATUS_DELIVERY_STORE_SUCCESS"
export const GET_STATUS_DELIVERY_STORE_FAIL = "GET_STATUS_DELIVERY_STORE_FAIL"

// get image object
export const GET_IMAGE_OBJECT = 'GET_IMAGE_OBJECT';


// get Verification Code
export const CREATE_VERIFICATION_CODE = "CREATE_VERIFICATION_CODE"
export const SEND_REQUEST_USER_EMAIL = "SEND_REQUEST_USER_EMAIL"
export const SEND_REQUEST_USER = "SEND_REQUEST_USER"
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS"
export const SET_ERROR_USER = "SET_ERROR_USER"
export const SET_ERROR_EMAIL_USER = "SET_ERROR_EMAIL_USER"
export const CLEAR_USER_STATE = "CLEAR_USER_STATE"
export const USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS"
export const USER_REQUEST_ERROR = "USER_REQUEST_ERROR"
export const CONFIRM_VERIFICATION_SUCCESS = "CONFIRM_VERIFICATION_SUCCESS"
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS"
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS"

// PAYMENT
export const SET_STRIPE_LALAMOVE_KEY = "SET_STRIPE_LALAMOVE_KEY"
