import React from "react";
import "./Outlets.css";
import "../../assets/css/modal.css";
import map from "../../assets/svgs/map.svg";
import aboutUs from "../../assets/svgs/about-us.svg";

const Outlets = () => {
  return (
    <div className="main_section">
      <div className="map">
        <img src={map} className="img-fluid" alt="" />
      </div>
      <div className="row" style={{ margin: "auto" }}>
        <div className="col-12 col-md-9 p-5">
          <div className="about-content">
            A local Chinese eatery that had been wok-king out fiery dishes for 3
            generation. Started out from a small hawker stall in 1970s at Old
            Havelock Road and currently operating at Alexandra Village.
          </div>
          <div className="about-content mt-3">
            A few of our top recommended dishes from our Menu is the Chilli &
            Black Pepper Crabs, Handmade Hei-Chor (Prawn Rolls), uniquely
            created Mingzhu Rolls, Wok-toss Moonlight Horfun and Coffee Pork
            Ribs.
          </div>
        </div>
      </div>
      {[...Array(2)].map((el, index) => (
        <div key={index}>
          <div className="outlet_div">
            <img src={aboutUs} className="w100" alt="" />
            <div className="img-text">Outlets A</div>
          </div>
          <div className="row" style={{ margin: "auto" }}>
            <div className="col-12 col-md-9 p-5">
              <div className="about-content">
                A local Chinese eatery that had been wok-king out fiery dishes
                for 3 generation. Started out from a small hawker stall in 1970s
                at Old Havelock Road and currently operating at Alexandra
                Village.
              </div>
              <div className="about-content mt-3">
                A few of our top recommended dishes from our Menu is the Chilli
                & Black Pepper Crabs, Handmade Hei-Chor (Prawn Rolls), uniquely
                created Mingzhu Rolls, Wok-toss Moonlight Horfun and Coffee Pork
                Ribs.
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Outlets;
