import axios from "axios"
import {GET_POSTAL_REQUEST, SET_ADDRESS} from "./types"
import {GET_POSTAL_SUCCESS} from "./types"
import {GET_POSTAL_FAIL} from "./types"
import { GET_OUTLET_DETAILS } from "./types"
import { GET_OUTLET_DETAILS_FAIL } from "./types"
import { GET_OUTLET_DETAILS_SUCCESS } from "./types"


let timer = null

export const requestPostal = () => {
    return {
        type: GET_POSTAL_REQUEST
    }
}

export const requestPostalSuccess = (data) => {
    return {
        type: GET_POSTAL_SUCCESS,
        payload: data
    }
}

export const requestPostalFail = (error) => {
    return {
        type: GET_POSTAL_FAIL,
        payload: error
    }
}


export const getPostal = (postal) => (dispatch) => {
    dispatch(requestPostal());

    if (timer) {
        clearTimeout(timer)
    }

    timer = setTimeout(() => {
        // fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then(function(response) {
        fetch(`https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then(function (response) {
            return response.json();
        }).then(function (data) {
            dispatch(requestPostalSuccess(data));
        }).catch(function(error) {
            dispatch(requestPostalFail(error));
        });
    }, 500)

    

    // axios.get(`https://developers.onemap.sg/commonapi/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((response)=>{
    //     dispatch(requestPostalSuccess(response.data))
    // }).catch((error)=>{
    //     dispatch(requestPostalFail(error))
    // })
}

export const getOutletInformation = (data) => (dispatch) => {
    dispatch({
        type: GET_OUTLET_DETAILS
    })

    axios.post("/getQuotation", {
        "business_id": localStorage.getItem("business_id"),
        "cust_lat": data.latitude ?? 0,
        "cust_lng": data.longtitude ?? 0,
        "cust_add": data.full_address,
        "cust_name": data.customer_name,
        "cust_phone": data.customer_phone,
        "cust_remark": data.customer_remark
    }).then((response)=>{
        response.data = response.data.sort((a, b)=>{ //SORT IN ASCENDING ORDER
            if (a.is_open) {
                if (b.is_open) {
                    if (parseFloat(a.charges.totalFee) < parseFloat(b.charges.totalFee)) {
                        return -1
                    } else {
                        return 1
                    }
                } else {
                    return -1
                }
             } else {
                return 1
             }
        })

        dispatch({
            type: GET_OUTLET_DETAILS_SUCCESS,
            payload: response
        })
    }).catch((error)=>{
        dispatch({
            type: GET_OUTLET_DETAILS_FAIL,
            payload: error
        })
    })
} 



