import { useHistory, useLocation } from "react-router"
import { useState } from "react"
import Signup from "../../components/Signup/Signup"
import Signup2 from "../../components/Signup/Signup2"
import { defaultParams } from "../../components/Header/defaultParams"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import React from "react";

class Spinner extends React.Component {
  render() {
    return (
      <div className="full-width" style={{backgroundColor:"rgba(34,12,0, 0.1)", height:"100vh", width:"100vw", top:window.scrollY.toString()+"px" }}>
        <div style={{color: "black", opacity: 1}} className="spinner-border spinner-wide-handling " role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}


const JoinUs = (props) => {
    const [signupVal, setSignupValue] = useState(defaultParams)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)    

    const [signup, setSignup] = useState(1)
    
    
    const signUpClose = () => {
        setSignup(0)
    }



    const business_id = searchParams.get("business_id")
    const location_id = searchParams.get("location_id")
    console.log(props.user)

    return (
        
        !business_id || !location_id ? 
            <h3 style={{textAlign: "center", margin:5}}>Invalid URL</h3>   
        :
        <>

            {props.user.loading ? <Spinner/> : ""}

            <Signup
                joinus={true}
                show={signup === 1 ? true : false}
                closeModal={() => {
                    setSignup(null)
                }}
                nextStep={() => setSignup(2)}
                setSignup={setSignupValue}
                signupVal={signupVal}
            />
            <Signup2
                location_id={location_id}
                business_id={business_id}
                joinus={true}
                show={signup === 2 ? true : false}
                closeModal={() => {
                    signUpClose()
                }}
                setSignupClose={setSignup}
                setSignup={setSignupValue}
                signupVal={signupVal}
                business_id={business_id}
                location_id={location_id}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
});
export default withTranslation()(
    connect(mapStateToProps, { })(JoinUs)
);

