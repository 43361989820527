import React, { useState, useEffect, useRef } from "react";
import "./LoginHome.css";
import "../../assets/css/bundle.css";
import { useHistory } from "react-router-dom";
import "../../assets/css/modal.css";
import Carousel from "../../components/Carousel/Carousel";
import Tabs from "../../components/HomeTabs/Tabs.js";
import OrderItem from "../../components/OrderItem/orderItem.js";
import ChooseModal from "../../components/OrderItem/chooseModal";
import Pickup from "../../components/Pickup/Pickup";
import PickupDate from "../../components/PickupDate/PickupDate";
import TrackOrder from "../../components/TrackOrder/TrackOrder";
import TrackOrderNow from "../../components/TrackOrderNow/TrackOrderNow";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { getMenubyId, getCategories, getProducts,getBusinessLocation } from "../../actions/productActions";
import { useParams } from "react-router-dom";
import PopUp from "../../components/Modals/Popup";
import useIntersection from './useIntersection';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import './MenuItems.css'
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'



const MenuItems = (props) => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const table_id = queryParams.get('table_id');
  // const table_name = queryParams.get('table_name');
  // const pax = queryParams.get('pax');
  // const token = queryParams.get('token');
  // const business_id = queryParams.get('business_id');
  // const location_id = queryParams.get('location_id');
  // const user_id = queryParams.get('uid');

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [productDataModal, setProductDataModal] = useState("");
  const [showPickup, setShowPickup] = useState(false);
  const [showPickupDate, setShowPickupDate] = useState(false);
  const [showtrackOrder, setShowTrackOrder] = useState(false);
  const [showTrackNow, setShowTracknow] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);
  const [catload, setCatLoad] = useState(false);
  // const { id } = useParams();
  const [menus, setMenus] = useState(null);
  const [category, setCategory] = useState(null);
  const [menuload, setMenuload] = useState(false);
  const [order, setOrder] = useState([]);
  const [lshow, setLshow] = useState(false);
  const [popupText, setPopupText] = useState("");
  // const [qrToken, setQrToken] = useState(true);
  // const [tableName, setTableName] = useState(table_name);
  // const [tokenload, setQrTokenload] = useState(false);
  // const [dineInDetail, setDineInDetail] = useState(null);
  // const [dineInDetailLoad, setDineInDetailLoad] = useState(false);

  let business_id = localStorage.business_id;
  let location_id = localStorage.location_id;
  let verifiedEmail = localStorage.verifiedEmail;

  // console.log(localStorage.getItem('business_id'));

  // const queryParams = new URLSearchParams(window.location.search);

  // console.log(queryParams);

  // business_id = queryParams.get('business_id');
  // location_id = queryParams.get('location_id');
  useEffect(()=>{
    if(props.business_location!==undefined){      
      console.log('business_location ',props.business_location)
      console.log('category ',props.category)
    } else {
      console.log('business_location is U ',undefined)
    }
  },[])

  useEffect(() => {
    if (!load) {
      // props.getMenubyId(id);
      if (business_id != null && location_id != null) {
        if(verifiedEmail == null) {
          history.push("/?business_id=" + business_id + "&location_id=" + location_id);
        }
        props.getCategories();
        props.getProducts();
        props.getBusinessLocation();
      }

      // console.log(props.errors);
    
      // props.check_dinein_detail();
      setLoad(true);
    }

    
    if(props.errors != null){
      
      if(props.errors.message !== ''){
        history.push("/Error");
        setError(props.errors);
      }
      
    }

  

    if (
      props.loading === false &&
      props.product !== null &&
      menuload === false
    ) {
      setMenus(props.product);
      setMenuload(true);

    }

    // console.log(props);

    // if (
    //   props.loading === false &&
    //   props.dine_in_detail !== null &&
    //   dineInDetailLoad === false
    // ) {
    //   setDineInDetail(props.dine_in_detail);
    //   setDineInDetailLoad(true);
    //   console.log(dineInDetail);
    // }

    if (
      props.loading === false &&
      props.category !== null &&
      catload === false
    ) {
      setCategory(props.category);
      setCatLoad(true);
    }

    // if(typeof token === 'undefined' || token === null) {
    //   setQrToken(false);
    // } else {




  }, [load, error, props, order, menuload, catload, business_id, location_id]);

  const showPickFunction = () => {
    // console.log("____Show pick up function_______");
    setShowModal(false);
    setShowPickup(true);
  };

  const pickupDateFunction = () => {
    setShowPickupDate(true);
    setShowPickup(false);
  };

  const trackOrderFunction = () => {
    setShowPickupDate(false);
    setShowTrackOrder(true);
  };

  const showTrackOrderNowFunction = () => {
    setShowTrackOrder(false);
    setShowTracknow(true);
  };

  const trackOrderPage = () => {
    setShowTracknow(true);
    history.push("/track-order");
  };

  const ProductModel = (data) => {
    setShowModal(true);
    setProductDataModal(data);
  };

  const ProductsRender = () => {



    if (category && Object.keys(category).length > 0) {
      Object.keys(category).map((categoryKey, index) => {

        category[categoryKey].product = [];

        if (menus && Object.keys(menus).length > 0) {
          Object.keys(menus).forEach(function (menusKey) {
            if (category[categoryKey].id === menus[menusKey].category_id) {
              category[categoryKey].product.push(menus[menusKey])
            }
          });
        }
      });

      console.log(category[2].product);
    }

    let html = [];

    if (category && Object.keys(category).length > 0) {
      Object.keys(category).forEach(function (key) {
        let product_num = category[key].product.length;

        html.push(
          product_num > 0 &&
          <div id="menus" key={key} className="ord-gutter" name={'con-' + category[key].id}>
            <div className="heading">
              <h3 >
                {category[key].name}<span>{product_num} items</span>
              </h3>
            </div>
            <OrderItem
              data={category[key]}
              showChooseModal={ProductModel}
            />
          </div>
        );
      });
    }
    return html;
  };

  const RenderWhatsappWidget = () => {
    if(props.business_location !== undefined && props.business_location !== null){
      const whatsappNumber = props.business_location[0].mobile.split(' ').join('');
      return(
        <footer className="whatsapp-footer-container whatsapp-footer-container-small whatsapp-footer-container-large">
          <WhatsAppWidget textReplyTime="" companyName="Warely Corp" message="What can we do for you?" phoneNumber={whatsappNumber} />
        </footer>
      )
    } 
    return null
  }


  // if(qrToken && category) {
  return (
    <div className="main-section">
      <PopUp
        text={"Added to cart successfully!"}
        show={lshow}
        closeModal={() => setLshow(false)}
      />
      {/* <Carousel /> */}

      <ChooseModal
        setOrder={setOrder}
        setLshow={() => setLshow(true)}
        order={order}
        show={showModal}
        product={productDataModal}
        updateCart={props.updateCart}
        closeModal={() => setShowModal(false)}
        showPickupModal={() => showPickFunction()}
      />
      <Pickup
        setOrder={setOrder}
        order={order}
        show={showPickup}
        closeModal={() => setShowPickup(false)}
        showPickupDate={() => pickupDateFunction()}
      />

      <PickupDate
        setOrder={setOrder}
        order={order}
        show={showPickupDate}
        closeModal={() => setShowPickupDate(false)}
        showTrackOrder={() => trackOrderFunction()}
      />

      <TrackOrder
        show={showtrackOrder}
        closeModal={() => setShowTrackOrder(false)}
        trackOrderNow={() => showTrackOrderNowFunction()}
      />

      <TrackOrderNow
        show={showTrackNow}
        closeModal={() => setShowTracknow(false)}
        trackOrder={() => trackOrderPage()}
      />
      {/* trackOrderNow={() => showTrackOrderNowFunction()} */}


      {/* ul */}

      {business_id != null && location_id != null ?
        <div className="tab-gutter">
          <Tabs category={category} />
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-1"
              role="tabpanel"
              aria-labelledby="pills-1-tab"
            >
              {ProductsRender()}
              {/* {RenderWhatsappWidget()} */}
            </div>
          </div>
        </div>
        :
        <h2 className="text-center" style={{marginTop:"100px"}}>Restaurant is not ready</h2>}


    </div>
  );

};

const mapStateToProps = (state) => ({
  // menus_by_id: state.products.get_menus_by_id,
  product: state.products.product,
  category: state.products.category,
  loading: state.products.loading,
  errors: state.products.errors,
  business_location: state.products.business_location
  // qrToken: state.products.qrToken,
  // dine_in_detail : state.products.dine_in_detail
});
export default withTranslation()(
  connect(mapStateToProps, { getCategories, getProducts, getBusinessLocation })(MenuItems)
);
