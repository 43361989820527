import React, { useEffect, useState, useRef } from "react";
import { Modal, InputGroup, Form, FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Step.css";
import "../../assets/css/component.css";
import "../../assets/css/modal.css";
import close from "../../assets/svgs/close.svg";
import warley from "../../assets/svgs/warley.svg";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { checkContactNo } from "../../actions/authActions";
import { submitOrder } from "../../actions/productActions";
import "../../assets/css/form.css"

const Step2 = (props) => {
  const { register, handleSubmit, reset } = useForm();

  const [phoneCode, setPhoneCode] = useState('+65');
  const [phoneCodeVal, setPhoneCodeVal] = useState("SG")
  const phoneCodeElement = useRef(null)
  const [phoneNumber, setPhoneNumber] = useState('+65');

  const [phoneNoStatus, setPhoneNoStatus] = useState("ERROR");
  const [canSubmit, setSubmitState] = useState(false);

  const onSubmit = (data) => {
    // let oldData = { ...props.signInVal, ...data };
    props.setLoginValue({ 'contact_number': phoneCode + phoneNumber });
    props.checkContactNo({ 'contact_number': phoneCode + phoneNumber, 'country_code': phoneCodeVal, 'partial_number': phoneNumber, 'country_code_value': phoneCode });
    setPhoneNumber("");
    reset();
  };

  const countries = [
    {
      'name': 'SG',
      'phoneCode': '+65'
    },
    // { 
    //   'name': 'MY',
    //   'phoneCode': '+60'
    // }
  ]

  function updatePhoneCode(event) {
    var newPhoneCode = event.target.value;
    setPhoneCodeVal(event.target.name)
    setPhoneNumber(phoneNumber.replace(phoneCode, newPhoneCode));
    setPhoneCode(newPhoneCode);
  }

  function updatePhoneNumber(event) {
    var inputPhoneNum = event.target.value;

    setPhoneNumber(inputPhoneNum);

  }

  if (document.querySelector("#LoginBtn")){ //Check if not null
    if (canSubmit === false) {
      document.querySelector("#LoginBtn").style.opacity = 0.5
      document.querySelector("#LoginBtn").disabled = true
    } else {
      document.querySelector("#LoginBtn").style.opacity = 1.0
      document.querySelector("#LoginBtn").disabled = false
    }
  }

  useEffect(() => {
    if (props.isRegistered !== null) {
      if (props.isRegistered) {  // proceed login
        props.setShow(3);
      }
      else { // proceed sign up
        if (props.setSignup) {
          props.setShow(null);
          props.setSignup(1);
        }
      }
    }
  }, [props.isRegistered, props.setSignup])

  useEffect(() => {
    if (props.user && props.user?.email) {
      props.setLoginValue({
        'email': props?.user?.email,
        // 'contact_number': props?.user?.contact_number
      });
    }
  }, [props.user])
  

  useEffect(() => {
    const phoneNoRegex = /^(6|8|9)\d{7}$/;
    
    setSubmitState(false);
    console.log(phoneNumber);
    console.log(phoneCode); 

    console.log(canSubmit);

    if(phoneNumber == phoneCode || phoneNumber == "") {
      setPhoneNoStatus("");
    } else {
      if(phoneNoRegex.test(phoneNumber)){
        setPhoneNoStatus(<div className="footer-warley-description text-success">Valid Phone Number</div>)
        setSubmitState(true);
      } else {
        setPhoneNoStatus(<div className="footer-warley-description text-danger">Invalid Phone Number</div>)
      }

    }

  


  }, [phoneNumber])

  return (
    <Modal
      show={props.show}
      onHide={props.closeModal}
      dialogClassName="modal-md xs"
      contentClassName="xs"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal_form">
        <Modal.Body className="px-5 py-4">

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.closeModal}
          >
            <img src={close} width="20" alt="close" />
          </button>

          <div className="text-center mt-5">
            <img src={warley} width="263px" height="55px" alt="warley" />

            <div className="mt-5 pt-3">
              <div className="input-title mb-2">Phone number</div>
              <InputGroup className="mb-2 mr-sm-2 phone-group">
                <InputGroup.Prepend>
                  {/* <InputGroup.Text>@</InputGroup.Text> */}
                  <Form.Group controlId="exampleForm.ControlSelect1 px-1">
                    <Form.Control as="select" {...register("ccode")}
                      onChange={updatePhoneCode}
                    >
                      {countries.map(({ name, phoneCode }, index) => <option value={phoneCode} name={name}>{name}</option>)}
                      {/* <option>SG</option> */}
                    </Form.Control>
                  </Form.Group>
                </InputGroup.Prepend>
                <div ref={phoneCodeElement} className="phone_number">{phoneCode}</div>
                <FormControl
                  data-testid="phone_number_id"
                  autoComplete="off"
                  required
                  {...register("phone")}
                  id="inlineFormInputGroupUsername2"
                  placeholder=""
                  minLength="8"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={updatePhoneNumber}
                />
              </InputGroup>
              <div className="">{phoneNoStatus}</div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="warley-description">
                Warely Pass can be used to log in to any <br />
                restaurant partners with Warely.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer no">
          <button
            data-testid="log_in"
            type="submit"
            className="btn btn-danger rounded font-weight-bold btn-block py-3 f18"
            data-dismiss="modal"
            id="LoginBtn"
            disabled={true}
          >
            {" "}
            Log in / Sign up
          </button>
          <div className="powered my-4 w-100">Powered by Warely</div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
Step2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  // showNext: PropTypes.func.isRequired,
};
// export default Step2;

const mapStateToProps = (state) => ({
  isRegistered: state.auth.isRegistered,
  user: state.auth.user
});
export default withTranslation()(
  connect(mapStateToProps, { checkContactNo })(Step2)
);
