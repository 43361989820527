import axios from "axios";
import { SET_LOGIN_ERROR, SET_CURRENT_USER, CLEAR_STATE, GET_ERRORS, SET_REGISTERED_USER, GET_CUSTOMER_PROFILE, UPDATE_CUSTOMER_PROFILE } from "./types";
import setAuthToken from "../utils/setAuthToken";
import { setLoading } from "./commonActions";
import { getError } from "./errorActions";
const authURL = process.env.REACT_APP_API_AUTH_URL;
const client_id = process.env.REACT_APP_OAUTH_CLIENT_ID;
const secret = process.env.REACT_APP_OAUTH_SECRET_KEY;

const encoded = btoa(client_id + ":" + secret);

const refreshTokenData = {
  grant_type: "refresh_token",
  refresh_token:
    localStorage.headers &&
    JSON.parse(localStorage.getItem("headers")).data.token,
};
//Login User
export const loginUser = (userData, shouldRefresh) => (dispatch) => {
  // console.log("LoginUser");
  // console.log(userData, shouldRefresh);
  // console.log(authURL);

  dispatch(setLoading());
  axios
    .post(
      authURL,
      userData === null && shouldRefresh ? refreshTokenData : userData,
      // userData,
      {
        headers: {
          Authorization: `Basic ${encoded}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      var data = res.data;

      if(!data?.status) {
        var d = new Date();
        var n = d.getTime();
        data["created_at"] = n;
        
        var userData = {
          id: data.user_id,
          username: data.username,
          email: data.email,
          fname: data.first_name,
          lname: data.last_name,
          surname: data.surname,
          dob: data.dob,
          gender: data.gender,
          contact_id: data.contact_id,
          contactNo: data.contact_number,
          address: data.address_line_1,
          city: data.city,
          zip: data.zip_code,
          country: data.country
        }

        var returnData = {
          data: {
            user: userData,
            token: data.access_token,
            token_expired_at: data.expires_at
          }
        }

        localStorage.setItem("headers", JSON.stringify(returnData));
        setAuthToken(`Bearer ${data.access_token}`);
        dispatch(setCurrentUser(returnData));
      }
    })
    .catch((err) => {
      // console.log(err.response);
      let error;
      if (err.response && err.response.data) {
        error = err.response.data.message;
      } else {
        error = null;
      }
      dispatch(setCurrentUser({}));
      // console.log(error);

      // dispatch(sendError(error));
    });

  if (userData === null && shouldRefresh) {
     window.location.reload();
  }
};

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
    loading: false
  };
};

//Log user out
export const logoutUser = () => (dispatch) => {
  axios
    .get('/auth/customer_logout')
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      dispatch(sendError(err))
    });

  // Set current user to {} which will set isAuthenticated to false
  dispatch(setLoading());
      
  localStorage.removeItem("login");
  localStorage.removeItem("cart");
  // localStorage.removeItem("cart_data");

  window.location.href = '/?business_id=' + localStorage.business_id + '&location_id=' + localStorage.location_id;
  
  localStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

//Get customer profile

export const getCustomerProfile = () => (dispatch) => {
  dispatch(setLoading())

  // console.log("HUHDUDGUG");

  axios.get("/auth/customer_profile", 
  ).then((response)=>

    {
    console.log("Status: Success!!");

    dispatch({
      type: GET_CUSTOMER_PROFILE,
      payload: response.data,
      loading: false,
    })

  }
  ).catch((err)=>{
    // console.log(err);
    dispatch(sendError(err))
  })
}

export const updateCustomerProfile = (data) => (dispatch) => {
  dispatch(setLoading());

  
  axios.post('/auth/update_customer_profile', data)
  .then(response => {

    dispatch({
      type: UPDATE_CUSTOMER_PROFILE,
      payload: response.data,
      loading: false,
    })

  }).catch(error => {
    dispatch(sendError(error));
  });

}


export const sendError = (error) => {
  return {
    type: GET_ERRORS,
    payload: error,
  };
};

export const ClearState = () => dispatch => {
  dispatch({
    type: CLEAR_STATE
  })
};

export const checkContactNo  = (data) => (dispatch) => {
  dispatch(setLoading());
  
  axios
  .post("checkContactNo", {"contact_number": data.contact_number})
  .then((res) => {
    let isRegistered = false;
    let email = '';

    // console.log(res);

    if(res?.data?.message === 'false') {
      // login
      isRegistered = true;
      email = res?.data?.email;
    }

    dispatch ({
      type: SET_REGISTERED_USER,
      isRegistered: isRegistered,
      contact_number: data.contact_number,
      country_code_value: data.country_code_value,
      partial_number: data.partial_number,
      country_code:data.country_code,
      email: email,
      loading: false
    });
  })
  .catch((err) => dispatch(sendError(err)));
}

export const setLoginErrorMessage = (data) => (dispatch) => {
  dispatch({
    type: SET_LOGIN_ERROR,
    payload: data
  })
}
