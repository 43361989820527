import React, { useState, useEffect, useRef } from "react";
import { Card, InputGroup, Form, FormControl } from 'react-bootstrap';
import "./Account.css";
import "../../assets/css/modal.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SingleOrder, submitPayment } from "../../actions/productActions";
import { getCustomerProfile, updateCustomerProfile } from "../../actions/authActions";
import UpdateProfile from "../../components/Modals/UpdateProfile";

import { set, useForm } from "react-hook-form";

import axios from 'axios';
import ChangePassword from "../../components/Modals/ChangePassword";
import { setLoading } from "../../actions/commonActions";
const Account = (props) => {
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState('+65');  
  const [phoneCodeVal, setPhoneCodeVal] = useState("SG")
  const phoneCodeElement = useRef(null)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fname, setFname] = useState(null);
  const [lname, setlName] = useState(null);
  const [emaill, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);

  const [fnameStatus, setFnameStatus] = useState("ERROR");
  const [lnameStatus, setlnameStatus] = useState("ERROR");
  const [phoneNoStatus, setPhoneNoStatus] = useState("ERROR");

  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [popupTxt, setPopupTxt] = useState("")
  const [lshow, setLshow] = useState(false);

  const countries = [{
    'name':'SG',
    'code':'+65'
  }]


  const { register, handleSubmit } = useForm();
  const [canSubmit, setSubmitState] = useState(false);

  if (localStorage.headers) {
    var login = JSON.parse(window.localStorage.getItem('headers'))
    var userid = login.data.user.id
    var usertoken = login.data.token;
    console.log(usertoken)
    var firstname = login.data.user.fname;
    var lastname = login.data.user.lname;
    var contact_no = login.data.user.contactNo;
  }


  console.log(props);

  useEffect(() => {


    if(!load){
      props.getCustomerProfile();
      console.log(props.customer_profile);
      setLoad(true);
    }
    
    // const customer_profile = props.customer_profile;

    if(!props.loading && props.customer_profile != null){

      console.log(props);

      setUpdatedFirstName(props.customer_profile.first_name);
      setUpdatedLastName(props.customer_profile.last_name);
      setUpdatedPhone(props.customer_profile.contact_number);
      setEmail(props.customer_profile.email);

    }

     if(props.update_customer_profile !== null) {

        if(props.update_customer_profile.message === 'Update user profile successful.'){
        login.data.user.fname = updatedFirstName;
        login.data.user.lname = updatedLastName;
        localStorage.setItem('headers', JSON.stringify(login));
        setPopupTxt("Update user profile successful.");
        setLshow(true);
       
        } else {
        console.log(props.update_customer_profile.message);
        setPopupTxt("HEY!!!");
        setLshow(true);
      }
    }
   
    
  }, [props, load]);


  useEffect(() => {

    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    const phoneNoRegex = /^\+65(6|8|9)\d{7}$/;

    setSubmitState(false);

    if(updatedFirstName == "") {
      setFnameStatus("");
    } else {
      if(nameRegex.test(updatedFirstName)){
        setFnameStatus(<div className="footer-warley-description text-success">Valid Name</div>)
      } else {
        setFnameStatus(<div className="footer-warley-description text-danger">Invalid Name</div>)
      }
    }

    if(updatedLastName == "") {
      setlnameStatus("");
    } else {

      if(nameRegex.test(updatedLastName)){
      setlnameStatus(<div className="footer-warley-description text-success">Valid Name</div>)
    } else {
      setlnameStatus(<div className="footer-warley-description text-danger">Invalid Name</div>)
    }

    }

    if(updatedPhone == "") {
      setPhoneNoStatus("");
    } else {

      if(phoneNoRegex.test(updatedPhone)){
            setPhoneNoStatus(<div className="footer-warley-description text-success">Valid Phone Number</div>)
          } else {
            setPhoneNoStatus(<div className="footer-warley-description text-danger">Invalid Phone Number</div>)
          }

    }

    if(nameRegex.test(updatedFirstName)&&nameRegex.test(updatedLastName)&&phoneNoRegex.test(updatedPhone)){

      setSubmitState(true);
      
    }

  },[updatedFirstName, updatedLastName, updatedPhone]);


  function updateFirstName(event) {
    if (updatedFirstName === null) {
      setUpdatedFirstName(fname)
      console.log(fname);
    } else { setUpdatedFirstName(event.target.value) }
    console.log(updatedFirstName)
  }

  function updateLastName(event) {
    if (updatedLastName === null) {
      setUpdatedLastName(lname)
    } else { setUpdatedLastName(event.target.value) }
    console.log(updatedLastName)

  }

  function updateEmail(event) {
    if (updatedEmail === null) {
      setUpdatedEmail(emaill)
    } else { setUpdatedEmail(event.target.value) }
    console.log(updatedEmail)

  }

  function updatePhoneCode(event) {
    var newPhoneCode = event.target.value;
    setPhoneCodeVal(event.target.name)
    setPhoneNumber(phoneNumber.replace(phoneCode, newPhoneCode));
    setPhoneCode(newPhoneCode);
  }


  function updatePhone(event) {
    if (updatedPhone === null) {
      setUpdatedPhone(phone)
      console.log(phone)
    } else {setUpdatedPhone(event.target.value)}
  }


  //Validating the submit state
  if (document.querySelector("#updateButton")){ //Check if not null
    if (canSubmit === false) {
      document.querySelector("#updateButton").style.opacity = 0.5
      document.querySelector("#updateButton").disabled = true
    } else {
      document.querySelector("#updateButton").style.opacity = 1.0
      document.querySelector("#updateButton").disabled = false
    }
  }


  const onSubmit = () => {

    const data = {
      id: userid,
      first_name: updatedFirstName,
      last_name: updatedLastName,
      phone: updatedPhone,
    };

    props.updateCustomerProfile(data);

   

    // if(props.update_customer_profile.message === 'Update user profile successful.'){
    //   login.data.user.fname = updatedFirstName;
    //   login.data.user.lname = updatedLastName;
    //   localStorage.setItem('headers', JSON.stringify(login));
    //   setPopupTxt("Update user profile successful.");
    //   setLshow(true);
    //   props.closeModal();
    // } else {
    //   console.log(props.update_customer_profile.message);
    //   setPopupTxt("HEY!!!");
    //   setLshow(true);
    // }
    // console.log(updatedLastName);
    
    // const data = {
    //   id: userid,
    //   first_name: updatedFirstName,
    //   last_name: updatedLastName,
    //   phone: updatedPhone,
    // };

    // axios.post('/auth/update_customer_profile', data)
    //   .then(response => {

    //     if(response.data.message == 'Update user profile successful.'){
    //       login.data.user.fname = updatedFirstName;
    //       login.data.user.lname = updatedLastName;
    //       localStorage.setItem('headers', JSON.stringify(login));
    //       setPopupTxt("Update user profile successful.");
    //       setLshow(true);
    //       props.closeModal();

    //     }
        
    //     // console.log(login.data.user);
    //     // console.log("Status: ", response.status);
    //     // console.log("Data: ", response.data);
    //   }).catch(error => {
    //     console.error('Something went wrong!', error);
    //   });


  }

  const showModal = (event, val) => {
    console.log(event, val);

    event && event.preventDefault();
    setShow(val);
  };



  return (

    <>

      <UpdateProfile
        text={popupTxt}
        show={lshow}
        closeModal={() => setLshow(false)}
      />

      <ChangePassword
          show={show === 1 ? true : false}
          closeModal={() => setShow(null)}
      />

    <div className="main_section">

      <div className="payment-gutter">
        
        <div className="row">
          <div className="col-sm-12">
        <Card className="shadow p-3 mb-5 bg-white rounded">
          <form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body className="form-body">
            <div className="d-flex justify-content-center">
            <h2>User Information</h2>
            </div>
            <div class="form-group row">
              <div class="col-sm-12 col-md-6">
                <div className="mt-5">
                  <div className="control-label">First Name</div>
                  <div className="">
                    <input
                      type="text"
                      autoComplete="off"
                      onChange={updateFirstName}
                      value={updatedFirstName}
                      placeholder={fname}
                      className="form-control input input-full"
                      required
                    />
                  </div>
                  <div className="mt-2">{fnameStatus}</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div className="mt-5">
                  <div className="control-label">Last Name</div>
                  <div className="mb-2">
                    <input
                      type="text"
                      autoComplete="off"
                      onChange={updateLastName}
                      value={updatedLastName}
                      placeholder={lname}
                      className="form-control input input-full"
                      required
                    />
                  </div>
                  <div className="mt-2">{lnameStatus}</div>
                </div>
              </div>
          </div>

            <div className="form-group row">
              <div className="col-sm-12 col-md-6">
              <div className="control-label">Phone number</div>
              <InputGroup className="mb-2 mr-sm-2 phone-group">
                <InputGroup.Prepend>
              
                  <Form.Group controlId="exampleForm.ControlSelect1 px-1">
                    <Form.Control as="select" {...register("ccode")}
                      onChange={updatePhoneCode}
                    >
                      {countries.map(({ name, phoneCode }, index) => <option value={phoneCode} name={name}>{name}</option>)}
                  
                    </Form.Control>
                  </Form.Group>
                </InputGroup.Prepend>
                <FormControl
                  autoComplete="off"
                  {...register("phone")}
                  id="inlineFormInputGroupUsername2"
                  placeholder=""
                  minLength="8"
                  onChange={updatePhone}
                  value={updatedPhone}
                  required
                  style={{background:"white"}}
                />
              </InputGroup>
              <div class="mt-2">{phoneNoStatus}</div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div className="control-label">Email</div>
                <div className="mb-2">
                  <input
                    type="email"
                    autoComplete="off"
                    onChange={updateEmail}
                    value={updatedEmail}
                    placeholder={emaill}
                    disabled={true}
                    className="form-control input input-full"
                    required
                  />
                </div>

              </div>
            </div>

            <div className="row d-flex justify-content-center" style={{marginTop:"20px"}}>
                <p style={{color:"red", textDecoration:"underline"}} onClick={(event) => showModal(event, 1)}>Change password</p>
            </div>
            
            <div style={{ marginBottom: "4%", marginTop: "2%"}} className="form-action row d-flex justify-content-center">
              <button id="updateButton" type="submit" className="btn primary">
                Update changes
              </button>
            </div>
          </Card.Body>
          </form>
        </Card>
        </div>
        </div>
        </div>
      </div>

    </>
  );
};

Account.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  customer_profile: state.auth.customer_profile,
  update_customer_profile: state.auth.update_customer_profile,
  errors: state.auth.errors
});
export default withTranslation()(
  connect(mapStateToProps, {getCustomerProfile, updateCustomerProfile})(Account)
);
