import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Order-history.css";
import "../../assets/css/modal.css";

import { Accordion, Card, Button } from "react-bootstrap";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { MyOrders, Reorder} from "../../actions/productActions";

import { orderHistory } from "../../actions/pageAction";

const OrderHistory = (props) => {
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState(null);
  const [aclass, setAclass] = useState(-1);
  const [rorder, setRorder] = useState(-1);
  const { id } = useParams();
  const history = useHistory();

  let GetFormattedDate = (date) => {
    var newdate= date.getDate() + '/' + (date.getMonth() + 1) + '/' +  date.getFullYear();
    return newdate;
}

  let GetFormattedTime = (date) => {
    var hour = date.getHours()<10 ? "0"+date.getHours() : date.getHours();
    var minute = date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
    var newtime =  hour + ':' + minute;
    return newtime;
  }

  
  useEffect(() => {
  

    if (
      !props.loading && 
      props.order_history !== null
    ) {
      setOrderHistory(props.order_history);
      setLoaded(true);
      // id ? setOrderActive(2) : setOrderActive(props.order_history.id);
    }


    if (!load) {
      props.MyOrders();
      props.orderHistory();
      setLoad(true);
    }

    if (props.order_history!=null){
      // RenderCart(props.order_history);
      // RenderOrders();
    }
    

    console.log(props);
    console.log(orderHistory);

  }, [history, id, load, loaded, orders, orderHistory, props, rorder]);

  let variationRender = (variation) => {
    let html = [];
    if (variation && variation.length > 0) {
      let data = variation;
      Object.keys(data).forEach(function (key) {
        html.push(<span key={key} className="text-danger font-weight-bold border border-danger rounded d-flex justify-content-center" style={{width:"150px"}}>{data[key].name}</span>);
      });
    }
    return html;
  };

  let RenderCart = (cart) => {
    console.log(cart + "Hi");
    // console.log(cart[0].id);
    let html = [];
    if (cart && cart.length > 0) {
      let data = cart;
      
      Object.keys(data).forEach(function (key) {
        html.push(
          <tr key={key}>
            <td> {data[key].quantity} x</td>
            <td>
              <>
                {" "}
                {data[key] &&
                  data[key].name}{" "}
             {variationRender(data[key].adons)}{" "}
              </>
            </td>
            <td> {parseFloat(data[key].total_for_single_item).toFixed(2)}</td>
          </tr>
        );
      });
    }
    return html;
  };

  // let reorder = (e, id) => {
  //   props.Reorder(id);
  //   setLoaded(false);
  //   setRorder(id);
  //   e.target.value = "In progress";
  // };

  const setOrderActive = (index) => {
    console.log("Active orders" + index);
    setAclass(parseInt(index));
  };

  // const checkJson = (text) => {
  //   if (
  //     /^[\],:{}\s]*$/.test(
  //       text
  //         .replace(/\\["\\\/bfnrtu]/g, "@")
  //         .replace(
  //           /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
  //           "]"
  //         )
  //         .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  //     )
  //   ) {
  //     return true;
  //     //the json is ok
  //   } else {
  //     //the json is not ok
  //     return false;
  //   }
  // };

  const conAddress = (address) => {
    let re_address = [];
    if (address !== null && address !== "") {
      let address_arr = JSON.parse(address);
      re_address.push(
        <>
          <p>
            {address_arr.u_no},{address_arr.b_name},{address_arr.pcode}
          </p>
          <span>{address_arr.note}</span>{" "}
        </>
      );
    }

    return re_address;
  };
  let RenderOrders = () => {
    let html = [];
    if (orderHistory && orderHistory.length > 0) {
      orderHistory.map((key, index) => {
        console.log(key.id + "WOKRING");
        var inkey = parseInt(key.id);

        var cdate;
        var adate;
        if(key.transaction_date !== null){
          let t = key.transaction_date?.split(/[- :]/)
          cdate = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5])
          console.log(cdate)



        } else {
          cdate = null;
        }
       
        if(key.d_date !== null){
          adate = new Date(key.d_date);
        } else {
          adate = null;
        }

        let htmltemp = (
          <Card key={inkey}>
            <Accordion.Toggle
              as={Button}
              onClick={() => setOrderActive(inkey)}
              variant={aclass === inkey ? "danger" : "light"}
              eventKey={inkey}
            >
              <span className="date">{cdate != null ? GetFormattedDate(cdate) : ""}</span>
              <span className="time pl-2">{cdate != null ? GetFormattedTime(cdate) : ""}</span>
              {key.refund_all ? <span className={aclass === inkey ? "void_light" : "void"}> Void </span> : ""}

              <i className="fa fa-caret-down"></i>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={inkey}>
              <Card.Body>
                <div className="row">
                  <div className="col-md-6">
                    <div className="table-box">
                      <table className="table">
                        <tbody>{RenderCart(key.item)}</tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="item-box">
                      <div className="form-group">
                        <label className="control-label">
                          {key.type_for_api === "Delivery"
                            ? " Deliver to"
                            : "Pick up from"}
                        </label>
                        <span className="form-control-static">
                          {key?.type_for_api === "Delivery"
                            ? key?.d_address : key.business_location_name_for_pickup}
                        </span>
                      </div>
                      {key.type_for_api === "Delivery" ? (
                        <>
                        <div className="form-group">
                          <label className="control-label">
                            Estimated Arrival
                          </label>
                          <span className="form-control-static disabled">
                            <span className="date">{ key?.estimated_arrival_time?.split(" ")[0] }</span>
                            <span className="time">
                               { key?.estimated_arrival_time?.split(" ")[1] }
                            </span>
                          </span>
                        </div>
                        <div className="form-group">
                          {/* <label className="control-label">
                            Estimated Arrival
                          </label> */}
                          {/* <span className="form-control-static disabled">
                            <span className="date">{adate != null ? GetFormattedDate(adate):""}</span>
                            <span className="time">
                              {key.d_time && key.d_time}
                            </span>
                          </span> */}
                          <label className="control-label">
                            Arrived
                          </label>
                          <span className="form-control-static">
                            <span className="date">{ key?.updated_at?.split(" ")[0] }</span>
                            <span className="time">
                               { key?.updated_at?.split(" ")[1] }
                            </span>
                          </span>
                        </div>
                        </>
                      ) : (
                        <div className="form-group">
                          <label className="control-label">Pickup time</label>
                          <span className="form-control-static">
                            <span className="date">{adate != null ? GetFormattedDate(adate) : ""}</span>
                            <span className="time">
                              {key.d_time && key.d_time}
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="form-group">
                        <label className="control-label">Payment</label>
                        <span className="form-control-static">
                      
                            
                            { key.paymentType === "cash" || key.paymentType === "paynow" ? 
                              <span className="form-control-static">
                                {key.paymentType.toUpperCase()}
                              </span>
                              : <span className="form-control-static">
                                {key.card_type ? key.card_type.toUpperCase() : ""} { key.card_type === "paynow" ? "" : key.last_4_card_digits}
                              </span>
                            }

                            
                        </span>
                      </div>
                      
                      {
                      key.type_for_api === "Delivery" 
                      ?
                      <div className="form-group">
                        <label className="control-label">Delivery Status</label>
                        <span className="form-control-static">
                            {key?.lalamove_status}
                        </span>
                      </div>
                      : ""
                      }
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-action t3">
                      {key.status === 0 ? (
                        <input
                          type="button"
                          className="btn primary"
                          onClick={(e) => reorder(e, key.id)}
                          value="REORDER"
                        />
                      ) : (
                        <span>In progress</span>
                      )}
                    </div>
                  </div> */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
        if (id) {
          if (parseInt(id) === inkey) html.push(htmltemp);
        } else {
          html.push(htmltemp);
        }
      });
    }
    return html;
  };

  return (
    <div className="main_section">
      <div className="order-gutter ispd">
        {orderHistory && orderHistory.length > 0 ? (
          <Accordion defaultActiveKey={orderHistory[0].id}>
            {" "}
            {RenderOrders()}
          </Accordion>
        ) : (
          <h5 className="text-center">No Order Placed yet</h5>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  orders: state.products.orders,
  reorders: state.products.reorders,
  loading: state.products.loading,
  order_history: state.page.order_history
});

export default withTranslation()(
  connect(mapStateToProps, { MyOrders, Reorder, orderHistory })(OrderHistory)
);
