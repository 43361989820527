// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../svgs/delete.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../svgs/search.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../svgs/check.svg";
import ___CSS_LOADER_URL_IMPORT_3___ from "../images/close.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".delete-icon {\n  width: 18px;\n  height: 20px;\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;\n}\n\n.search-icon {\n  width: 26px;\n  height: 26px;\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 0 0;\n}\n\n.double-check-icon {\n  width: 48px;\n  height: 34px;\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 0 0;\n}\n\n.close span {\n  width: 20px;\n  height: 22px;\n  display: block;\n  font-size: 0;\n  opacity: 1;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat 0 0;\n}\n", "",{"version":3,"sources":["webpack://src/assets/css/icon.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iEAAmD;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iEAAmD;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iEAAkD;AACpD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,UAAU;EACV,iEAAoD;AACtD","sourcesContent":[".delete-icon {\n  width: 18px;\n  height: 20px;\n  display: block;\n  background: url(\"../svgs/delete.svg\") no-repeat 0 0;\n}\n\n.search-icon {\n  width: 26px;\n  height: 26px;\n  display: block;\n  background: url(\"../svgs/search.svg\") no-repeat 0 0;\n}\n\n.double-check-icon {\n  width: 48px;\n  height: 34px;\n  display: block;\n  background: url(\"../svgs/check.svg\") no-repeat 0 0;\n}\n\n.close span {\n  width: 20px;\n  height: 22px;\n  display: block;\n  font-size: 0;\n  opacity: 1;\n  background: url(\"../images/close.png\") no-repeat 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
