import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../../assets/css/component.css";
import "./signup.css";
import "../../assets/css/modal.css";
import close from "../../assets/svgs/close.svg";
import warley from "../../assets/svgs/warley.svg";
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Step1 from '../Modals/Step1';
import Step2 from '../Modals/Step2';
import Step3 from '../Modals/Step3';
import { withTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

const removeCharAt = (string, index) => {
  const arr = string.split("")
  arr.splice(index, 1)
  return arr.join("")
}

const insert = (string, index, newString) => {
  if (index >= 0) {
    return string.substring(0, index) + newString + string.substring(index)
  }
}

const validDay = (string) => {
  if (string.length === 2) {
    const day = string.substring(0, 2)
    if (isNaN(day)){
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

const validMonth = (string) => {
  if (string.length === 5) {
    const month = string.substring(3, 5)
    if (isNaN(month)){
      return false
    } else if (string.substring(2,3)==="/"){
      return true
    }
  } else {
    return false
  }
}



const Signup = (props) => {
  const { register, reset, handleSubmit } = useForm();
  const [checked, setChecked] = useState(1);
  const [signInVal, setSignInVal] = useState(null);
  const [show,setShow] = useState(null);
  const [clLogin, setClogin] = useState(false);

  const defaultError = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthDay: ""
  }

  const [errors, setErrors] = useState(defaultError)

  const [phoneCode, setPhoneCode] = useState("+65")
  
  //Element references
  const mobileNumberElement = useRef(null)
  const countryCodeElement = useRef(null)
  const [DOB, setDOB] = useState("")
  const stringPlacement = useRef(0)
  const keyCode = useRef("")
  const DOBElement = useRef(null)

  const onSubmit = (data) => {

    setErrors({...defaultError})
    
    const newError = {
      birthDay: "",
      phoneNumber: ""
    }

    if (!(/^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/g.test(DOBElement.current.value))) {
      newError["birthDay"] = "Invalid birth day"
    } if (!/^(6|8|9)\d{7}$/.test(mobileNumberElement.current.value)) {
      newError["phoneNumber"] = "Invalid phone number"
    } else {
      const dob = DOBElement.current.value.split("/")
      let newData = { 
        ...props.signupVal, 
        "last_name": data.last_name, 
        "first_name": data.first_name, 
        "gender": (checked === 1 ? "Male" : "Female"),
        "mobile": props?.user?.contact_number ? props?.user?.contact_number : phoneCode+mobileNumberElement.current.value,
        "contact_number": props?.user?.contact_number ? props?.user?.contact_number : phoneCode+mobileNumberElement.current.value,
        "dob": dob[2] + "-" + dob[1] + "-" + dob[0]
      };
      props.setSignup(newData);
      props.nextStep();
      reset();
    }
    setErrors({...errors, ...newError})
    
  };
  const showModal = (event, val) => {
      event && event.preventDefault();
      setShow(val);
  };

  const ChnageChecked = (value) => {
    setChecked(value);
  };
  const setLoginValue = (data) => {
    setSignInVal(data);
  };
  const loginClicked = (data) => {
    if (data.password && data.phone && !clLogin) {
      props.loginUser(data, true);
      setClogin(true);
    }
  };
  const CloseloggedIn = () => {
    setShow(null);

  };

  function handleClick(event){
   event.preventDefault();
   props.closeModal()
    
   showModal(event,1)
  }


  const countries = [
    { 
      'name': 'SG',
      'phoneCode': '+65'
    }
  ]

  // useEffect(()=>{
  //   if (keyCode.current === "Backspace") {
  //     setDOB(removeCharAt(DOB, stringPlacement.current))
  //   } else {
      
  //   }
    
    
  // }, [DOB])
  

  return (
    <Modal
      show={props.show}
      onHide={props.joinus ? ()=>{} : props.closeModal}
      dialogClassName="modal-md xs"
      contentClassName="xs"
      centered
    >
    <Step1 
          show={show === 1 ? true : false}
          closeModal={() => setShow(null)}
          showNext={(event) => showModal(event, 2)}
        />

        <Step2
          show={show === 2 ? true : false}
          closeModal={() => setShow(null)}
          signInVal={signInVal}
          setLoginValue={setLoginValue}
          showNext={(event) => showModal(event, 3)}
        />

       <Step3
          signInVal={signInVal}
          loginClicked={loginClicked}
          setLoginValue={setLoginValue}
          show={show === 3 ? true : false}
          closeModal={CloseloggedIn}
        />
      <form onSubmit={handleSubmit(onSubmit)} className="modal_form">
        <Modal.Body className="px-5 py-4">
          {
            props.joinus ? 
              ""
            :
              <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
              data-testid="signup_close"
              >
                <img src={close} width="20" alt="close" />
              </button>
          }
          <div className="text-center mt-5">
            <img src={warley} width="263px" height="55px" alt="warley" />
            <div className="signup-subtitle">Sign up for WARELY account</div>

            <div className="">
              <div className="input-title mb-2">First name</div>
              <div className="mb-2">
                <input
                  minLength={3}
                  required
                  type="text"
                  autoComplete="off"
                  {...register("first_name")}
                  className="form-control-lg input input-full"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="input-title mb-2">Last name</div>
              <div className="mb-2">
                <input
                  minLength={3}
                  required
                  type="text"
                  autoComplete="off"
                  {...register("last_name")}
                  className="form-control-lg input input-full"
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="input-title mb-2">Gender</div>

              <div className="app-radio-inline">
                <Row className="gender">
                  <Col className="6">
                    <Button
                      size="lg"
                      onClick={() => ChnageChecked(1)}
                      variant={checked === 1 ? "danger" : "outline-danger"}
                    >
                      {" "}
                      Male
                    </Button>
                  </Col>
                  <Col className="6">
                    <Button
                      onClick={() => ChnageChecked(2)}
                      size="lg"
                      variant={checked === 2 ? "danger" : "outline-danger"}
                    >
                      {" "}
                      Female
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="mt-3 pt-2">
              <div className="input-title mb-2">Phone number</div>
              <InputGroup className="mb-2 mr-sm-2 phone-group">
                <InputGroup.Prepend>
                  {/* <InputGroup.Text>@</InputGroup.Text> */}
                  <Form.Group controlId="exampleForm.ControlSelect1 px-1">
                    <Form.Control ref={countryCodeElement} as="select">
                      {countries.map(({name, phoneCode}, index) => <option value={phoneCode} name={name}>{name}</option>)}
                    </Form.Control>
                  </Form.Group>
                </InputGroup.Prepend>
                <div className="mobile_number">{props?.user?.country_code_value ? props?.user?.country_code_value : phoneCode }</div>
                  { props?.user?.partial_number ?
                      <FormControl
                      type="text"
                      minLength={8}
                      maxLength={8}
                      id="mobileInputElement"
                      placeholder={props?.user?.partial_number}
                      ref={mobileNumberElement}
                      value={props?.user?.partial_number}
                      disabled={props?.user?.country_code_value}
                      style={{padding:"0.300rem 0.40rem", border:0, borderColor:"#E9ECEF"}}
                      />
                  :

                    <FormControl
                      onChange={(event)=>{
                        console.log(mobileNumberElement.current.value)
                      }}
                      type="text"
                      minLength={8}
                      maxLength={8}
                      id="mobileInputElement"
                      ref={mobileNumberElement}
                      style={{padding:"0.300rem 0.40rem", border:0, borderColor:"#E9ECEF", backgroundColor:"#E9ECEF"}}
                    />
                    
                  }
                  <div className="text-danger footer-warley-description">{errors.phoneNumber}</div>
              </InputGroup>
            </div>

            <div className="">
              <div className="input-title mb-2">Birthday</div>
              <div className="mb-2">
                <input
                  maxLength={10}
                  minLength={3}
                  required
                  placeholder="DD / MM / YYYY"
                  type="text"
                  autoComplete="off"
                  ref={DOBElement}
                  onChange={(event)=>{
                    event.target.value = event.target.value.replace(/[^0-9\/]/, "")
                    if (!(keyCode.current === "Backspace")) {
                      if (validDay(event.target.value)) {
                        event.target.value = event.target.value + "/"
                      } if (validMonth(event.target.value)) {
                        event.target.value = event.target.value + "/"
                      }
                    }
                  }}
                  onKeyDown={(event)=>{
                    if (event.key === 'Backspace') {
                      keyCode.current = 'Backspace'
                    } else {
                      keyCode.current = ''
                    }
                    
                  }}
                  className="form-control-lg input input-full"
                  id="dob"
                />
              </div>
              <div className="text-danger footer-warley-description">{errors.birthDay}</div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="footer-warley-description">
                Once succesfully created, you can use WARELY account across all
                partners with WARELY
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            type="submit"
            className="btn btn-danger rounded font-weight-bold btn-block py-3 f18"
            data-dismiss="modal"
            
          >
            Continue
          </button>
          {/* <p>Already a member? <Link 
            aria-label="Close" 
            onClick={handleClick}
            >Log in</Link></p> */}
          <div className="powered my-4 w-100">Powered by Warely</div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default withTranslation()(
  connect(mapStateToProps)(Signup)
);


