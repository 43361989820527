import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import TimePicker from 'react-time-picker';
import "react-time-picker/dist/TimePicker.css";
import "../../assets/css/modal.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PopUp from "../Modals/Popup";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import "../../assets/css/form.css"

const renderAddress = (props, order) => {

  if (props.method === 1) {
    if (props.address) { //Check if no address
      const address = props.address.full_address
      return address
    } else {
      return "Please select Address"
    } 
  } else if (props.method === 2) {
    if (order.out_name) {
      return order.out_name
    } else if (localStorage.out_name) {
      return localStorage.out_name
    } else {
      return "Please select Outlet"
    }
  }
}

// const calculateTime = (dateObj, offset) => {
//   let hours = dateObj.getHours()
//   let minutes = dateObj.getMinutes()

//   minutes += offset

//   if (minutes > 60) {
//     hours += 1
//     minutes = minutes - 60
//   }

//   let newTime = hours.toString() + ":" + minutes.toString()

//   return newTime;
// }


const PickupDate = (props) => {
  let order = props.order;

  let show = props.show;
  const [load, setLoad] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [lshow, setLshow] = useState(false);

  const dateObj = new Date()
  const [date, setDate] = useState(new Date());
  const [timeRange, setRange] = useState(`Select your ${props.method === 1 ? "Delivery": "Pickup" } Time`);
  const [currentOrder, setOrder] = useState(props.method);
  const [timeRangeDP, setTimeRangeDP] = useState([]);

  useEffect(() => {
    var d = new Date(); // for now
    var h = d.getHours(); // => 9
    var m = d.getMinutes(); // =>  30

    var timeNow = parseFloat(h + '.' + m).toFixed(2);
    // console.log(timeNow);

    var result = props.outlates?.business_location?.filter(obj => {
      return obj.id === props.order.out_id;
    });

    if(result?.length > 0) {
      var collectTimeRange = [];
      var operationTimeRange = result[0].custom_field3;

      // set time range
      operationTimeRange.forEach(function (element, index){
        for (let i = element[0]; i < element[1]; i++) {
          var startEndTime = [i, i+1];

          // var splitted1 = timeNow.split('.');
          // var splitted2 = (parseFloat(i).toFixed(2)).split('.');
          // var timeNow1 = splitted1[0]+splitted1[1];
          // var startTime = splitted2[0]+splitted2[1];

          // console.log(time1, time2, time1 < time2);

          // console.log(timeNow1 < startTime);

          // if (time1 < time2) {
          // //   var diff = getTimeDiff((parseFloat(i).toFixed(2)), timeNow.replace('.', ':'), 'm');
          // //   console.log(diff);
          // } else {
          // //   var diff1 = getTimeDiff('24:00', timeNow, 'm'); 
          // //   var diff2 = getTimeDiff((parseFloat(i).toFixed(2)).replace('.', ':'), '00:00', 'm');
          // //   var totalDiff = diff1+diff2;
          // //   console.log(totalDiff);
          // };

          if(parseFloat(timeNow) < i) {
            startEndTime.forEach(function (se, seIndex) {
              if(se > element[1]) { se = element[1]; }
  
              if(Math.floor(se) < 12) { 
                startEndTime[seIndex] = (parseFloat(se).toFixed(2)).replace('.', ':') + ' AM'; 
              }
              else {
                if(Math.floor(se) > 12) { se -= 12; } // minus 12 hours
                startEndTime[seIndex] = (parseFloat(se).toFixed(2)).replace('.', ':') + ' PM'; 
              }
            });
            collectTimeRange.push(startEndTime[0] + ' - ' + startEndTime[1]);
          }
        }
      });

      setTimeRangeDP(collectTimeRange);

      if(collectTimeRange?.length > 0) {
        setRange(collectTimeRange[0]);
      }
      // console.log(collectTimeRange);
    }
  }, [props, order])

  useEffect(() => {
    // console.log(props);
    if (
      !props.loading &&
      props.norders &&
      props.norders !== null &&
      props.norders.success &&
      load
    ) {
      // console.log("_____orders____");
      //console.log(props.orders);
      // props.showTrackOrder();
      setLoad(false);
    }
  }, [props.outlates?.business_location, order, load]);


  const changeDate = (date) => {
    setDate(date);

    let oldOrder = order;
    oldOrder["date"] = date.toDateString();
    props.setOrder(oldOrder);
    console.log(date)
    // const monthNames = ["January", "February", "March", "April", "May", "June",
    //   "July", "August", "September", "October", "November", "December"
    // ];
    
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset*60*1000));
    date = date.toISOString().split('T')[0];
    

    // console.log(date);

    // localStorage.setItem("date", date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear());
  }

  const changeTime = (time) => {
    // console.log(time, order);
    
    let oldOrder = order;

    if(time != "Select your Pickup Time") {
      setRange(time); 
      oldOrder["time"] = time;
    }

    props.setOrder(oldOrder);
    // localStorage.setItem("time", time);

    // console.log(time, oldOrder, order);
  }


  const ProceedOrder = () => {
    let oldData = order;
    // console.log(oldData["date"]);
    // console.log(oldData[""]);

    // console.log(timeRange);


    // console.log(props.cartId());
    if (oldData["date"] && oldData["time"]) {
      
      if (props.method===2) {
        if (localStorage.date){
          localStorage.removeItem("date")
        }
        console.log("SET!!!!!!!!!!!!!")
        localStorage.setItem("date", date.toLocaleDateString("en-US"))
      }

      console.log("Place order1");
      oldData["order_type"] = 2;

      //  oldData["cart_id"] = props.cartId();
      // props.placeOrder(oldData);
      // setLoad(true);
      props.setShowPayment(true)
      props.closeModal();
    } 
    else if (date && timeRange) { //Set the button elements to the default value initially
      changeTime(timeRange)
      changeDate(date)
      props.closeModal();
    } 
    else {
      setPopupText("Select Date and Time!");
      setLshow(true);
    }

    //props.showTrackOrder();
  };

  const returnTimeExp = (data, setName) => {
    // return (data.map((item, index)=>(
    //   <Dropdown.Item onClick={()=>{changeTime(item.time); setName(item.time)}} eventKey={index} style={{textAlign:"center", width:"100%", color: "black" }}>
    //     {item.time}
    //   </Dropdown.Item>
    // )))

    return (data.map((item, index)=>(
      <Dropdown.Item onClick={()=>{changeTime(item); setName(item)}} eventKey={index} style={{textAlign:"center", width:"100%", color: "black" }}>
        {item}
      </Dropdown.Item>
    )))
  } 

  if(currentOrder !== props.method){ //Check if user changed order type and set time, date to default
    setOrder(props.method)
    setRange(`Select your ${props.method === 1 ? "Delivery": "Pickup" } Time`)
  }

  return (
    <Modal
      show={show}
      onHide={props.closeModal}
      dialogClassName="modal-md modal-dialog-scrollable"
      contentClassName="shrink modal_form"
      centered
      size="sm"
    >
      <PopUp
        text={popupText}
        show={lshow}
        closeModal={() => setLshow(false)}
      />
      <Modal.Header>
        <h4 className="modal-title">
          {" "}
          {props.method === 1 ? "Your delivery time" : "Pick up"}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="no">
        <div className="panel-ember">
          <label className="control-label">
            {" "}
            {props.method === 1 ? "Selected address " : "Selected outlet"}{" "}
          </label>
          <span 
            className="btn secondry d-block" 
            onClick={()=>{
              // console.log(order.out_name)
            }} style={{pointerEvents:"none"}}>
            {renderAddress(props, order)}
          </span>
        </div>
        <div className="pdbrd">
          <label className="control-label no">
            Select your {props.method === 1 ? 'delivery' : 'pick up'} <span>Date</span>
          </label>

          <DatePicker disabled={true} className="form-control" selected={date} onChange={changeDate} />


        </div>
        <div className="pdbrd no">
          <label className="control-label no" style={{display:"block"}}>
            Select your {props.method === 1 ? 'delivery' : 'pick up'} <span>Time</span>
          </label>
          
          <Dropdown style={{width:"100%"}}>
              <Dropdown.Toggle id="dropdown-item-button" className="dropdown-toggle border-0" style={{width:"100%",backgroundColor: "#f1f1f1", color: "black" }}>
                {timeRange}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{width:"100%", backgroundColor: "white" }}>
                {returnTimeExp(timeRangeDP, setRange)}
              </Dropdown.Menu>
            </Dropdown> 


        </div>
        
      </Modal.Body>
      <Modal.Footer className="form-action no">
        <button className="btn lg primary d-block" onClick={ProceedOrder}>
          Proceed
        </button>
      </Modal.Footer>
    </Modal>
  );
};

PickupDate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outlates: state.products.outlates,
  norders: state.products.order,
  loading: state.products.loading,
});
export default withTranslation()(connect(mapStateToProps, {})(PickupDate));
