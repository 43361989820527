export const defaultParams = {
    "business_id":2,
   "surname":null,
   "first_name":"test1",      
   "last_name":null,
   "email":"test@hotmail.com",
   "is_active":"inactive",
   "allow_login":"1",
   "username":null,
   "password":"12345",
   "confirm_password":"12345",
   "pin":"0000",
   "role":"",
   "access_all_locations":"access_all_locations",
   "selected_contacts":1,
   "cmmsn_percent":null,
   "max_sales_discount_percent":null,
   "dob":null,
   "gender":null,
   "marital_status":null,
   "blood_group":null,
   "contact_number":null,
   "alt_number":null,
   "family_number":null,
   "fb_link":null,
   "twitter_link":null,
   "social_media_1":null,
   "social_media_2":null,
   "custom_field_1":null,
   "custom_field_2":null,
   "custom_field_3":null,
   "custom_field_4":null,
   "guardian_name":null,
   "id_proof_name":null,
   "id_proof_number":null,
   "permanent_address":null,
   "current_address":null,
   "bank_details":{
      "account_holder_name":null,
      "account_number":null,
      "bank_name":null,
      "bank_code":null,
      "branch":null,
      "tax_payer_id":null
   },
   "type":"customer",
   "contact_type_radio":"individual",
   "contact_id":null,
   "customer_group_id":null,
   "supplier_business_name":null,
   "prefix":null,
   "middle_name":null,
   "mobile":"12345678",
   "alternate_number":null,
   "landline":null,
   "crm_source":null,
   "crm_life_stage":null,
   "position":null,
   "tax_number":null,
   "opening_balance":"0",
   "pay_term_number":null,
   "pay_term_type":null,
   "credit_limit":null,
   "address_line_1":null,
   "address_line_2":null,
   "city":null,
   "state":null,
   "country":null,
   "zip_code":null,
   "custom_field1":null,
   "custom_field2":null,
   "custom_field3":null,
   "custom_field4":null,
   "custom_field5":null,
   "custom_field6":null,
   "custom_field7":null,
   "custom_field8":null,
   "custom_field9":null,
   "custom_field10":null,
   "shipping_address":null
}