import axios from "axios";
import {
  ORDER_HISTORY, PAGE_LOAD, TRACK_ORDER
} from "./types";

export const orderHistory = () => (dispatch) => {
    let data = {
      business_id: localStorage.business_id,
      location_id: localStorage.location_id,
      contact_id: localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user?.contact_id):0
    }
    dispatch({
        type: PAGE_LOAD
    })
    axios
    .post("/track_order_history",data)
    .then((response)=>
      dispatch({
        type: ORDER_HISTORY,
        payload: response.data.sells,
      })
    )
    //.catch((err) => dispatch(getError(err)));
}

export const trackOrder = (load = true) => (dispatch) => {
    console.log(localStorage.getItem("business_id"))
  
    let data = {
      business_id: localStorage.getItem('business_id'),
      location_id: localStorage.getItem('location_id'),
      contact_id: localStorage.headers != undefined?(JSON.parse(localStorage.headers)?.data?.user?.contact_id):0
    }
  
    if (load){
      dispatch({
          type: PAGE_LOAD
      })
    }
    
  
    axios.post("/track_order", data).then((response)=>{
      dispatch({
        type: TRACK_ORDER,
        payload: response.data.sells,
        loading: false
      })
    })
    // .catch((err)=>{
    //   dispatch(getError(err))
    // })
}