import { GET_POSTAL_REQUEST } from "../actions/types";
import { GET_POSTAL_SUCCESS } from "../actions/types";
import { GET_POSTAL_FAIL } from "../actions/types";
import { GET_OUTLET_DETAILS_SUCCESS } from "../actions/types";
import { GET_OUTLET_DETAILS_FAIL } from "../actions/types";
import { GET_OUTLET_DETAILS } from "../actions/types";

const initialState = {
    postalCodes: [],
    loading: false,
    error: "",
    outlet: null,
    address: null
}

const postalCodeReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_POSTAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_POSTAL_SUCCESS:
            return {
                ...state,
                loading: false,
                postalCodes: action.payload
            }
        case GET_POSTAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_OUTLET_DETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_OUTLET_DETAILS_SUCCESS:
            return {
                ...state,
                outlet: action.payload,
                loading: false
            }
        case GET_OUTLET_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

export default postalCodeReducer
