import {
    ORDER_HISTORY,
    TRACK_ORDER,
    PAGE_LOAD
  } from '../actions/types';


  
  const initialState = {
      loading: false,
      order_history: null,
      track_order: null,
  };
   
  export default (state = initialState, action) => {
      switch (action.type) {
        case PAGE_LOAD:
            return {
                ...state,
                loading: true
            }
        
        case ORDER_HISTORY:
            return {
                ...state,
                order_history: action.payload,
                loading: false,
            };
        case TRACK_ORDER:
            return {
                ...state,
                track_order: action.payload,
                loading: false
            }
        default:
          return state;
      }
  };