import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./Track-order.css";
import "../../assets/css/modal.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { trackOrder } from "../../actions/productActions";
import SingleOrder from "./SingleOrder";


import { useForm } from "react-hook-form";

const TrackOrder = (props) => {
  const [order, setOrder] = useState(null);
  const [load, setLoad] = useState(false);
  
  const { id } = useParams();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const elements = [] //Will be used to store order information

  const [hasPickUpOrder, setHasPickUpOrder] = useState(false);

  

  if (props.order) {
    if (props.order.length !== 0 ) {
      for (let i = 0; i < props.order.length; ++i) {
        // console.log(props?.order[i]?.type_for_api);
        if(!hasPickUpOrder && props?.order[i]?.type_for_api !== "Delivery") {
          setHasPickUpOrder(true);
        }
        elements.push(<SingleOrder order={props?.order[i]}/>) //store orders into array
      }

      // if(!hasPickUpOrder){
      //   elements.push(<h5 className="text-center">No pending order at this moment</h5>);
      // }
    }
    else {
      elements.push(<h5 className="text-center">No pending order at this moment</h5>);
    }
  }

  useEffect(() => {
    props.trackOrder()

    let track = setInterval(()=>{
      props.trackOrder(false)
    }, 10000)

    return () => {
      clearInterval(track)
    }
  }, []);

  
  
  

  const conAddress = (address) => {
    let re_address = [];
    if (address !== null && address !== "") {
      let address_arr = JSON.parse(address);
      re_address.push(
        <>
          <p>
            {address_arr.u_no},{address_arr.b_name},{address_arr.pcode}
          </p>
          <span>{address_arr.note}</span>{" "}
        </>
      );
    }

    return re_address;
  };
  const conDate = (conDate) => {
    let re_date = "";
    if (conDate !== null) {
      let date = new Date(conDate);

      return date.toDateString();
    }
    return re_date;
  };
  
  return (
    <>
      {elements}
    </>
  );
};

const mapStateToProps = (state) => ({
  order: state.products.track_order,
  loading: state.products.loading
});
export default withTranslation()(
  connect(mapStateToProps, { trackOrder })(TrackOrder)
);
