import { SET_LOGIN_ERROR, SET_CURRENT_USER ,CLEAR_STATE, GET_ERRORS, SET_REGISTERED_USER, GET_CUSTOMER_PROFILE, UPDATE_CUSTOMER_PROFILE} from '../actions/types';
import isEmpty from './is_empty';

const initialState = {
    isRegistered: null,
    isAuthenticated : false,
    user : {},
    customer_profile: null,
    update_customer_profile: null,
    error : null,
    userMessage : null
};
 
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  
    switch (action.type) {

      case CLEAR_STATE:
        return {
          ...state,
          isRegistered: null,
          user: null,
          errors:null,
          loading: false,
        };
      case SET_CURRENT_USER: 
        return {
           ...state,
           isAuthenticated: !isEmpty(action.payload),
           user: action.payload,
           error : null,
           loading: false,
      };
      case SET_LOGIN_ERROR:
        return {
          ...state,
          userMessage: action.payload,
          user :null,
          isAuthenticated :false,
          loading: false,
        }
      case GET_CUSTOMER_PROFILE:
        // console.log(action.payload);  
        return{
          ...state,
          customer_profile: action.payload,
          error: null,
          loading: false,
      };

      case UPDATE_CUSTOMER_PROFILE:
        return{
          ...state,
          update_customer_profile: action.payload,
          error: null,
          loading: false,
        };

      case GET_ERRORS:
        // console.log(action.payload);
      return {
        ...state,
        errors: action.payload,
        user :null,
        isAuthenticated :false,
        loading: false,
      };

      case SET_REGISTERED_USER:
        return {
          ...state,
          isRegistered: action.isRegistered,
          user: {
            'contact_number': action.contact_number,
            'email': action.email,
            'country_code': action.country_code,
            'partial_number': action.partial_number,
            'country_code_value': action.country_code_value
          },
          loading: false,
        }

      default:
        return state;
    }
};