import { Modal, Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react"

import "./PaymentMethod.css"
import check from "../../assets/svgs/single-check.svg"


const PaymentMethod = (props) => {

    const [activePayment, setActivePayment] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState(null)


    useEffect(()=>{
        console.log(props.outletInfo)
        setActivePayment(0)
        setPaymentMethod(null)
    }, [props.outletInfo?.out_id])

    return (
    <> 
        <Modal
        show={props.showPayment}
        onHide={()=>{props.setPaymentVisible(false)}}
        className="rounded-lg"
        dialogClassName="modal-md xs modal-dialog-centered"
        contentClassName="xs"
        centered
        backdrop="static" >

            <Modal.Header className="no">
                <h4 className="modal-title">Payment Method</h4>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{props.setPaymentVisible(false)}}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>

            <div className="paymentContainer">
                
                
                {/* <Col style={{textAlign:"center", backgroundColor:"#ececec"}} xs={{span:4, offset:1}}>Card</Col>
                <Col style={{textAlign:"center", backgroundColor:"#ececec"}} xs={{span:4, offset:1}}>Cash</Col> */}


                <div style={{display: "flex", flexDirection: "row", justifyContent:"space-evenly", verticalAlign:"middle"}}>
                    
                    <div className={activePayment === 1 ? "paymentMethod btn activeMethod" : "paymentMethod btn"} onClick={()=>{
                        setPaymentMethod("card")
                        setActivePayment(1)
                    }}>Card <img src={check} alt="tick_mark" className={`${activePayment === 1 ? "tick" : "tick_disabled"}`}/></div>
                    <div className={activePayment === 2 ? "paymentMethod btn activeMethod" : "paymentMethod btn"} onClick={()=>{
                        setPaymentMethod("cash")
                        setActivePayment(2)
                    }}>Cash<img src={check} alt="tick_mark" className={`${activePayment === 2 ? "tick" : "tick_disabled"}`}/></div>
                    
                    
                    {
                        props.paynowNumber ? 
                            <div
                                className={activePayment === 3 ? "paymentMethod btn activeMethod" : "paymentMethod btn"} onClick={()=>{
                                setPaymentMethod("paynow")
                                setActivePayment(3)
                                }}>Paynow<img src={check} alt="tick_mark" className={`${activePayment === 3 ? "tick" : "tick_disabled"}`}/>
                            </div>
                        :
                            ""
                    }
                    
                
                
                </div>
                

            </div>

            <div className="selectedOption"> Selected option : 
                <span style={{marginLeft:"2%", fontWeight:"bold", color:"black"}}>
                    {
                        paymentMethod ? paymentMethod[0].toUpperCase() + paymentMethod.slice(1, paymentMethod.length) : ""
                    }
                </span> 
            </div>

            <Modal.Footer style={{borderTop:0}} className="modal-footer form-action">
                <button
                    className="btn lg primary d-block"
                    onClick={()=>{
                        props.setPaymentMethod(paymentMethod)
                        props.setPaymentVisible(false)
                        if (paymentMethod === "paynow") {
                            props.setShowPaynow(true)
                        }
                    }}
                >
                    Proceed
                </button>
            </Modal.Footer>

        </Modal>
    </>
    
    )
}

export default PaymentMethod