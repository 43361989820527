import React, { useEffect, useRef} from 'react';
import {useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import close from "../../assets/svgs/close.svg";
import axios from 'axios';
import "../../assets/css/modal.css";
import PropTypes from "prop-types";
import "../../assets/css/component.css";
import Verification from "./Verification"
import ConfirmPassword from './ConfirmPassword';
import { turnOffButton, turnOnButton } from './buttonFunctions';
import Spinner from '../../common/Spinner';
import { load, doneLoading, createVerification } from "../../actions/userActions"
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const ResetPassword = (props) =>{
  const [resetPasswordData, setResetPasswordData] = useState({})
  const [canSubmit, setButtonState] = useState(false)
  const buttonElement = useRef(null)
  const [openVerification, setVerificationVisible] = useState(false)
  const [openConfirmation, setConfirmationVisible] = useState(false)
  const [passwordEmail,setEmail]=React.useState('');
  const [emailStatus,setEmailStatus]=React.useState('');

  turnOnButton(buttonElement.current)

  const handleSubmit = () => {
    if(props.email == passwordEmail) {
      
      // axios.post("/password/create", {"email": passwordEmail}).then((res)=>{
      //   props.doneLoading()
      //   setResetPasswordData({...resetPasswordData, "email": passwordEmail})
      //   setVerificationVisible(true)
      // }).catch((error)=>{
      //   props.doneLoading()
      //   console.log(error)
      // })
      props.createVerification(passwordEmail)
    }
    else {
      setEmailStatus(<div className="footer-warley-description text-danger">Email is not registered with this account!</div>)
    }
  }

  const handleClose = () => {
    setResetPasswordData({})
    props.onHide()
  }

  useEffect(()=>{ //CHECK IF CREATE VERIFICATION CODE WAS A SUCCESS(user.verificationCode is not null)
    console.log(props.user.verificationCode)
    if (props.user.verificationCode?.data) {
      console.log("HEY")
      setResetPasswordData({...resetPasswordData, "email": passwordEmail})
      setVerificationVisible(true)
    }
  }, [props.user.verificationCode])

  useEffect(()=>{
    turnOffButton(buttonElement.current)

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(emailRegex.test(passwordEmail)){
      turnOnButton(buttonElement.current)
    }
    

  }, [passwordEmail])

  function handleChange(event){
    setEmailStatus('');
    setEmail(event.target.value);
    event.preventDefault();
  }


  return(

    <>
      <Verification 
      show={openVerification} 
      verificationVisibility={setVerificationVisible} 
      confirmationVisibility={setConfirmationVisible}
      resetData={resetPasswordData}
      setResetData={setResetPasswordData}
      passwordEmail={passwordEmail}
      />
      <ConfirmPassword 
      show={openConfirmation} 
      confirmationVisibility={setConfirmationVisible}
      resetData={resetPasswordData}
      setResetData={setResetPasswordData}
      hideResetPassword={props.onHide}
      close={props.close}
      showSuccess={props.showSuccess}
      hideSuccess={props.hideSuccess}
      />
      <Modal
        show={props.show}
        onHide={handleClose}
        dialogClassName="modal-md xs"
        contentClassName="xs"
        centered
      >
      <form onSubmit={handleSubmit}>
          <Modal.Body className=" px-5 py-4">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img onClick={props.onHide} src={close} width="20" alt="close" />
            </button>
            <div style={{marginBottom:'5%'}} className="text-center mt-5">
              <div className="back-heading">Reset your password</div>
              <div className="warley-description">
                A verification code will be sent to the registered email
              </div>
              <div className="mt-3">
                <div className="input-title mb-2">Enter your email</div>
                <div className="mb-2">
                  <input
                    type="email"
                    className="form-control-lg input input-full"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                  />
                </div>
                <div>{emailStatus}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer no">
            <button
              className="btn btn-block btn-danger font-weight-bold py-3 rounded f18"
              type="submit" ref={buttonElement} style={{opacity:0.5}} disabled
            >
              {" "}
              Get verification code
              <img src="images/log-out.svg" className="ml-2" alt="" />
            </button>
            <div className="powered my-4 w-100">Powered by Warely</div>
          </Modal.Footer>
        </form>    
      </Modal>
    </>
    )
}

ResetPassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withTranslation()(
  connect(mapStateToProps, {
    load, doneLoading, createVerification
  })(ResetPassword)
);