import React, { useState, useEffect, useRef} from "react";
import { Modal } from "react-bootstrap";

import PropTypes from "prop-types";
import "../../assets/css/component.css";
import "./signup.css";
import "../../assets/css/modal.css";
import close from "../../assets/svgs/close.svg";
import warley from "../../assets/svgs/warley.svg";
import { Signup, ClearState } from "../../actions/productActions";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PopUp from "../Modals/Popup";
import axios from "axios";
import SignupComplete from "./SignupComplete";
import { checkEmail, clearUserState, signup } from "../../actions/userActions";
import "../../assets/css/form.css"

const Signup2 = (props) => {
  const { register,reset, handleSubmit } = useForm();
  const [canSubmit, setSubmitState] = useState(false)
  const [load, setLoad] = useState(false);
  const [lshow, setLshow] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [emailStatus, setEmailStatus] = useState("ERROR")
  const [emailText, setEmailText] = useState("")
  const source = useRef(null)
  const emailInputElement = useRef(null)
  const [accountSuccess, setShowModal] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    if (props.signup && !props.loading && props.signup.success && load) {
      console.log("____Not Errors______");
      setLoad(false);
      localStorage.removeItem('cart');
      setPopupText("Registration Successfully !");
      setLshow(true);
      reset();
      //props.closeModal();
      props.ClearState();
    } else if (props.errors && load) {
      alert(JSON.stringify(props.errors.data));
      setLoad(false);
      props.ClearState();
    }
  }, [load, props]);

  useEffect(()=>{ //USED TO CHECK IF EMAIL INPUT ELEMENT HAS CHANGED
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setSubmitState(false)

    setEmailStatus("")
    props.clearUserState()
    
    
    if (emailRegex.test(String(emailText).toLowerCase())){
      setEmailStatus(<div className="footer-warley-description text-dark">Loading</div>)
      props.checkEmail(emailText) //SEND THE REQUEST TO CHECK EMAIL
    }
    
    
    
  }, [emailText])

  useEffect(()=>{ //USED TO CHECK IF SIGNUP WAS SUCCESSFUL
    if (props.user.signup) {
      setShowModal(true)
      props.clearUserState()
    }
  },[props.user.signup])

  useEffect(()=>{ // USED TO UPDATE ERROR/SUCCESS MESSAGE FOR EMAIL
    if (props.user.email === true) {
      setEmailStatus(<div className="footer-warley-description text-success">Email Available</div>)
    } else if (props.user.email === false){
      setEmailStatus(<div className="footer-warley-description text-danger">Email Unvailable</div>)
    }
  }, [props.user.email])

  //signup
  const onSubmit = (data) => {
    console.log(data)
    if (data.password !== data.confirm_password) {
      setConfirmPassword("Password must be the same")
    } else {
      let newData = { 
        ...props.signupVal, 
        ...data, 
        "email":emailInputElement.current.value, 
        "password": data.password, 
        "confirm_password": data.confirm_password, 
        "location_id": props.location_id ? props.location_id : localStorage.location_id,
        "business_id": props.business_id ? props.business_id : localStorage.business_id,
        "username": props.signupVal.first_name
      };
      console.log(newData,"")
      props.setSignup(newData);
      props.signup(newData) //SEND REQUEST TO SIGN UP
      props.closeModal()
    }
    
  };


  const closeModal = () => {
    props.closeModal();
    setLshow(false);
  };

  if (document.querySelector("#signUpButton")){ //Check if not null
    if (!props.user?.email || props.user?.checkEmailLoading) {
      document.querySelector("#signUpButton").style.opacity = 0.5
      document.querySelector("#signUpButton").disabled = true
    } else {
      document.querySelector("#signUpButton").style.opacity = 1.0
      document.querySelector("#signUpButton").disabled = false
    }
  }
  
  return (
    <>
    <SignupComplete joinus={props.joinus} location_id={props.location_id} business_id={props.business_id} show={accountSuccess} closeModal={()=>{setShowModal(false)}}/>
    <Modal
      show={props.show}
      onHide={props.joinus ? ()=>{} : props.closeModal}
      dialogClassName="modal-md xs"
      contentClassName="xs"
      centered
    >
      <PopUp text={popupText} show={lshow} closeModal={closeModal} />
      <form onSubmit={handleSubmit(onSubmit)} className="modal_form">
        <Modal.Body className="px-5 py-4">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={
              ()=>{
                if (props.joinus) {
                  props.setSignupClose(1)
                } else {
                  props.closeModal() 
                } 
              }
              
            }
          >
            <img src={close} width="20" alt="close" />
          </button>
          <div className="text-center mt-5">
            <img src={warley} width="263px" height="55px" alt="warley" />
            <div className="signup-subtitle">Sign up for WARELY account</div>

            <div className="">
              <div className="input-title mb-2">Email</div>
              <div className="mb-2">
                <input
                  type="email"
                  ref={emailInputElement}
                  className="form-control-lg input input-full"
                  required
                  autoComplete="off"
                  onChange={()=>{setEmailText(emailInputElement.current.value)}}
                  
                />
                <div className="">{emailStatus}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="input-title mb-2">Password</div>
              <div className="mb-2">
                <input
                  required
                  minLength="7"
                  autoComplete="off"
                  {...register("password")}
                  type="password"
                  className="form-control-lg input input-full"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="input-title mb-2">Confirm Password</div>
              <div className="mb-2">
                <input
                  required
                  minLength="7"
                  autoComplete="off"
                  {...register("confirm_password")}
                  type="password"
                  className="form-control-lg input input-full"
                />
                <div className="footer-warley-description text-danger">{confirmPassword}</div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="footer-warley-description text-left w-100">
                By signing up, you have read and agreed to our
                <br />
                <span>
                  <a href={null} className="text-danger cursor">
                    Terms & Conditions
                  </a>
                </span>
                &nbsp; and &nbsp;
                <span>
                  <a href={null} className="text-danger cursor">
                    Privacy Policy.
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            type="submit"
            className="btn btn-danger rounded font-weight-bold btn-block py-3 f18"
            data-dismiss="modal"
            style={{ opacity :canSubmit ? 1.0 : 0.5}}
            id="signUpButton"
          >
            Sign up
          </button>
          <div className="powered my-4 w-100">Powered by Warely</div>
        </Modal.Footer>
      </form>
    </Modal>
  </>
  );
};
Signup2.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  signup: state.products.signup,
  loading: state.products.loading,
  auth: state.auth,
  errors: state.products.errors,
  user: state.user
});

export default withTranslation()(
  connect(mapStateToProps, { Signup, ClearState, checkEmail, clearUserState, signup })(Signup2)
);
