import React, { useRef, useState, useEffect } from "react";
import { Modal, Dropdown} from "react-bootstrap";
import { getPostal } from "../../actions/autocompleteActions";
import PropTypes from "prop-types";
import "../../assets/css/icon.css";
import "../../assets/css/bundle.css";
import "../../assets/css/component.css";
import { useSelector, useDispatch } from "react-redux";
import { upperCaseAddress } from "./addressFunctions";

import { useForm } from "react-hook-form";
//import { propTypes } from "react-bootstrap/esm/Image";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const formatAddress = (string) => {
  const arr = string.split(" ")

  let newArr = arr.map((item)=>{
    return (item[0]+item.toLowerCase().substring(1, item.length))
  })
  return newArr.join(" ")
}


const DeliveryModal = (props) => {
  const { register, handleSubmit } = useForm();

  const hideModal = () => {
    props.closeModal()
  }

  //USED TO PASS AS DETAILS TO ANOTHER COMPONENT
  const [location, setLocationDetails] = useState({
    latitude:"",
    longtitude:""
  })

  //TIMEOUT FOR INPUT CHANGE
  const [timer, setTimer] = useState(null)

  const [visibility, setVisible] = useState("none") //FOR THE DROPDOWN LIST CONTAINING POSTAL CODES
  const [postal, setPostal] = useState("")
  const [addressForm, setFormVisible] = useState("hidden")
  const [buildingName, setBuildingName] = useState("")
  const [unitNumber, setUnitNumber] = useState("")
  const [fullAddress, setFullAddress] = useState("")

  const [note, setNote] = useState("")
  
  //STATES FROM THE REDUX STORE
  const postalCodes = useSelector((state)=>(state.postal.postalCodes.results))
  const loading = useSelector((state)=>(state.postal.loading))
  const dispatch = useDispatch()
  //--------------------------

  //ERROR MESSAGE TO RENDER
  const [errorMessage, setErrorMessage] = useState("")
  //----------------------

  //REFERENCES FOR THE VARIOUS INPUT ELEMENTS
  const postalCodeElement = useRef(null)
  const buildingNameElement = useRef(null)
  const unitNumberElement = useRef(null)
  const dropdownRef = useRef(null)
  //------------------------------------

  const handleClick = (event) => {
    if (dropdownRef.current) {
      if (dropdownRef.current.contains(event.target)){
        return
      }

      setVisible("none")
    }
  }

  //EVENT FOR DETECTING CLICK OUTSIDE
  useEffect(()=>{
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  //DETECT CHANGES FOR POSTAL CODE, THEN SEND REQUEST
  useEffect(()=>{
    if (postal !== "") {
      dispatch(getPostal(postal))
    }
    
  }, [postal])

  useEffect(()=>{ //HIDE INPUT ELEMENTS IF FULL ADDRESS IS BLANK
    if (buildingName === "") {
      setFormVisible("hidden")
    }
  }, [buildingName])

  const myRef = useRef();
  const onSubmit = (data) => {

    //ADDRESS FORMATTING(SET TO LOWER CASE EXCEPT FIRST LETTER AND COMBINE POSTAL CODE INTO ONE ADDRESS)
    let previousData = upperCaseAddress(fullAddress).replace("Singapore "+ postal, unitNumber==="" ? (postal === "" ? "" : "Singapore " + postal): unitNumber + " " + "Singapore " + postal)
    let newData = buildingNameElement.current.value + " " + unitNumberElement.current.value + " Singapore " + postal
    
    

    if (postal === "" && buildingNameElement.current.value !== "") { //CHANGE LATERSSSSSSS--TEMPORARY FOR BUS STOP ADDRESSES
      newData = buildingNameElement.current.value
    }

  
    let formData = { //DATA TO PASS TO ANOTHER COMPONENT, RIGHT-SIDEBAR.JS
      ...location,
      full_address: newData,
      note: note
    }
    

    //SET ADDRESS INFORMATION IN THE LOCAL STORAGE
    localStorage.setItem("d_address", formData.full_address)
    localStorage.setItem("latitude", formData.latitude)
    localStorage.setItem("longtitude", formData.longtitude)
    localStorage.setItem("additional_note", formData.note)

    //FUNCTION PROPERTY FROM RIGHTSIDEBAR JS, SET THE STATE TO THE FORM DATA AS FROM BEFORE
    props.setAddress(formData);

    //MODAL VISIBILITY
    props.closeModal();
    props.showOutlet()
    //----------------

    


    // localStorage.setItem("address", JSON.stringify(formData));
  };

  const returnAddress = (data) => {
    
    if (!data){
      return ""
    }
    
    let array = []

    let maxLength = (data.length < 5) ? data.length : 5

    for (let i = 0; i < maxLength; ++i) {
      const roadName =  data[i].ROAD_NAME[0] + data[i].ROAD_NAME.toLowerCase().substring(1, data[i].ROAD_NAME.length)
      const fullAddress = upperCaseAddress(data[i].ADDRESS)

        //POPULATE DROPDOWN LIST
        array.push(
          <div key={i} onClick={(event)=>{ //ONCLICK EVENT TO SET THE VARIOUS INPUT ELEMENTS AND THE STATES TO THE CURRENT
                                      //ADDRESS THAT THE USER HAS CLICKED
            
            setLocationDetails({
              ...location,
              latitude: data[i].LATITUDE,
              longtitude: data[i].LONGITUDE
            })
            setPostal(data[i].POSTAL === "NIL" ? "" : data[i].POSTAL); //DATA MAY PASS NIL, LEAVE INPUT ELEMENT BLANK IF SO
            setVisible("none") 
            setFormVisible("visible")
            setBuildingName(upperCaseAddress(data[i].ADDRESS).replace("Singapore "+data[i].POSTAL, "").trim())
            setFullAddress(data[i].ADDRESS)
          }} style={{whiteSpace: "unset", width:"100%", fontSize:"75%", textAlign:"center"}} className="dropdown-item">
          
          {
            fullAddress
          }
          </div> 
        )
      
    }
    return array
  }

  

  // useEffect(()=>{
  //   document.addEventListener("click", clickOutside, true);
  //   console.log(menuRef)
  // }, [])

  return (
    <Modal show={props.show} onHide={hideModal}  dialogClassName="delivery" centered>
      <Modal.Header>
        <Modal.Title>Your delivery address</Modal.Title>
        {/* <h4 className="modal-title"></h4> */}
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={hideModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* {!localStorage.login && (
            <div
              onClick={() => redurectLogin(myRef)}
              className="head-info mb-5"
            >
              Login for faster check out next time
            </div>
          )} */}
          <div className="form-group">
            <label className="control-label">Postal Code</label>
            <div className="search-box">

              <Dropdown>
                <i className="search-icon" style={{top:9}}></i>
                <input
                    value={postal}
                    autoComplete="off"
                    ref={postalCodeElement}
                    onChange={(event)=>{
                      event.target.value = event.target.value.replace(/\D/g, "")
                      setPostal(postalCodeElement.current.value)
                      setVisible("show")
                    }}
                    id="inputField"
                    className="form-control"
                    type="text"
                    placeholder="Enter your postal code"
                    onFocus={()=>{console.log(visibility); setVisible("show")}}

                  />
                <div ref={dropdownRef} style={{width:"100%"}} className={`dropdown-menu ${postal === "" || postalCodes?.length === 0 ? "none" : visibility} dropdown-menu-lg-end`}>
                  {loading === true ? <div style={{textAlign:"center"}}>Loading</div> : returnAddress(postalCodes)}
                </div>
              </Dropdown>

            </div>
          </div>
          <div className="row" style={{visibility:addressForm}}>
            <div className="col-md-7">
              <div className="form-group">
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="Full address"
                  value={buildingName}
                  ref={buildingNameElement}
                  onChange={()=>{
                  setFullAddress(fullAddress.replace(fullAddress, buildingNameElement.current.value + (
                    unitNumber === "" ? "": " " + (postal === "" ? "" : " Singapore " + postal)
                  ) ) )
                  setBuildingName(buildingNameElement.current.value)}}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={unitNumber}
                  placeholder="Unit No."
                  ref={unitNumberElement}
                  onChange={(e)=>{setUnitNumber(e.target.value)}}
                />
              </div>
            </div>
          </div>
          <div  style={{visibility:addressForm}} className="form-group">
            <label className="control-label">Note</label>
            <textarea className="form-control" onChange={(event)=>{setNote(event.target.value)}}></textarea>
          </div>
          <div className="form-action mt-5" style={!props.auth?.isAuthenticated || postal === "" || buildingName === "" ? {
            pointerEvents:"none",
            opacity:0.4
          } : {pointerEvents:"auto", opacity:1.0} } onClick={onSubmit} data-testid="delivery_modal_btn">
            <div className="btn lg primary d-block">
              {props.auth?.isAuthenticated ? "Select outlet" : "Please login to select outlet"}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
DeliveryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  postal: state.postal
});
export default withTranslation()(
  // connect(mapStateToProps, { clearCartSingle, clearCart, placeOrder, getTaxes, submitOrder, updateOrder })(
  connect(mapStateToProps, {  })(
    DeliveryModal
  )
);