export const upperCaseAddress = (string) => {
    const stringArr = string.split(" ")

    let newArr = stringArr.map((element)=>{

        if (/^\d+$/.test(element[0]) === true) {
            return element[0] + element.substring(1, element.length)
        } else {
            return element[0] + element.substring(1, element.length).toLowerCase()
        }
    
    })

    return newArr.join(" ")
}

