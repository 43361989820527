import React, { useRef } from "react";
import ReactHtmlParser from 'react-html-parser'; 
import "./orderItem.css";
import "./button.css";

import PropTypes from "prop-types";

const URL = process.env.REACT_APP_API_BASE_IMG;
const OrderItem = (props) => {
  const data = props.data;

  // console.log(data);


  const renderProduct = () => {
    let html = [];

    if (props.data.product && Object.keys(props.data.product).length > 0) {

      let data = props.data.product;

      Object.keys(data).forEach(function (key) {
        html.push(
          <div key={key} className="col-xl-6 ">
            <div className="box">
              <div className="row">
                <div style={{ width: "345px"}} className="col-6">
                  {/* <img 
                    className="item-img"
                    src={
                      data &&
                      data[key].get_image &&
                      URL + "pic/" + data[key].get_image.path
                    }
                    alt="product"
                  /> */}
                  <img
                    className="item-img"
                    src={data && data[key].image}
                    alt="product"
                  />
                </div>
                <div className="col-6 cnts">
                  <div className="head">
                    <h3>{data && data[key].name}</h3>
                    {/* <h4>Online Exclusive</h4> */}
                  </div>
                  <div className="dec">
                   {/* {Converting HTML strings to React Component} */}
                  {data[key].product_description != null ? ReactHtmlParser(data[key].product_description) : null}
                  </div>
                  <div className="action">
                    <div className="price" style={{marginLeft:"0px"}}>
                      From <span>$ {data && parseFloat(data[key].price).toFixed(2)}</span>
                    </div>
                    <button
                      onClick={() => {
                        props.showChooseModal(data[key]);
                      }}
                      data-toggle="modal"
                      data-target="#cartModal"
                      className="btn primary btn-item"
                      data-testid={`${data[key].id}_modal`}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return html;
  };

  return <div className="row">{renderProduct()}</div>;
};

OrderItem.propTypes = {
  showChooseModal: PropTypes.func.isRequired,
};

export default OrderItem;
