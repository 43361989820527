import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import "../../assets/css/modal.css";
import "../../assets/css/form.css"
import { getOutlates } from "../../actions/productActions";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const formatTime = (time) => {
  let postfix; //AM, PM
  let timeArr; //Used to contain resulting array after split function
  let finalTime; //Used to combine the time array after 
  
  if (time > 12) { //Prepare for modulo operation, anything above 12, e.g 13, 14 will be counted as PM
    postfix = "PM"
  } else {
    postfix = "AM"
  }

  timeArr = (time % 12).toFixed(2).split(".") //toFixed parses the float into a string, Split for when time provided has decimal point
  if (timeArr.length > 1) {
    finalTime = timeArr[0] + ":" + timeArr[1] + " " + postfix
  } else {
    finalTime = timeArr[0] + ":" + "00" + " " + postfix
  }
  return finalTime
} 


class Pickup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // outlates: props.outlatesx,
      select: false,
      activeItem: -1,
      noOutlets: 0
    };
    this.outlateRenders = this.outlateRenders.bind(this);
  }
  componentDidMount() {
    this.props.getOutlates();

    
  }
  // componentWillReceiveProps(nextprops) {
  //   // console.log(nextprops);

  //   if (
  //     nextprops.loading === false &&
  //     nextprops.outlates !== null
  //     // nextprops.outlates.success
  //   ) {
  //     this.setState({ outlates: nextprops.outlates });
  //   }
  // }
  selctOutlate = (e, id, name, index, amount_rounding_method) => {
    let oldorder = this.props.order;
    console.log("______select outlate______");
    console.log(oldorder);
    oldorder["out_id"] = id;
    oldorder["out_name"] = name;

    this.props.setOrder(oldorder);
    this.setState({ activeItem: index });

    

    localStorage.setItem("outlate", id);
    localStorage.setItem("out_name", name);

    this.props.setAmountRoundingMethod(amount_rounding_method);
    
    
  };

  outlateRenders = () => {
    

    let html = [];
    // let cdata = this.state.outlates;
    let cdata = this.props.outlates?.business_location;
    // console.log('cdata: ', cdata);

    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    var today = new Date();
    var todayDay = days[today.getDay()];

    const checkIfRestDay = (outlet) => { //RETURN FALSE IF REST DAY IS NULL, RETURN ACCORDINGLY(BASED ON TODAY'S DAY) IF ITS NOT
      if (outlet?.custom_field2) {
        let outletRestDay = outlet.custom_field2
    
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
        
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const userDate = new Date((new Date).toLocaleString('en-US', {timeZone: userTimeZone}))
        if (outletRestDay[0] === days[userDate.getDay()-1]) {
          return true
        } else {
          return false
        } 
    
      } else {
        return false
      }
    }


    if (cdata != null && Object.keys(cdata).length > 0) { //CHECK IF cdata IS DEFINED AND IF THERE ARE PROPERTIES
      Object.keys(cdata).map((key, index) => {
        if (cdata[index]?.custom_field3) {
          this.noOutlets++;

          var isRestDay = cdata[index]?.custom_field2?.includes(todayDay);
         

          let opening_time = [] //MANUALLY FORMAT THE TIME FROM CUSTOM_FIELD3 
          for (let i = 0; i < cdata[index]?.custom_field3.length; ++i){
            opening_time.push(cdata[index].custom_field3[i])
          }

          // CHANGE OPENING_TIME FORMAT TO E.G [ '10PM - 3PM', '5PM - 12AM' ]
          opening_time = opening_time.map((time) => (`${formatTime(time[0])} - ${formatTime(time[1])}`)) 
          
          if (opening_time.length > 1) { //CHECK IF ARRAY IS MORE THAN 1 ELEMENT E.G [ '10PM - 3PM', '5PM - 12AM' ]
            let merged_time = opening_time[0] //START FROM FIRST ELEMENT E.G '10PM - 3PM' AND APPEND THE COMMAS AND TIME FROM THERE
            for (let i = 1; i < opening_time.length; ++i) { //FOR LOOP THAT IS APPENDING THE TIME ELEMENTS
              merged_time += ", " + opening_time[i]
            }
            opening_time[0] = merged_time //SET THE FIRST ELEMENT TO THE MERGED TIME E.g '10PM - 3PM, 5PM - 12AM' SO LATER JUST TO OPENING_TIME[0]
          } 

          html.push(
            <div
              style={(!cdata[index].is_open || checkIfRestDay(cdata[index])) ? {
                pointerEvents: 'none',
                'cursor': 'not-allowed',
                'opacity': '0.4'
              } : {
                pointerEvents: 'auto',
                'cursor': 'pointer',
                'opacity': '1'
              }}
              key={key}
              className={
                this.state.activeItem === cdata[index]?.id ? "box fill" : "box outline"
              }
              onClick={(e) =>{
                this.props.setPaymentMethod(null)
                this.props.setPaynowNumber(cdata[key].paynow_number)
                this.props.setAmountRoundingMethod(cdata[key].amount_rounding_method)
                localStorage.setItem("paynow_number", cdata[key].paynow_number)
                localStorage.setItem("amount_rounding_method", cdata[key].amount_rounding_method)
                this.selctOutlate(e, cdata[key].id, cdata[key].name, index, cdata[key].amount_rounding_method)
              }
                
              }
            >
              <div className="ins">
                <ul>
                  <li>
                    {/* <i className="icon-5"></i> */}
                    <span className="txt title">{cdata[key].name}</span>
                  </li>
                  <li>
                    {/* <i className="icon-6"></i> */}
                    <span className="txt">
                      {cdata[key].address}
                    </span>
                  </li>
                  {cdata[key]?.custom_field2 ?  
                  <li>
                    <span className="name">Rest day :</span>
                    {
                      cdata[key]?.custom_field2 !== null
                      &&                      
                      <span className="txt bold" style={{ 'marginLeft': '10px' }}>  {(cdata[key]?.custom_field2).join(", ")}</span>
                    }
                  </li>
                  : ""
                  }
                  <li>
                    <span className="name">Hours :</span>
                    <span className="txt">
                      {" "}
                      {/* <span className="open">Open</span> {cdata[key].start} - {cdata[key].end} */}
                      <span className={cdata[key].is_open && (!isRestDay) ? 'open' : 'outletClosed'}>
                      {cdata[key].is_open && (!isRestDay) ? 'Open' : 'Close'}</span>  {opening_time[0]}
                    </span>
                  </li>
                  <li>
                    <span className="name">Phone :</span>
                    <span className="txt bold">  {cdata[key].phone}</span>
                  </li>
                </ul>
              </div>
            </div>
          )
        }
      });
    }
    //  console.log(menus);
    return html;
  };

  static getDerivedStateFromProps(props, state) {
    if (localStorage.outlate) {
      if (props.outlates) {
        let outlates = props.outlates.business_location
        for(let i = 0; i < outlates.length; ++i) {
          let today = new Date();
          let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          let todayDay = days[today.getDay()];
          if (outlates[i].id === parseInt(localStorage.outlate)){
            if (outlates[i].is_open && !(outlates[i]?.custom_field2?.includes(todayDay))){
              return {...state, activeItem: parseInt(localStorage.outlate)}
            } else {
              localStorage.removeItem("outlate")
              localStorage.removeItem("out_name")
              localStorage.removeItem("")
              return {...state}
            } 
          }
        }
      }
    }
  }

  //console.log(closeModal);
  render() {
    

    let cdata = this.props.outlates?.business_location;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var today = new Date();
    var todayDay = days[today.getDay()];
    var availableOutlets = 0;

    if (cdata != null && Object.keys(cdata).length > 0) {
      Object.keys(cdata).map((key, index) => {
        if (cdata[index]?.custom_field3) {
          var isRestDay = cdata[index]?.custom_field2?.includes(todayDay);
        }
        if (cdata[key].is_open && !isRestDay) {
          availableOutlets += 1;
        }
      })
    }

    // var totalOutlet = (this.props.outlates?.business_location?.length) ? this.props.outlates?.business_location?.length : 0;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.closeModal}
        className="rounded-lg "
        dialogClassName="modal-md xs"
        contentClassName="pickup-modal xs modal_form"
        centered
        backdrop="static"
      >
        <Modal.Header className="no">
          <h4 className="modal-title">Pick up</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="no">
          <div className="scroll">
            <div className="box data">
              <div className="ins">
                <span>{availableOutlets} Store(s) Available</span>
                {/* <a href={null}>View on Map</a> */}
              </div>
            </div>

            {this.outlateRenders()}
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer form-action">
          <button
            type="submit"
            disabled={this.state.activeItem == -1}
            className="btn lg primary d-block"
            onClick={this.props.showPickupDate}
          >
            Select this outlet
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Pickup.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showPickupDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outlates: state.products.outlates,
  loading: state.products.loading,
});
export default withTranslation()(
  connect(mapStateToProps, { getOutlates })(Pickup)
);
