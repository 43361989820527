import React from 'react';
import Modal from 'react-bootstrap/Modal';
import close from "../../assets/svgs/close.svg";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../../assets/css/modal.css";
import PropTypes from "prop-types";
import "../../assets/css/component.css";
import { useForm } from "react-hook-form";
import { Col } from 'react-bootstrap';
import { turnOffButton, turnOnButton } from "./buttonFunctions"
import { doneLoading, load, submitReset, clearUserState } from '../../actions/userActions';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const ResetSuccess = (props) => {
  return (    


        <Modal
          show={props.show}
          dialogClassName="modal-smd maxwidth500"
          centered
          onHide={()=>{
          props.clearUserState()
          props.closeModal()
          }}
        >
          <Modal.Body className="p-2">
            <div className="modal-body d-flex justify-content-center align-items-end">
              <button
                type="button"
                className="close-modal-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.clearUserState()
                  props.closeModal()
                }}
              >
                <img src={close} width="20" alt="close" />
              </button>
              <div className="text-center" style={{marginTop: '5vh'}}>
                <div className="f40 claim">
                    <h1>GREAT!</h1>
                </div>
                <p>Your password has been changed.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer no">
            <button
              type="button"
              className="btn btn-danger claim-button btn-block py-0"
              data-dismiss="modal"
              onClick={() => {
                props.clearUserState()
                props.closeModal();
              }}
            >
                START ORDERING NOW
            </button>
            <div style={{width:'100%', fontSize:'10pt'}}>
                <p>Powered by Warely</p>
            </div>
          </Modal.Footer>
        </Modal>
    )
}



const ConfirmPassword = (props) => {
    const { register, reset, handleSubmit } = useForm()
    const [password, setPassword] = useState("")
    const [password_confirm, setCfmPassword] = useState("")
    const [textArray, setTextArray] = useState(Array(props.maxLength))
    const [showSuccess, setShowSuccess] = useState(false)
    const buttonElement = useRef(null)

    useEffect(()=>{

        turnOffButton(buttonElement.current)

        if (password === "" || password_confirm === "") {
            turnOffButton(buttonElement.current)
        }

        else if (password === password_confirm) {
            turnOnButton(buttonElement.current)
        }

    }, [password, password_confirm])

    useEffect(()=>{
      if (props.user.reset) {
        props.confirmationVisibility(false)
        props.hideResetPassword()
        setShowSuccess(true)
      }
    }, [props.user.reset])


    const onSubmit = () => {
       const newData = {...props.resetData, "password": password, "password_confirmation": password_confirm}
       console.log(newData)
       //props.load()
       props.submitReset(newData)
      //  axios.post("/password/reset", newData).then((response)=>{
      //   props.doneLoading()
      //   props.confirmationVisibility(false)
      //   props.hideResetPassword()
      //   setShowSuccess(true)
      //   console.log(response)
      //  }).catch((error)=>{
      //    props.doneLoading()
      //   console.log(error)
      //  })
    }


    const onClick = () => {
      props.confirmationVisibility(false)
      props.setResetData({})
    }

    return (
        <>
        <ResetSuccess show={showSuccess} closeModal={()=>{props.close()}} clearUserState={props.clearUserState}/>
        <Modal
        show={props.show}
        dialogClassName="modal-md xs"
        contentClassName="xs"
        centered
        >
        <form onSubmit={onSubmit}>
          <Modal.Body className=" px-5 py-4">
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClick}
              >
              <img src={close} width="20" alt="close" />
              </button>
              <div style={{marginBottom:'5%'}} className="text-center mt-5">
                <div className="back-heading">Change password</div>
                <div className="mt-5">
                  <div className="input-title mb-3">New password</div>
                  <div className="mb-2">
                    <input
                      type="password"
                      className="form-control-lg input input-full"
                      required
                      autoComplete="off"
                      onChange={(event)=>{setPassword(event.target.value)}}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="input-title mb-3">Confirm password</div>
                  <div className="mb-2">
                    <input
                      type="password"
                      className="form-control-lg input input-full"
                      required
                      autoComplete="off"
                      onChange={(event)=>{setCfmPassword(event.target.value)}}
                    />
                  </div>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer no">
            <button
              className="btn btn-block btn-danger font-weight-bold py-3 rounded f18"
            type="submit" ref={buttonElement} disabled style={{opacity:0.5}}
            >
              {" "}
              Submit
              <img src="images/log-out.svg" className="ml-2" alt="" />
            </button>
            <div className="powered my-4 w-100">Powered by Warely</div>
          </Modal.Footer>
        </form>    
      </Modal>
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withTranslation()(
  connect(mapStateToProps, {
    load, doneLoading, submitReset, clearUserState
  })(ConfirmPassword)
);