import React from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import '../Header/Header.css';
import Carousel from '../Carousel/Carousel';
import HomePage from '../../pages/Homepage/Homepage';
import MenuItems from '../../pages/MenuItems/MenuItems';
function HeaderMob(props){
  
    return(
<div className="is">
  <div className="page-header">
    <div  className="d-block d-sm-none">
        <Button style={{margin:'0'}} className="pull-right table-Button" variant="danger">
          Table no <span>{props.table}</span>
        </Button>
    </div>
  </div>

</div>
     
  
    )
}

export default HeaderMob;