import { Modal, Row, Col } from "react-bootstrap"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import "./PaymentMethod.css"
import check from "../../assets/svgs/single-check.svg"


const Paynow = (props) => {

    const [activePayment, setActivePayment] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const alt_num = props.business_location ? props.business_location[0].alternate_number : ""

    return (
    <> 
        <Modal
        show={props.showPaynow}
        onHide={()=>{props.setShowPaynow(false)}}
        className="rounded-lg"
        dialogClassName="modal-md xs"
        contentClassName="pickup-modal xs"
        centered
        backdrop="static" >

            <Modal.Header className="no">
                <h4 className="modal-title">Payment Method</h4>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{props.setShowPaynow(false)}}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>

            <img className="paynow_logo" src="paynow_logo.png"/>

            <div className="paymentContainer paynow">
                
                <div className="uen_no_header">
                    UEN No :
                </div>
                <div className="uen_no_container">
                    {props.paynowNumber}
                </div>
                
                

            </div>

            <div className="paynow_content"> 
                Please provide a screenshot of your paynow @ <span style={{
                    marginLeft: "5px",
                    fontSize: "18px"
                }} className="h5 text-danger">{alt_num}</span>   
            </div>

            <Modal.Footer style={{borderTop:0}} className="modal-footer form-action">
                <button
                    className="btn lg primary d-block"
                    onClick={()=>{
                        props.setShowPaynow(false)
                    }}
                >
                    Proceed
                </button>
            </Modal.Footer>

        </Modal>
    </>
    
    )
}

const mapStateToProps = (state) => ({
  business_location: state.products.business_location
});
export default withTranslation()(
  // connect(mapStateToProps, { clearCartSingle, clearCart, placeOrder, getTaxes, submitOrder, updateOrder })(
  connect(mapStateToProps, {  })(
    Paynow
  )
);