import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../components/Modals/Step.css"

import close from "../../assets/svgs/close.svg";
import logout from "../../assets/svgs/log-out.svg";
import "../../assets/css/modal.css";
import { useHistory } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";

const SignupComplete = ({ show, closeModal, business_id, location_id, joinus }) => {
  const history = useHistory();

  return (
    <Modal
      show={show}
      onHide={() => closeModal}
      dialogClassName="modal-smd maxwidth500"
      centered
    >
      <Modal.Body className="p-2">
        <div className="modal-body d-flex justify-content-center align-items-end">
          <button
            type="button"
            className="close-modal-btn"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              if (joinus){
                window.location.href = "/?business_id=" + business_id + "&location_id=" + location_id
              } else {
                closeModal()
              }
            }}
          >
            <img src={close} width="20" alt="close" />
          </button>
          <div className="text-center" style={{marginTop: '5vh'}}>
            <div className="f40 claim">
                <h1>GREAT!</h1>
            </div>
            <p>Please verify your email using the link that was sent. If you have not received the verification email, please check your "Spam" or "Bulk Email" folder.</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer no">
        <button
          type="button"
          className="btn btn-danger claim-button btn-block py-0"
          data-dismiss="modal"
          onClick={() => {
            closeModal();
            if (joinus){
              window.location.href = "/?business_id=" + business_id + "&location_id=" + location_id
            } else {
              history.push("/?business_id=" + localStorage.business_id + "&location_id=" + localStorage.location_id);
            }
            
          }}
        >
            START ORDERING NOW!
        </button>
        <div style={{width:'100%', fontSize:'10pt'}}>
            <p>Powered by Warely</p>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignupComplete;
