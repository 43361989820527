import "./VerificationInput.css"
import { useRef, useEffect, useState } from "react"




const VerificationInput = (props) => {

    const [elements, setElement] = useState([])
    const containerElement = useRef(null)

    
    //props.setInputText()

    useEffect(()=>{
        let elementArray = [] //Contains input elements to render
        let text = [...props.inputText]
        console.log(text)
        for (let i = 0; i < props.maxLength; ++i) {
            setElement(elementArray.push(
                <input className="form_control" maxLength={1} data-index={i} key={i} onKeyDown={(event)=>{
                    event.preventDefault()
                    
                    //console.log(event.target.dataset.index, event.key)
                    let currentIndex = parseInt(event.target.dataset.index)
                    let currentElement = document.querySelector(`[data-index="${currentIndex}"]`)
                    if (event.key === "Backspace") { //focus to previous input element if backspace
                        text[currentIndex] = ""
                        props.setInputText([...text])
                        let newIndex = ( (currentIndex - 1) < 0 ) ? 0 : currentIndex - 1 
                        currentElement.value = ""
                        document.querySelector(`[data-index="${newIndex}"]`).focus() 
                    } else if (event.key >= 0 && event.key < 10){ //Else focus to next element on any number input
                        text[currentIndex] = event.key
                        props.setInputText([...text])
                        let newIndex = ( (currentIndex + 1) > (props.maxLength-1) ) ? (props.maxLength-1) : currentIndex + 1 
                        currentElement.value = event.key
                        document.querySelector(`[data-index="${newIndex}"]`).focus()
                        
                    } else if (event.key === "ArrowRight") {
                        let newIndex = ( (currentIndex + 1) > (props.maxLength-1) ) ? (props.maxLength-1) : currentIndex + 1
                        document.querySelector(`[data-index="${newIndex}"]`).focus()
                    } else if (event.key === "ArrowLeft") {
                        let newIndex = ( (currentIndex - 1) < 0) ? 0 : currentIndex - 1
                        document.querySelector(`[data-index="${newIndex}"]`).focus()
                    }
                    
                }} />
            ))
        }
        document.querySelector(`[data-index="0"]`)?.focus()
        setElement(elementArray)
    }, [])


    return (
        <div id="input_container" ref={containerElement}>
            {elements}
        </div>
        )
}

export default VerificationInput