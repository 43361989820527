import { useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import close from "../../assets/svgs/close.svg";
import axios from 'axios';
import "../../assets/css/modal.css";
import PropTypes from "prop-types";
import "../../assets/css/component.css";
import { turnOnButton, turnOffButton } from './buttonFunctions';
import VerificationInput from './VerificationInput/VerificationInput';
import { doneLoading, load, confirmVerification, clearUserState } from '../../actions/userActions';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearState } from '../../actions/authActions';


const EmailVerification = (props) => {
    const [inputText, setInputText] = useState(Array(6).fill(""))
    const buttonElement = useRef(null)
    const [resendState, setResendState] = useState(false)
    const defaultValue = 60
    const [seconds, setSeconds] = useState(defaultValue)
    const interval = useRef(null)
    const [errorMessage, setErrorMessage] = useState("")

    const decrement = () => {
      setSeconds((previousState)=>previousState-1)
    }

    const hideModal = () => {

    }

    const handleSubmit = () => {
        props.load()
        let fullInput = inputText.join("")
        setErrorMessage("")
        props.confirmVerification(fullInput)
    }

    useEffect(()=>{ //IF REQUEST FIRED BY PROPS.CONFIRMVERIFICATION(FULLINPUT) IS SUCCESS, TRIGGER THIS USE EFFECT
      if (props.user.correctCode) {
        props.setResetData({...props.resetData, "token":props.user.correctCode.data.token})
        props.verificationVisibility(false)
        props.confirmationVisibility(true)
      }
    }, [props.user.correctCode])

    useEffect(()=>{
      if (props.user.errorMessage) {
        if (props.user.errorMessage.statusText === "Not Found") {
          setErrorMessage("Invalid verification code")
        }
      }
    }, [props.user.errorMessage])


    useEffect(()=>{
        turnOnButton(buttonElement.current)

        let fullInput = inputText.join("")
        console.log(fullInput)
        if (fullInput?.length === 0 || fullInput?.length < 6) {
          turnOffButton(buttonElement?.current)
        }

    }, [inputText])


    useEffect(()=>{
      if (seconds === 0) {
        clearInterval(interval.current)
        setResendState(false)
        interval.current = null
        setSeconds(defaultValue)
        setInputText([])
      }
    }, [seconds])

    return (
        <Modal
        show={props.show}
        dialogClassName="modal-md xs"
        contentClassName="xs"
        onHide={ ()=>{
          setErrorMessage("")
          props.clearUserState()
          clearInterval(interval.current)
          setResendState(false)
          interval.current = null
          setSeconds(defaultValue)
          props.verificationVisibility(false)
        } }
        centered
        >
        <form onSubmit={handleSubmit}>
          <Modal.Body className=" px-5 py-4">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={()=>
              {
                setErrorMessage("")
                props.clearUserState()
                clearInterval(interval.current)
                setResendState(false)
                interval.current = null
                setSeconds(defaultValue)
                props.verificationVisibility(false)
                setInputText([])
              }}
            >
              <img src={close} width="20" alt="close" />
            </button>
            <div style={{marginBottom:'5%'}} className="text-center mt-5">
              <div className="back-heading">Complete verification</div>
              <div className="warley-description">
                A verification code has been sent to the email
              </div>
              <div className="mt-5">
                <div className="input-title mb-2">Enter verification code</div>
                <div className="mb-2">
                  <VerificationInput maxLength={6} setInputText={setInputText} inputText={inputText}/>
                </div>
                <div className="powered" style={{textAlign:"center", color:"red"}}>{errorMessage}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer no">
          <div className="powered my-1 w-100">Did not receive verification code? <a className="powered "
          onClick={()=>{
            if (resendState === false) {
              axios.post("/password/create", {"email": props.passwordEmail}).then((res)=>{
              }).catch((error)=>{
                console.log(error)
              })
              interval.current = setInterval(()=>{
                decrement()
              }, 1000)
              setResendState(true)
            }
          }}
          
          >{resendState === false ? "Resend" : "Resend in " + seconds + "s"}</a></div>
            <button
              className="btn btn-block btn-danger font-weight-bold py-3 rounded f18"
              type="submit" disabled style={{opacity: 0.5}} ref={buttonElement}
            >
              {" "}
              Submit verification code
              <img src="images/log-out.svg" className="ml-2" alt="" />
            </button>
            <div className="powered my-4 w-100">Powered by Warely</div>
          </Modal.Footer>
        </form>    
      </Modal>
    )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withTranslation()(
  connect(mapStateToProps, {
    load, doneLoading, confirmVerification, clearUserState
  })(EmailVerification)
);