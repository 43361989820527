import {useState, useEffect} from "react"
import { Modal, Container, Row, Col } from "react-bootstrap"
import closeButton from "../../assets/images/close.png"
import "../../assets/css/icon.css";



const SingleOrder = (props) => {
  

  const [showOrder, hideOrder] = useState(false)
  const [foodItems, populateItems] = useState([])
  
  const deliveryStates = ["ASSIGNING_DRIVER", "ON_GOING", "PICKED_UP", "COMPLETED"]
  const [activeStateDelivery, changeStateDelivery] = useState(["", "", "", ""])

  const returnAddons = (obj) => {

    let addonString = ""
    if (obj.adons.length > 0) {
      for (let i = 0; i < obj.adons.length; ++i) {

        if (i === obj.adons.length-1) {
          addonString +=  obj.adons[i].name
        } else {
          addonString += obj.adons[i].name  + ", " 
        }
      }
    }

    return addonString
  }

  useEffect(()=>{
      if (props.order){
        const status = props.order.lalamove_status
        let numOfActive = deliveryStates.indexOf(status)
        let x = [...activeStateDelivery]
        for(let i = 0; i <= numOfActive; ++i) {
          x[i] = "active" //make a shallow copy of the array
          console.log(activeStateDelivery)
          changeStateDelivery([...x])
        }
      } 

    if (props.order) {
      const tempArray = []
      for (let i = 0; i < props.order.item.length; ++i) {
        const item = props.order.item

        if ( (i !== 0 || i === props.order.item.length - 1) && props.order.item.length !== 1) {
          tempArray.push(
            <div key={i}>
              <div className="dashed"></div>
              <Row className="m-3">
                <Col className="item-description quantity" xs={2}>
                  {item[i].quantity}  x
                </Col>
                <Col className="item-description" xs={6}>
                  <Row data-testid={`${item[i].product_id}`}>
                    {item[i].name}
                  </Row>
                  <Row className="mt-3" >
                    <div style={{fontWeight:"normal", fontSize:"13px"}}>{returnAddons(props.order.item[i])}</div>
                  </Row>
                </Col>
                <div className="item-description price">
                  {String((item[i].total_for_single_item.toFixed(2)))}
                </div>
              </Row>
            </div>
            )

        } else {
          tempArray.push(
            <div key={i}>
              <Row className="m-3">
                <Col className="item-description quantity" xs={2}>
                  {item[i].quantity} x
                </Col>
                <Col className="item-description" xs={6}>
                  <Row data-testid={`${item[i].product_id}`}>
                    {item[i].name}
                  </Row>
                  <Row className="mt-3">
                    <div style={{fontWeight:"normal", fontSize:"13px"}}>{returnAddons(props.order.item[i])}</div>
                  </Row>
                </Col>
                <div className="item-description price">
                  {String((item[i].total_for_single_item.toFixed(2)))}
                </div>
              </Row>
            </div>
            )
  
        }
      
      }
      populateItems([...tempArray])    
    }

  }, [props.order])
  
    return (
        <>

        <Modal size="sm" centered show={showOrder}>
          <Modal.Header>
            <h5 className="m-1">My order  <span className="ref_no">{props.order.ref_no}</span></h5>
            <button style={{
              borderRadius:"50%",
              padding:"7px 7px 7px 15px",
              border:"1.2px solid black",
              backgroundColor:"white",
              height:"25px",
              width:"25px",
              position:"relative"
            }} className="m-1" onClick={()=>{hideOrder(false)}}>
              <img style={{position: "absolute", left: "4.7px", top:"4.85px", width:"14px", height:"14px"}} src={closeButton}/>
            </button>
          </Modal.Header>

          <Modal.Body className="p-3">
            {foodItems}
          </Modal.Body>

        </Modal>

        <div className="main_section mb-5">
          <div className="track-gutter m-lg-5 m-md-5" data-testid={props.order.ref_no}>
            {(

              <div className="ins">
                {
                props?.order?.type_for_api === "Delivery" ? (<div className="panel-ember">
                    <div className="data">
                      <label className="control-label">Your order number</label>
                      <span className="form-control-static">
                        {" "}
                        {props?.order?.ref_no} 
                      </span>
                    </div>
                    <a onClick={()=>{hideOrder(true)}}
                    // href={order.id && "/order-history/" + order.id} //CHANGE
                      className="btn primary"
                    >
                      View order
                    </a>
                  </div>) :
                
                  (<div className="panel-ember">
                    <div className="data">
                      <label className="control-label">Your order number</label>
                      <span className="form-control-static">
                        {" "}
                        {props?.order?.ref_no} 
                      </span>
                    </div>
                    <a onClick={()=>{hideOrder(true)}}
                    // href={order.id && "/order-history/" + order.id} //CHANGE
                      className="btn primary"
                    >
                      View order
                    </a>
                  </div>)
                }


                {props?.order?.type_for_api === "Delivery" ? (
                  <>
                    <ul key={1} className="step-list">
                      <li data-status={0} className={activeStateDelivery[0]}>
                        <span>Assigning Driver</span>
                      </li>
                      <li data-status={1} className={activeStateDelivery[1]} >
                        <span>On Going</span>
                      </li>
                      <li data-status={2} className={activeStateDelivery[2]}>
                        <span>Picked Up</span>
                      </li>
                      <li data-status={3} className={activeStateDelivery[3]}
                      >
                        <span>Completed</span>
                      </li>
                    </ul>
                    <div key={2} className="box">
                      <div style={{margin:0}} className="row">
                        <div className="col-md-6">
                          <div className="item-box">
                            <div className="form-group">
                              <label className="control-label">Deliver To</label>
                              <span className="form-control-static">
                                {props?.order?.d_address}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Estimated Arrival
                              </label>
                              <span className="form-control-static disabled">
                                <span className="date">
                                  {props?.order?.estimated_arrival_time?.split(" ")[0]}
                                </span>
                                <span className="time mx-2">
                                  {props?.order?.estimated_arrival_time?.split(" ")[1]}
                                </span>
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="control-label">Payment</label>
                              <span className="form-control-static">
                                {props?.order?.paymentType?.toUpperCase()}  {props?.order?.last_4_card_digits}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="item-box">
                            <div className="form-group">
                              <label className="control-label">Contact us:</label>
                              <span className="form-control-static-call"  style={{fontWeight:"bold"}}>
                                {props?.order?.business_location_mobile_for_delivery}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <ul key={1} className="step-list">
                      <li
                        className={"active"
                        }
                      >
                        <span>Order Received</span>
                      </li>
                      <li className={props.order.pickup_completed !== 0 ? "active" : ""}
                      >
                        <span>Collected</span>
                      </li>
                    </ul>
                    <div key={2} className="box">
                      <div style={{margin:0}} className="row">
                        <div className="col-md-6">
                          <div className="item-box">
                            <div className="form-group">
                              <label className="control-label">Pick up outlet</label>
                              <span className="form-control-static">
                                {props.order.business_location_name_for_pickup ? props.order.business_location_name_for_pickup: "NA"}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Picked up time
                              </label>
                              <span className="form-control-static disabled">
                                <span style={{color:"#4B4B4B"}}>
                                  {props.order.d_date}
                                </span>
                                <span style={{color:"#4B4B4B"}}>
                                  {props.order.d_time}
                                </span>
                              </span>
                            </div>
                            <div className="form-group">
                            <label className="control-label">Payment</label>
                            { props.order.paymentType === "cash" || props.order.paymentType === "paynow" ? 
                              <span className="form-control-static">
                                {props.order.paymentType.toUpperCase()}
                              </span>
                              : <span className="form-control-static">
                                {props.order.card_type ? props.order.card_type.toUpperCase() : ""} { props.order.card_type === "paynow" ? "" : props.order.last_4_card_digits}
                              </span>
                            }
                            </div> 
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="item-box">
                            <div className="form-group">
                              <label className="control-label">Contact us:</label>
                              <span className="form-control-static-call" style={{fontWeight:"bold"}}>
                                {props.order.business_location_mobile_for_pickup ? props.order.business_location_mobile_for_pickup: "NA"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) }
          </div>
        </div>
      </>
    );
}

export default SingleOrder