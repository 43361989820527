import React, { useState, useEffect, useRef } from "react";
import "./Payment.css";
import "../../assets/css/modal.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SingleOrder, submitPayment, clearPayment, placeDeliveryOrder, getStripeAndLalamoveKey } from "../../actions/productActions";
import OrderPlaced from "../../components/OrderPlaced/OrderPlaced";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import TrackOrder from "../../components/TrackOrder/TrackOrder";
import TrackOrderNow from "../../components/TrackOrderNow/TrackOrderNow";
import { AiOutlineArrowLeft, AiOutlineCloseCircle } from 'react-icons/ai'
import Spinner from "../../common/Spinner";
import axios from "axios";


import {
  clearCart
} from "../../actions/productActions";

const Payment = (props) => {

  // console.log("PAYMENTTTTTTTT");
  // console.log(localStorage);

  const myRef = useRef();
  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState([]);
  const [orderType, setOrderType] = useState(1);
  const [local, setLocal] = useState([]);
  const [user, setUser] = useState([]);
  const [udata, setUdata] = useState(false);
  const [showOrderPlaced, setShowOrderPlaced] = useState(false);
  const [submitting, setSubmitState] = useState(false)
  const [showtrackOrder, setShowTrackOrder] = useState(false);
  const [showTrackNow, setShowTracknow] = useState(false);
  const { register, handleSubmit} = useForm();
  const [ ticked, setTicked ] = useState(false)


  let deliveryInformation
  if (localStorage.deliveryInformation) {
    deliveryInformation = JSON.parse(localStorage.deliveryInformation) //DELIVERY INFORMATION CONTAINING LATITUDE, LONGTITUDE ...etc
  }




  
  //KEEP TRACK OF INPUT FIELD VALUES USING THE ONCHANGE EVENT LISTENER
  const defaultErrors = {
    name: {value:"", error:""},
    cardNumber: {value:"", error:""},
    cvc: {value:"", error:""},
    expirationMonth: {value:"", error:""},
    expirationYear: {value:"", error:""},
    firstName: {value: "", error:""},
    lastName: {value: "", error:""},
    address: {value:"", error:""},
    city: {value:"", error:""},
    zip: {value:"", error:""},
    country: {value:"", error:""},
    phone: {value:"", error:""},
    email: {value:"", error:""}
  }

  useEffect(()=>{

    // SETS DEFAULT VALUES FOR INPUT FIELDS THAT REQUIRE THE LOCAL STORAGE FOR INITIAL UPDATES
    setErrors({ 
      ...errors, 
      firstName: {...errors.firstName , value: user.fname ? user.fname : "" },
      lastName: {...errors.lastName , value: user.lname ? user.lname : "" },
      address: {...errors.address , value: user.address ? user.address : "" },
      city: {...errors.city , value: user.city ? user.city : "" },
      zip: {...errors.zip , value: user.zip ? user.zip : "" },
      country: {...errors.country , value: user.country ? user.country : "" },
      phone: {...errors.phone, value: user.contactNo ? user.contactNo : ""},
      email: {...errors.email, value: user.email ? user.email : ""}
    })

  }, [user])



  const returnErrorMessage = (error) => {
    return error === "" ? "" : <span className="Error">{error}</span>
  }


  //FUNCTION FOR AFTER INITIAL BASIC VALIDATIONS, CHECK IF THERE ARE NO ERRORS IN THE ERRORS OBJECT
  //SO IT WILL GO THROUGH THE STRIPE API CALL
  const noError = (obj) => {
    for (let key in obj) {
      if (obj[key].error !== "") {
        return false
      } 
    }
    return true
  }


  
  const [errors, setErrors] = useState(defaultErrors) //MAIN STATE VARIABLE FOR KEEPING TRACK OF INPUT ELEMENTS

  const [stripeToken, setStripeToken] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);
  const history = useHistory();


  //CURRENT DATES FOR DELIVERY AND PICKUP
  let date = new Date()
  date = new Date(date.toLocaleString("en-us", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}))
  let currentDate = date.toISOString().split("T")[0]
  let currentTime = date.toLocaleString("en-us", {hour:"numeric",minute:"numeric",hour12:true})


  const onSubmit = (data, event) => {
    // if(!data.a_tos){

    // }
    // if(!data.u_offers){

    // }
    // console.log(data);
    // console.log(localStorage);
    // console.log(JSON.parse(localStorage.cart));
    // console.log(localStorage.login);

    event.preventDefault();
    window.scrollTo(0, 0)

    //USED AS BAND AID AS THERE IS AN ISSUE IN THE ...REGISTER() WHERE ADDING AN ONCHANGE EVENT LISTENER
    //AND SETTING DEFAULT VALUES DO NOT GET PASSED IN THE DATA IN ONSUBMIT(DATA, EVENT)
    //SO USE THE VALUE PROPERTY AS THEY ARE BEING UPDATED IN THE (ERRORS) STATE VARIABLE
    const updatedData = {
      ...data, 
      card_name: errors.name.value,
      card_number: errors.cardNumber.value,
      ccv: errors.cvc.value,
      e_date_m: errors.expirationMonth.value,
      e_date_y: errors.expirationYear.value,
      fname: errors.firstName.value,
      lname: errors.lastName.value,
      address: errors.address.value,
      city: errors.city.value,
      zip: errors.zip.value,
      country: errors.country.value,
      phone: errors.phone.value,
      email: errors.email.value
    }


    const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    

    //SETS A PLACEHOLDER OBJECT FOR THE MAIN STATE VARIABLE, (ERRORS)
    let errorObj = {
      ...errors
    }

    //RESETS ERRORS AFTER EVERY SUBMIT
    for (let key in errorObj) { 
      errorObj[key].error = ""
    }

    ////// VALIDATION //////
    if (errors.name.value === "") {
      errorObj = { ...errorObj, name:{...errors.name, error: "Name cannot be empty"} }
    } 
    
    if (errors.cardNumber.value.length !== 16) {
      errorObj = { ...errorObj, cardNumber:{...errors.cardNumber, error: "Card number must be minimum 16 digits"}}
    }
    
    if (errors.cvc.value.length < 3) {
      errorObj = { ...errorObj, cvc:{...errors.cvc, error: "Security Code must be a minimum length of 3"}}
    }
    
    if (errors.expirationMonth.value.length < 2) {
      errorObj = { ...errorObj, expirationMonth: {...errors.expirationMonth, error: "Expiration month must be 2 digits"}}
    }
    
    if (errors.expirationYear.value.length < 2) {
      errorObj = { ...errorObj, expirationYear: {...errors.expirationYear, error: "Expiration year must be 2 digits"}}
    } 
    
    if (errors.firstName.value === "") {
      errorObj = { ...errorObj, firstName: {...errors.firstName, error: "First name cannot be empty"}}
    } 
    
    if (errors.lastName.value === "") {
      errorObj = { ...errorObj, lastName: {...errors.lastName, error: "Last name cannot be empty"}}
    } 
    
    if (errors.address.value === "") {
      errorObj = { ...errorObj, address: {...errors.address, error: "Address cannot be empty"}}
    } 
    
    if (errors.city.value === "") {
      errorObj = { ...errorObj, city: {...errors.city, error: "City cannot be empty"}}
    } 
    
    if (errors.zip.value === "") {
      errorObj = { ...errorObj, zip: {...errors.zip, error: "Zip code cannot be empty"}}
    } 
    
    if (errors.country.value === "") {
      errorObj = { ...errorObj, country: {...errors.country, error: "Country cannot be empty"}}
    } 
    
    if (errors.phone.value === "") {
      errorObj = { ...errorObj, phone: {...errors.phone, error: "Phone number cannot be empty"}}
    } 

    if (emailRe.test(errors.email.value)===false) {
      errorObj = { ...errorObj, email: {...errors.email, error: "Invalid email"}}
    }


    //UPDATE THE STATE SO COMPONENT CAN RENDER THE ERRORS
    setErrors({...errorObj})
    

    //GO THROUGH THE API CALL IF NO ERROR AFTER BASIC VALIDATION CHECKS IN THE PREVIOUS LINES
    if (noError(errorObj)) {
      setSubmitState(true)      

      /* Stripe Submit - Start */
      window.Stripe.card.createToken({
        number: updatedData.card_number,
        exp_month: updatedData.e_date_m,
        exp_year: updatedData.e_date_y,
        cvc: updatedData.ccv,
        name: updatedData.name,
        address_line1: updatedData.address,
        address_city: updatedData.city,
        address_zip: updatedData.zip,
        address_country: updatedData.country
      }, (status, response) => {
        setSubmitState(false)
        if (status === 200) {
          
          updatedData['method'] = response.type;
          updatedData['card_type'] = response.card.brand;
  
          console.log('-------- Stripe -----------');
  
          //UPDATE THE STATE AND SET IT TO THE DATA TO TRIGGER THE USE EFFECT
          setSubmittedData(updatedData);
          setStripeToken(response.id);

          console.log(response);
          console.log({
            message: `Success! Card token ${response.id}.`,
            formProcess: false
          });
        } else {
          console.log({
            message: response.error.message,
            formProcess: false
          });
  
  
  
          
          //////// SPECIFIC CARD VALIDATIONS ////////
          if (response.error.code==="invalid_number" || response.error.code==="incorrect_number") {
            setErrors({ ...errorObj, cardNumber:{...errors.cardNumber, error: "Invalid Card Number"}})
          }
          if (response.error.code==="invalid_expiry_year") {
            setErrors({ ...errorObj, expirationYear:{...errors.expirationYear, error: "Invalid Year"}})
          }
          if (response.error.code==="invalid_expiry_month"){
            setErrors({ ...errorObj, expirationMonth:{...errors.expirationMonth, error: "Invalid Expiry Month"}})
          }
          if (response.error.code==="incorrect_cvc" || response.error.code==="invalid_cvc") {
            setErrors({ ...errorObj, cvc:{...errors.cvc, error: "Invalid CVC"}})
          } if (response.error.code === "card_declined") {
            setErrors({ ...errorObj, cardNumber:{...errors.cardNumber, error: "Card declined"}})
          }
          console.log(response.error)
        }
        
      });
    }



    
    /* Stripe Submit - End */

    // if (localStorage.login) {
    //   props.submitPayment(data);
    // } else {
    //   props.submitPayment({ ...data, local });
    // }

    // 
  };

  useEffect(() => {
    props.getStripeAndLalamoveKey();
  }, [])

  useEffect(()=> {
    if (props.stripeLalaMoveKeys.length > 0) {
      console.log(props.stripeLalaMoveKeys)
      loadStripe();
    }
  }, [props.stripeLalaMoveKeys])

  useEffect(() => {
    if (
      !props.loading &&
      // props.orders !== null &&
      // Object.keys(props.orders.data).length > 0 &&
      props.order !== null &&
      Object.keys(props.order).length > 0 &&
      // props.orders.success &&
      load
    ) {
      // setOrder(props.orders.data[0]);
      setOrder(props.order.data);

      setLoad(true);
    }

    if (!load) {
      if (localStorage.login) {
        // props.SingleOrder(id); 
        props.SingleOrder();

        setLoad(true);
        if (localStorage.headers) {
          let userData = JSON.parse(localStorage.headers);
          setUser(userData.data.user);
        }
        setUdata(true);
        setOrderType(parseInt(localStorage.getItem("order_type")));
      } else {
        setUdata(true);

        if (localStorage.cart) {
          setLocal(JSON.parse(localStorage.cart));
        }
        if (localStorage.order_type) {
          setOrderType(parseInt(localStorage.getItem("order_type")));
        }
      }
    }

    // console.log(!props.loading, props.payment !== null, props.payment?.order_no, loaded);

    if (
      !props.loading &&
      props.payment !== null &&
      // Object.keys(props.payment.data).length > 0 &&
      props.payment?.data?.order_no &&
      loaded
    ) {
      // console.log('HELLO');

      if (localStorage.headers) {
        let userData = JSON.parse(localStorage.headers);

        // console.log(userData);
        // userData.data.user = props.payment.data && props.payment.data.user_info;
        // localStorage.setItem("headers", JSON.stringify(userData));
        // setUser(userData.data.user);
      }

      
      
      // history.push("/");
    }

    


  }, [history, id, load, loaded, props]);


  console.log(props.payment)
  useEffect(()=>{
    if (props.payment){
      console.log(errors)
      const data = JSON.parse(localStorage.headers)?.data

      data.user["city"] = errors.city.value
      data.user["address"] = errors.address.value
      data.user["zip"] = errors.zip.value
      data.user["country"] = errors.country.value

      localStorage.setItem("headers", JSON.stringify({data: data}))
      
      // const defaultErrors = {
      //   name: {value:"", error:""},
      //   cardNumber: {value:"", error:""},
      //   cvc: {value:"", error:""},
      //   expirationMonth: {value:"", error:""},
      //   expirationYear: {value:"", error:""},
      //   firstName: {value: "", error:""},
      //   lastName: {value: "", error:""},
      //   address: {value:"", error:""},
      //   city: {value:"", error:""},
      //   zip: {value:"", error:""},
      //   country: {value:"", error:""},
      //   phone: {value:"", error:""},
      //   email: {value:"", error:""}
      // }
      

      if (orderType === 1) {
        const data = {
          "transaction_id": props.payment.data.transaction_id,
          "business_id": localStorage.business_id,
          "outlet_lat": deliveryInformation.deliveryOutLat,
          "outlet_lng": deliveryInformation.deliveryOutLong,
          "outlet_add": deliveryInformation.deliveryOutAddress,
          "cust_lat": deliveryInformation.customerLat ?? 0,
          "cust_lng": deliveryInformation.customerLong ?? 0,
          "cust_add": deliveryInformation.customerAddress,
          "cust_name": deliveryInformation.customerName,
          "cust_phone": deliveryInformation.customerPhone,
          "cust_remark": deliveryInformation.note,
          "quoted_fee": deliveryInformation.deliveryCharge,
          "quoted_currency": deliveryInformation.deliveryCurrency,
          "location_id": localStorage.location_id
          }

        props.placeDeliveryOrder(data)

      } else {
        setShowOrderPlaced(true)
        localStorage.setItem("order_id", props.payment.data.order_no);
        setLoaded(false);
        localStorage.removeItem("cart");
        localStorage.removeItem("cartID");
        localStorage.removeItem("cart_data");
        localStorage.removeItem("deliveryInformation")
        props.clearCart()
      }
    }
  }, [props.payment])

  useEffect(()=>{
    if (props.place_order) {
      setShowOrderPlaced(true)
      localStorage.setItem("order_id", props.payment?.data?.order_no);
      setLoaded(false);
      localStorage.removeItem("cart");
      localStorage.removeItem("cartID");
      localStorage.removeItem("cart_data");
      localStorage.removeItem("deliveryInformation")
      props.clearCart()
    }

  }, [props.place_order])

  useEffect(() => {
    if (stripeToken) {
      var user = JSON.parse(localStorage.headers)?.data?.user;
      var products = JSON.parse(localStorage.products)[0];
      var serviceFee = JSON.parse(localStorage.serviceFee)[0];

      var orderTypeName = '';

      // console.log(user);
      // console.log(JSON.parse(localStorage.getItem('cart')));
      // console.log(products);
      // console.log(serviceFee);
      // console.log(submittedData);

      // console.log(parseFloat(serviceFee.cart_sum_wo_tax_price), parseFloat(serviceFee.d_charge), parseFloat(serviceFee.gst_charge));

      if (orderType == 1) {
        orderTypeName = 'Delivery';
      }
      else if (orderType == 2) {
        orderTypeName = 'Pickup';
      }

      //FORMAT DATE DURING PAYMENT SUBMISSION
      

      var submit_data = {
        "business_id": localStorage.business_id,
        "user_id": user?.id,
        "location_id": localStorage.location_id,
        "sub_type": "",
        "contact_id": user?.contact_id,
        "search_product": "",
        "pay_term_number": "",
        "pay_term_type": "",
        "res_table_id": "",
        "res_waiter_id": "",
        "sell_price_tax": "includes",
        "type": "sell",
        "type_for_api": orderTypeName,
        "products": products,
        "discount_type": "percentage",
        "discount_amount": 0,
        "additional_discount_method": '',
        "service_charges": 0,
        "tax_rate_id": 1,
        "tax_calculation_amount": 7,
        "rp_redeemed": 0,
        "rp_redeemed_amount": 0,
        "shipping_details": "",
        "shipping_address": "",
        "shipping_status": "",
        "delivered_to": "",
        "shipping_charges": 0,
        "advance_balance": 0,
        "payment": [
            {
                "payment_id": "",
                "amount": parseFloat(serviceFee.cart_sum_wo_tax_price) + ((orderType == 1)?(parseFloat(serviceFee.d_charge)):0) + parseFloat(serviceFee.gst_charge),
                "method": submittedData.method,
                "card_type": submittedData.card_type,
                "card_holder_name": submittedData.card_name,
                "card_month": submittedData.e_date_m,
                "card_number": submittedData.card_number,
                "card_security": submittedData.ccv,
                "card_year": submittedData.e_date_y
            }
        ],
        "sale_note": localStorage.additional_note,
        "staff_note": "",
        "change_return": 0,
        "additional_notes": "",
        "is_suspend": 0,
        "recur_interval": 1,
        "recur_interval_type": "days",
        "recur_repetitions": "",
        "subscription_repeat_on": "",
        "size": "all",
        "is_enabled_stock": "",
        "is_credit_sale": 0,
        "total_before_tax": parseFloat(serviceFee.cart_sum_wo_tax_price),
        "final_total": parseFloat(serviceFee.cart_sum_wo_tax_price) + ((orderType == 1)?(parseFloat(serviceFee.d_charge)):0) + parseFloat(serviceFee.gst_charge),
        "discount_type_modal": "percentage",
        "discount_amount_modal": "0",
        "order_tax_modal": "",
        "shipping_details_modal": "",
        "shipping_address_modal": "",
        "shipping_charges_modal": 0,
        "shipping_status_modal": "",
        "delivered_to_modal": "",
        "status": "final",
        "stripe_token": stripeToken,
        "first_name": submittedData.fname,
        "last_name": submittedData.lname,
        "gender": submittedData.gender,
        "billing_address": submittedData.address,
        "city": submittedData.city,
        "zip_code": submittedData.zip,
        "country": submittedData.country,
        "phone": submittedData.phone,
        "email": submittedData.email,
        "accept_term_and_cond": 1,
        "occasional_offers": submittedData.u_offers ? 1 : 0,
        "d_address": orderType === 1 ? localStorage.d_address : "",
        "d_date": orderType === 1 ? currentDate : currentDate , // {* CHANGE LATERS}
        "d_time": orderType === 1 ? currentTime : localStorage.time,
        "outlet": "",
        "pickup_completed": 0,
        "delivery_charges" : 0.0
      };

      // console.log(orderType, localStorage);

      if(orderType == 1) { // for delivery
        submit_data.d_address = localStorage.d_address
        submit_data.outlet = deliveryInformation.deliveryOutId
        submit_data.delivery_charges = parseFloat(deliveryInformation.deliveryCharge)
      } else if(orderType == 2) { // for pick up
        submit_data.outlet = localStorage.outlate;
      }


      if (localStorage.login) {
        props.submitPayment(submit_data);
      }

      console.log(submit_data)
    }
    setLoaded(true);
    setStripeToken(null);
  }, [stripeToken])

  const loadStripe = () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        console.log();
        if(props.stripeLalaMoveKeys && props.stripeLalaMoveKeys[0]['stripe_pk']) {
          window['Stripe'].setPublishableKey(props.stripeLalaMoveKeys[0]["stripe_pk"]);
        }
        // window['Stripe'].setPublishableKey('pk_test_51JbSBJEM5INsltd1Gu519DOKBK9turtHjz3pl2gF1vBfz1KLhauTkq0M11KTpXZyZSHGgd4piVpGPyQwGF7h6AYy00tnffyrqc'); // for dev testing
        // window['Stripe'].setPublishableKey('pk_test_Uae4redOdqbbCrnPaismUza000nf3FDQVq'); // live testing
        // window['Stripe'].setPublishableKey('pk_live_Hj273qahTTerpulbKdz8MWFf00MSjvzmZp'); // live use
      }
      window.document.body.appendChild(s);
    }
  }

  const conAddress = (address) => {
    let re_address = [];
    if (address !== null && address !== "") {
      let address_arr = JSON.parse(address);
      re_address.push(
        <>
          <p>
            {address_arr.u_no},{address_arr.b_name},{address_arr.pcode}
          </p>
          <span>{address_arr.note}</span>{" "}
        </>
      );
    }

    return re_address;
  };
  const conDate = (conDate) => {
    let re_date = "";
    if (conDate !== null) {
      let date = new Date(conDate);
      return date.toDateString();
    }
    return re_date;
  };
  const showTrackOrderNowFunction = () => {
    setShowTrackOrder(false);
    setShowTracknow(true);
  };
  const trackOrderPage = () => {
    setShowTracknow(true);
    history.push("/track-order/" + order.id);
  };
  const showTrackOrder = () => {
    setShowOrderPlaced(false);
    setShowTrackOrder(true);
  };

  const closePlaceorder = () => {
    setShowOrderPlaced(false);
    console.log('close place order');

    history.push('/?business_id=' + localStorage.business_id + '&location_id=' + localStorage.location_id);
  }
  
  

  return (
    <>
    { submitting ? <Spinner/> : ""}
    <div className="main_section">
      <div className="row d-flex justify-content-between mx-1 payment-details-header">
        <div className="arrowBack" style={{marginTop:"3px"}}>
          <a onClick={history.goBack}>
            <AiOutlineArrowLeft size="30"/>
          </a>
        </div>
        <div className="payment-header">
          <h3>
            Payment Details
          </h3>
          <div style={{ borderBottom: '1px solid #bbb'}}></div>
        </div>
        <div className="flex-end circleClose" style={{marginTop:"3px"}}>
          <a onClick={history.goBack}>
            <AiOutlineCloseCircle size="30"/>
          </a>
        </div>

      </div>
      <div className="payment-gutter">
        <OrderPlaced
          clearPayment={props.clearPayment}
          regRef={props.regRef}
          // orders={order}
          show={showOrderPlaced}
          closeModal={() => setShowOrderPlaced(false)}
          TrackOrder={() => setShowTracknow(true)}
        />
        <TrackOrderNow
          show={showTrackNow}
          closeModal={() => setShowTracknow(false)}
          trackOrder={() => setShowTracknow(true)}
        />

        {/* <TrackOrderNow
          show={showTrackNow}
          closeModal={() => setShowTracknow(false)}
          trackOrder={() => trackOrderPage()}
        /> */}
        {udata && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-body">
                  <div className="box">
                    <div className="heading">
                      <h2>Card Information</h2>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Name on Card</label>
                      {localStorage.login && (
                        <input
                          {...register("order_id", {
                            value: id && id,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                      <input
                        {...register("card_name")}
                        type="text"
                        className="form-control"
                        onChange={ (event)=>{
                          setErrors({ ...errors, name:{...errors.name, value: event.target.value}}) 
                        } }
                      />
                      { returnErrorMessage(errors.name.error) }
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Credit Card Number
                      </label>
                      <input
                        size="20"
                        data-stripe="number"
                        {...register("card_number")}
                        onChange={(event)=>{
                          event.target.value = event.target.value.replace(/\D/g, "")
                          setErrors({ ...errors, cardNumber:{...errors.cardNumber, value: event.target.value}}) 
                        }}
                        maxLength="16"
                        type="text"
                        className="form-control"
                      />
                      { returnErrorMessage(errors.cardNumber.error) }
                    </div>
                    <div className="row xsm">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Security Code</label>
                          <input
                            size="4"
                            data-stripe="cvc"
                            {...register("ccv")}
                            onChange={(event)=>{
                              event.target.value = event.target.value.replace(/\D/g, "")
                              setErrors({ ...errors, cvc:{...errors.cvc, value: event.target.value}}) 
                            }}
                            maxLength="4"
                            type="text"
                            className="form-control"
                          />
                          {returnErrorMessage(errors.cvc.error)}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Expiration Date
                          </label>
                          <div className="row">
                            <div className="col-6">
                              <input
                                size="2"
                                data-stripe="exp-month"
                                {...register("e_date_m")}
                                onChange={(event)=>{
                                  event.target.value = event.target.value.replace(/\D/g, "")
                                  setErrors({ ...errors, expirationMonth:{...errors.expirationMonth, value: event.target.value}}) 
                                }}
                                maxLength="2"
                                type="text"
                                className="form-control"
                                placeholder="MM"
                              />
                              { returnErrorMessage(errors.expirationMonth.error) }
                            </div>
                            <div className="col-6">
                              <input
                                size="4"
                                data-stripe="exp-year"
                                {...register("e_date_y")}
                                onChange={(event)=>{
                                  event.target.value = event.target.value.replace(/\D/g, "")
                                  setErrors({ ...errors, expirationYear:{...errors.expirationYear, value: event.target.value}}) 
                                }}
                                maxLength="2"
                                type="text"
                                className="form-control"
                                placeholder="YY"
                              />
                              {returnErrorMessage(errors.expirationYear.error)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="heading">
                      <h2>Billing address</h2>
                    </div>
                    <div className="row xsm">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">First Name</label>
                          <input
                            {...register("fname",

                            {
                              value: user.fname ? user.fname : null,
                            }
                            
                            )}
                            type="text"
                            className="form-control"
                            onChange={(event)=>{
                              setErrors({ ...errors, firstName:{...errors.firstName, value: event.target.value}}) 
                            }}
                          />
                          {returnErrorMessage(errors.firstName.error)}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Last Name</label>
                          <input
                            {...register("lname", {
                              value: user.lname ? user.lname : null,
                            }
                            
                            )}
                            onChange={(event)=>{
                              setErrors({ ...errors, lastName:{...errors.lastName, value: event.target.value}}) 
                            }}
                            type="text"
                            className="form-control"
                          />
                          {returnErrorMessage(errors.lastName.error)}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Gender</label>
                      <div className="app-radio-inline">
                        <label className="app-radio">
                          {" "}
                          Male
                          <input
                            {...register("gender")}
                            type="radio"
                            value= {
                              user  //Check if null 
                              ? (user.gender === "Male" ? 1 : 2) //If not null, check if Male or Female
                              : "1" //Default to 1
                              }
                            defaultChecked
                          />
                          <span></span>
                        </label>
                        <label className="app-radio">
                          {" "}
                          Female
                          <input
                            {...register("gender")}
                            type="radio"
                            value="2"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Billing Address</label>
                      <input
                        {...register("address",
                        
                        {
                          value: user.address ? user.address : null,
                        }
                        
                        )}
                        onChange={(event)=>{
                          setErrors({ ...errors, address:{...errors.address, value: event.target.value}}) 
                        }}
                        type="text"
                        className="form-control"
                      />
                      {returnErrorMessage(errors.address.error)}
                    </div>
                    <div className="form-group">
                      <label className="control-label">City</label>
                      <input
                        {...register("city"
                        , {
                            value: user.city ? user.city : null,
                          }
                        
                        )}
                        type="text"
                        onChange={(event)=>{
                          setErrors({ ...errors, city:{...errors.city, value: event.target.value}}) 
                        }}
                        defaultValue={user.city ? user.city : null}
                        className="form-control"
                      />
                      {returnErrorMessage(errors.city.error)}
                    </div>
                    <div className="row xsm">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">ZIP Code</label>
                          <input
                            {...register("zip"
                            , {
                              value: user.zip ? user.zip : null,
                            }
                            )}
                            onChange={(event)=>{
                              setErrors({ ...errors, zip:{...errors.zip, value: event.target.value}}) 
                            }}
                            type="text"
                            className="form-control"
                          />
                          {returnErrorMessage(errors.zip.error)}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Country</label>
                          <input
                            {...register("country"
                            
                            , {
                              value: user.country ? user.country : null,
                            }
                            
                            )}
                            type="text"
                            onChange={(event)=>{
                              setErrors({ ...errors, country:{...errors.country, value: event.target.value}}) 
                            }}
                            className="form-control"
                          />
                          {returnErrorMessage(errors.country.error)}
                        </div>
                        
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Phone</label>
                      <div className="input-group fixed">
                        <div className="sm">
                          <select className="form-control">
                            <option value="1">SG</option>
                          </select>
                        </div>
                        <input
                          {...register("phone", {
                            value: user.contactNo ? user.contactNo : null,
                          })}
                          onChange={(event)=>{setErrors({ ...errors, phone:{...errors.phone, value: event.target.value}}) }}
                          minLength="11"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      {returnErrorMessage(errors.phone.error)}
                    </div>
                    <div className="form-group">
                      <label className="control-label">Email</label>
                      <input
                        {...register("email", {
                          value: user.email ? user.email : null,
                        })}
                        onChange={(event)=>{
                          setErrors({ ...errors, email:{...errors.email, value: event.target.value}}) 
                        }}
                        type="text"
                        {...(user.email && "readOnly")}
                        className="form-control"
                      />
                      {returnErrorMessage(errors.email.error)}
                      {localStorage.time && (
                        <input
                          {...register("time", {
                            required: true,
                            value: localStorage.time ? localStorage.time : null,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                      {localStorage.date && (
                        <input
                          {...register("date", {
                            required: true,
                            value: localStorage.date ? localStorage.date : null,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                      {localStorage.outlate && (
                        <input
                          {...register("outlate", {
                            required: true,
                            value: localStorage.outlate
                              ? localStorage.outlate
                              : null,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                      {localStorage.d_address && (
                        <input
                          {...register("d_address", {
                            required: true,
                            value: localStorage.d_address
                              ? localStorage.d_address
                              : null,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                      {localStorage.order_type && (
                        <input
                          {...register("order_type", {
                            required: true,
                            value: localStorage.order_type
                              ? localStorage.order_type
                              : null,
                          })}
                          type="hidden"
                          className="form-control"
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <div className="app-radio-list">
                        <label data-testid="TOS" className="app-checkbox is">
                          {" "}
                          I accept the Terms & Conditions and Privacy Policy
                          <input
                            {...register("a_tos")}
                            onChange={(event)=>{setTicked(event.target.checked)}}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <label className="app-checkbox is">
                          {" "}
                          Update me on the occasional offers
                          <input

                            {...register("u_offers")}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-action t2">
                    <button type="submit" className="btn primary" disabled={ticked ? "" : "disabled"}>
                      Place Order
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-none d-md-block details-body">
                  <a href={'/?business_id=' + localStorage.business_id + '&location_id=' + localStorage.location_id} className="close circle">
                    <span></span>
                  </a>
                  <div className="heading">
                    <h2>Order details</h2>
                  </div>
                  <ul className="value-list">
                    <li>
                      <span className="txt">Total items</span>
                      <span className="value">
                        {order?.cart_count
                          ? order.cart_count
                          : local.count && local.count}
                      </span>
                    </li>
                    <li>
                      <span className="txt">Sub total</span>
                      <span className="value">
                        $
                        {order?.cart_sum_wo_tax_price
                          ? order.cart_sum_wo_tax_price
                          : local.sub && local.sub}
                      </span>
                    </li>
                    {(order?.order_type && order.order_type.id === 1) ||
                      orderType === 1 ? (
                      <>
                        <li>
                          <span className="txt">Delivery Charges</span>
                          <span className="value">
                            $
                            {order?.d_charge
                              ? order?.d_charge
                              : deliveryInformation?.deliveryCharge && deliveryInformation?.deliveryCharge}
                          </span>
                        </li>
                        <li>
                          <span className="txt">GST</span>
                          <span className="value">
                            $
                            {order?.gst_charge
                              ? order.gst_charge
                              : local.gst && local.gst}
                          </span>
                        </li>
                        <li className="total">
                          <span className="txt">Order Total:</span>
                          <span className="value">
                            $
                            {local.sum
                              ? (local.sum).toFixed(2)
                              : ( (order?.cart_sum_wo_tax_price
                                  ? parseFloat(order.cart_sum_wo_tax_price)
                                  : 0) +
                                (order?.d_charge
                                  ? parseFloat(order.d_charge)
                                  : 0) +
                                (order?.gst_charge
                                  ? parseFloat(order.gst_charge)
                                  : 0) ).toFixed(2)}
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <span className="txt">GST</span>
                          <span className="value">
                            $
                            {order?.gst_charge
                              ? order?.gst_charge
                              : local.gst && local.gst}
                          </span>
                        </li>
                        <li className="total">
                          <span className="txt">Order Total:</span>
                          <span className="value">
                            $
                            {local.sub
                              ? parseFloat(local.sub) + parseFloat(local.gst)
                              : order?.cart_sum_wo_tax_price
                              ? (parseFloat(order?.cart_sum_wo_tax_price) +
                                parseFloat(order?.gst_charge)).toFixed(2)
                              : 0}
                          </span>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="data">
                    <div className="mb-3">
                      <h3>
                        {" "}
                        {orderType === 1
                          ? "Delivery details"
                          : "Pickup details"}
                      </h3>
                    </div>
                    <div className="mb-3">
                      <p>
                        {/* {orders.d_address ? conAddress(orders.d_address)  : (orderType === 1)  ? conAddress(localStorage.getItem("d_address")) : localStorage.getItem("out_name")} */}

                        {orderType === 1
                          ? localStorage.getItem("d_address")
                          : localStorage.getItem("out_name")}
                        
                      </p>

                      <p>
                      {orderType === 1 ? localStorage.delivery_out_name : ""}
                      </p>

                    </div>
                    <div className="mb-3">
                    { orderType === 1 ? 
                      <span>
                        {currentDate} {currentTime}
                      </span>
                      : ""
                    }
                    </div>
                    <p>
                      {orderType === 2 ?
                      
                        order?.time
                        ? order.time
                        : localStorage.time &&
                        localStorage.time
                      : "" }
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </form>
        )}
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  order: state.products.order,
  payment: state.products.payment,
  user: state.auth.user,
  place_order: state.products.place_order,
  loading: state.products.loading,
  stripeLalaMoveKeys: state.products.stripeLalaMoveKeys
});
export default withTranslation()(
  connect(mapStateToProps, { SingleOrder, submitPayment, clearPayment, clearCart, placeDeliveryOrder, getStripeAndLalamoveKey })(Payment)
);
