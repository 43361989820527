import {
  SEND_REQUEST_USER, 
  CHECK_EMAIL_SUCCESS, 
  SEND_REQUEST_USER_EMAIL, 
  SET_ERROR_EMAIL_USER, 
  CLEAR_USER_STATE,
  USER_REQUEST_SUCCESS, 
  USER_REQUEST_ERROR, 
  CREATE_VERIFICATION_CODE, 
  CONFIRM_VERIFICATION_SUCCESS,
  RESET_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS
} from '../actions/types';

const initialState = {
    loading: false,
    errorMessage: null,
    email: null,
    checkEmailLoading: false,
    verificationCode: null,
    correctCode: null,
    signup: null,
    reset: null,
    updatePassword: null
};
 
export default (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_USER_STATE:
        return {
          ...initialState
        }  

      //---CHECK_EMAIL CASES---//
      case SEND_REQUEST_USER_EMAIL:
        return {
          ...initialState,
          checkEmailLoading: true
        }
      case CHECK_EMAIL_SUCCESS:
        return {
          ...state,
          checkEmailLoading: false,
          email: action.payload
        }
      case SET_ERROR_EMAIL_USER: 
        return {
          ...state,
          checkEmailLoading: false,
          errorMessage: action.payload
        }
      
      //-------------------------//  
      
      //---GENERAL USER CASES---//
      case SEND_REQUEST_USER:
        return {
          ...state,
          loading:true
      }
      case USER_REQUEST_SUCCESS: 
        return {
          ...state,
          loading: false,
          signup: action.payload
        }
      case USER_REQUEST_ERROR:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload
        }
        case UPDATE_USER_PASSWORD_SUCCESS:
          return {
            ...state,
            loading: false,
            updatePassword: action.payload
        }
      // case USER_LOADING:
      //   return {
      //     ...state,
      //     loading: true
      //   };
      // case DONE_LOADING:
      //   return {
      //     ...state,
      //     loading: false
      //   }

      case CREATE_VERIFICATION_CODE:
        return {
          ...state,
          verificationCode: action.payload,
          loading: false
        }
      case CONFIRM_VERIFICATION_SUCCESS:
        return {
          ...state,
          correctCode: action.payload,
          loading: false
        }
      case RESET_USER_PASSWORD_SUCCESS:
        return {
          ...state,
          reset: action.payload,
          loading: false
        }
      //-----------------------//

      default:
        return state;
    }
};