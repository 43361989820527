import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser'; 
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import "./orderItem.css";
import "./button.css";
import "../../assets/css/modal.css";
import "../../assets/css/form.css"
import thumb4 from "../../assets/images/thumb4.png";
// import { addTocart, cartLocal, getProductVariation } from "../../actions/productActions";
import { cartLocal, getProductVariation } from "../../actions/productActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { text } from "dom-helpers";

const ChooseModal = (props) => {
  const [price, setPrice] = useState(0);
  const [variation_set_id, setvariationSetId] = useState([]);
  const [variation, setvariation] = useState([]);
  const [variation_name, setvariationName] = useState([]);
  const [variation_quantity, setvariationQuantity] = useState([]);
  const [variation_price, setvariationPrice] = useState([]);
  const [load, setload] = useState(false);
  const [addCart, setaddCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [productId, setProductId] = useState(0);
  // const [productVariation, setProductVariation] = useState(null);
  // const [productVariationLoad, setProductVariationLoad] = useState(false);
  const [cartData, setCartData] = useState(null);
  const [notifyAtLeast, setNotifyAtLeast] = useState("");
  const [checkFlag, setCheckFlag] = useState("");

  let product = props.product;
  let show = props.show;
 
  
  
  if(product) {
    if ( product.id !== productId ) {
      props.getProductVariation(product.id);
      setProductId(product.id);
      // setProductVariation(null);
      // setProductVariationLoad(false);
      // console.log(product.id);
    }

  }

  //CHANGE OVERFLOW TO HIDDEN
  useEffect(()=>{
    if (props.show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [props.show, props.loading])

  useEffect(()=>{
    console.log("run...")
    renderVariation()
  }, [checkFlag])

  useEffect(() => {
    // if (
    //   load &&
    //   !props.loading &&
    //   props.add_to_cart !== null &&
    //   props.add_to_cart.data &&
    //   Object.keys(props.add_to_cart.data).length > 0
    // ) {
    //   // console.log('_____Choose model update cart_________');
    //   props.updateCart(props.add_to_cart.data);
    //   let data = {
    //     cart_id: props.add_to_cart.data.cart[0].id,
    //   };
    //   props.setOrder(data);
    //   // props.showPickupModal();
    //   setload(false);
    //   props.setLshow(true);

    //   props.closeModal();
    //   setPrice(0);
    //   setQuantity(1);
    //   setvariation([]);
    //   // console.log('_____C_________');
    // }

    

    if (
      load &&
      !props.loading &&
      cartData !== null &&
      Object.keys(cartData).length > 0
    ) {
      console.log('_____Choose model update cart_________');
      props.updateCart(cartData);
      
      
      // props.showPickupModal();
      setload(false);
      props.setLshow(true);

      props.closeModal();
      setPrice(0);
      setQuantity(1);
      setvariation([]);
      setvariationName([]);
      setvariationQuantity([]);
      setvariationSetId([]);
      setvariationPrice([]);
      setNotifyAtLeast("");
      // console.log('_____C_________');
    }

    // console.log(props);
    // localStorage.removeItem("cart");
    // console.log(JSON.parse(localStorage.getItem("cart")));
    // console.log(props);

  }, [addCart, load, props, cartData]);

  const selectVariation = (e, id, name, newprice, setid) => {
    if (e.target.checked) {
      let sprice = price + newprice;
      setPrice(sprice);
      setvariation([...variation, id]);
      setvariationName([...variation_name, name]);
      setvariationQuantity([...variation_quantity, 1]);
      setvariationSetId([...variation_set_id, setid]);
      setvariationPrice([...variation_price, newprice]);
      setCheckFlag(true) //here
    } else {
      let sprice = price - newprice;
      setPrice(sprice);
      var varIndex = variation.indexOf(id);
      variation.splice(varIndex, 1);
      setvariation(variation);

      // var varNameIndex = variation_name.indexOf(name);
      variation_name.splice(varIndex, 1);
      setvariationName(variation_name);

      variation_quantity.splice(varIndex, 1);
      setvariationQuantity(variation_quantity);

      // var varSetIdIndex = variation_set_id.indexOf(setid);
      variation_set_id.splice(varIndex, 1);
      setvariationSetId(variation_set_id);

      variation_price.splice(varIndex, 1)
      setvariationPrice(variation_price)
      setCheckFlag(false) //here
    }
  };

  const renderVariation = () => {

  //  console.log(props.variation);
    let html = [];
    // var variation;

    // Filters the product modifiers with not_for_pick_delivery status !== 1
    if(props.variation != null){
      var filteredVariation = props.variation.filter(function(variation){ return variation.not_for_pickup_delivery !==  1 });
    }

    // variation = filteredVariation;
    

    if (filteredVariation && Object.keys(filteredVariation).length > 0) {
      let data = filteredVariation;

      Object.keys(data).forEach(function (key) {
        let vari = data[key];
        let innerhtml = [];
        let checkedCount = 0;
        const checkedValuesID = { ...variation };
        let at_least_sentence = "";

        Object.keys(checkedValuesID).forEach(function (key){
          Object.keys(vari.value).forEach(function (modiKey) {
            if(checkedValuesID[key] == vari.value[modiKey].id) {
              checkedCount += 1;
            }
          })
        });

        if(vari.order_limit_at_least > 0) {
          at_least_sentence = "(Please choose at least "+ vari.order_limit_at_least +" )";
        }

        Object.keys(vari.value).forEach(function (innerKey) {
          const disabled = (vari.order_limit === 0) ? false : checkedCount >= vari.order_limit;
          let innerVari = vari.value[innerKey];
          
          const filteredByVariationID = Object.fromEntries(
            Object.entries(checkedValuesID).filter(([key, value]) => value === innerVari.id) );
          const filteredByVariationIDLength = Object.keys(filteredByVariationID).length;

          innerhtml.push(
            <tr key={key}>
              <td>
                <label className="app-checkbox">
                  {innerVari.name}
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      selectVariation(e, innerVari.id, innerVari.name, parseFloat(innerVari.sell_price_inc_tax), vari.id, vari.order_limit)
                    }
                    value={innerVari.id}
                    name="variations[]"
                    disabled={!filteredByVariationIDLength && disabled}
                  />
                  <span></span>
                </label>
              </td>
              <td>+${parseFloat(innerVari.sell_price_inc_tax).toFixed(2)}</td>
            </tr>
          );
        });

        html.push(
          <div className="price-table-container">
            <h3>{vari.name} {at_least_sentence} :</h3>
            <table className="table price-table">
              <tbody>
                {innerhtml}
              </tbody>
            </table>
          </div>
        );
      });
    }
    //  console.log(menus);

    return html;
  };

  const addCartLocal = (data) => {
    var b = [];

    b = JSON.parse(localStorage.getItem("cart")) || [];
    b.push(data);
    localStorage.setItem("cart", JSON.stringify(b));

    // props.cartLocal(b);
    setCartData(JSON.parse(localStorage.getItem("cart")));
  };

  // response = await response.map((item, index) => {
  //   return(
  //   {
  //     id:item.id,
  //     type:item.name,
  //     maxSelect:0,
  //     items: item.value.map((innerItem, innerIndex) => {
  //       return(
  //       {
  //         id:innerItem.id, //variation_id
  //         name:innerItem.name,
  //         price: innerItem.default_sell_price,
  //         set_id: item.id,
  //         hide_on_receipt: item.not_for_receipt
  //       });
  //     })
  //   });
  // })

  const addTocarts = () => {
    // console.log('addTocarts');
    var BreakException = {};

    if(props.variation != null){
      var filteredVariationData = props.variation.filter(function(variation){ return variation.not_for_digital_ordering !==  1 });
    }
    console.log(filteredVariationData);

    Object.keys(filteredVariationData).forEach(function (key) {
      let vari = filteredVariationData[key];
      let checkedCount = 0;
      const checkedValuesID = { ...variation };

      Object.keys(checkedValuesID).forEach(function (key){
        Object.keys(vari.value).forEach(function (modiKey) {
          if(checkedValuesID[key] == vari.value[modiKey].id) {
            checkedCount += 1;
          }
        })
      });

      if(vari.order_limit_at_least != 0 && vari.order_limit_at_least > checkedCount) {
        //let booleanAtLeastChoose = false;
        console.log("Please choose more then one")
        setNotifyAtLeast("Please choose at least " +vari.order_limit_at_least+ " in " +vari.name+ ".");
        throw BreakException;
      }
    });

    let cartID;
    if (localStorage.cartID) {
      cartID = parseFloat(localStorage.getItem("cartID")) || [];
      cartID += 1;
      localStorage.setItem("cartID", cartID);
    } else {
      cartID = parseFloat(localStorage.getItem("cartID")) || [];
      cartID = parseFloat(1);
      localStorage.setItem("cartID", cartID);
    }

    
    let data = {
      ...product,
      id: cartID,
      product_id: product.id,
      product_name: product.name,
      product_variation_id: product.variation_id,
      product_price: product.price,
      quantity: quantity,
      variation_id: variation.join(),
      variation_name: variation_name.join(),
      variation_quantity: variation_quantity.join(),
      variation_set_id: variation_set_id.join(),
      price: quantity * (parseFloat(product.price) + price),
      vari_price: price,
      variation_price: variation_price,
      printed: 0
    };
    console.log(data);

    // if (localStorage.login) {
    //   setload(true);
    //   console.log('add to cart');
    //   props.addTocart(data);
    // } else {
    //   setload(true);
    //   addCartLocal(data);
    //   console.log('add to cart local');
    //   props.closeModal();
    //   setPrice(0);
    //   setQuantity(1);
    //   setvariation([]);
    // }
    //  props.showPickupModal();

    // props.addTocart(data);

    setload(true);
    addCartLocal(data);
    props.closeModal();
    setPrice(0);
    setQuantity(1);
    setvariation([]);
    setvariationName([]);
    setvariationQuantity([]);
    setvariationSetId([]);
    setvariationPrice([]);
    setNotifyAtLeast("");
  };
  const changeQty = (status) => {
    // console.log(status);
    let oldQty = quantity;
    if (status) {
      oldQty = oldQty + 1;
    } else {
      oldQty = oldQty - 1;
    }
    if (oldQty > 0) {
      setQuantity(oldQty);
    }
  };
  const closeModel = () => {
    if (!props.loading) {
      setPrice(0);
      setQuantity(1);
      setvariation([]);
      setvariationName([]);
      setvariationQuantity([]);
      setvariationSetId([]);
      setvariationPrice([]);
      setNotifyAtLeast("");
      props.closeModal();
    }
  };

  
  return (
    <Modal
      show={show}
      onHide={closeModel}
      dialogClassName="modal-md xs"
      contentClassName="xs modal_form"
      centered
    >
      <Modal.Header>
        <h4 className="modal-title">Choose your option</h4>
        <button
          type="button"
          className="close"
          onClick={closeModel}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="res-price-box">
          <figure>
            <img src={product.image} alt="" />
          </figure>
          <div className="cnts">
            <div className="head">
              <h3>{product.name}</h3>
            </div>
            <div className="dec" style={{marginTop: 5, marginBottom: 10}}>
              {/* {Converting HTML strings to React Component} */}
              {product.product_description != null ? ReactHtmlParser(product.product_description) : null}
            </div>
            <div className="price" style={{marginLeft:"0px"}}>
              From <span data-testid={`${product.id}_price`}>$ {parseFloat(product.price).toFixed(2)}</span>
            </div>
            {/* <div className="action">
              <span className="btn hint">Only available on Weekend</span>
            </div> */}
          </div>
        </div>
        {renderVariation()}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 col-5">
              <div className="num-action">
                <span className="btn" onClick={() => changeQty(false)}>
                  -
                </span>
                <span className="value">{quantity}</span>
                <span onClick={() => changeQty(true)} className="btn">
                  +
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <button
                data-toggle="modal"
                data-target="#cartModal"
                className="btn primary"
                onClick={addTocarts}
                data-testid={`${product.id}_add`}
              >
                <small>Add</small> ${" "}
                {(quantity * (parseFloat(product.price) + price)).toFixed(2)}
              </button>
              <span style={{ color: "red" }}>{notifyAtLeast}</span>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ChooseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showPickupModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  add_to_cart: state.products.add_to_cart,
  loading: state.products.loading,
  variation: state.products.variation
});
export default withTranslation()(
  // connect(mapStateToProps, { addTocart, cartLocal, getProductVariation })(ChooseModal)
  connect(mapStateToProps, { cartLocal, getProductVariation })(ChooseModal)
);
