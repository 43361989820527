export const getSelectedOutlet = (id, data) => { //RETURN DELIVERY CHARGE FOR SPECIFIC OUTLET VIA ID
    for (let i = 0; i < data.length; ++i){
      if (id === data[i].id) {
        return data[i].charges
      }
    }
}

export const checkIfRestDay = (outlet) => { //RETURN FALSE IF REST DAY IS NULL, RETURN ACCORDINGLY(BASED ON TODAY'S DAY) IF ITS NOT
  if (outlet?.custom_field2) {
    let outletRestDay = JSON.parse(outlet.custom_field2)

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
    
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const userDate = new Date((new Date).toLocaleString('en-US', {timeZone: userTimeZone}))
    if (outletRestDay[0] === days[userDate.getDay()-1]) {
      return true
    } else {
      return false
    } 

  } else {
    return false
  }
}