import React from 'react';
import HeaderMob from '../HeaderMob/HeaderMob';
function TableNo(props){
    return(
        <div>
        <HeaderMob table={props.table}/>
        </div>

    )
}

export default TableNo;