import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as serviceWorker from "./serviceWorker";
import setAuthToken from "./utils/setAuthToken";
import axios from "axios";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Toast } from "react-bootstrap";
import { setLoginErrorMessage } from "./actions/authActions";
import store from "./store";
import { USER_REQUEST_ERROR } from "./actions/types";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  async config => {

    var dateTimeNow = new Date();
    var token_expired_at = '';

    if(localStorage.headers) {
      if(JSON.parse(localStorage.headers)?.data?.token_expired_at) {
        token_expired_at = JSON.parse(localStorage.headers)?.data?.token_expired_at;
        token_expired_at = new Date(token_expired_at);
      }
    }

    config.headers = { 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem("headers")) && JSON.parse(localStorage.getItem("headers")).data.token}`
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }

    // token expired, logout user
    if(localStorage.login && token_expired_at !== '' && dateTimeNow > token_expired_at) {
      alert('Token expired! Redirect to login page.');
      var newHeaders = JSON.parse(localStorage.headers);
      newHeaders.data.token_expired_at = '';
      
      localStorage.setItem('headers', JSON.stringify(newHeaders));

      axios
        .get('/auth/customer_logout')
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });

      localStorage.clear();
      window.location.reload();
    }
    
    return config;
  },
  error => {
    Promise.reject(error)
});

axios.interceptors.response.use(undefined, error => {
  console.log("------------------------RESPONSE INTERCEPTED----------------------------");
  console.log(error.response)

//* CHANGE LOGIC LATER *//
  //POSSIBLE USER CREDENTIAL ERRORS
  const userErrorMessage = [
    "Email is not verified",
    "Email is terminated",
    "Unauthorized"
  ]

  //USED TO CHECK IF THE ERROR MESSAGES ARE RELATED TO THE USER LOGIN
  let errorUserCredentials = false

  for(let i = 0; i < userErrorMessage.length; ++i) {
    if (userErrorMessage[i] === error.response.data.message){
      errorUserCredentials = true
    }
  }

  //DISPATCH AN ACTION TO THE STORE TO UPDATE THE ERRORS PROPERTY IN THE REDUX STORE
  if (errorUserCredentials) {
    store.dispatch(setLoginErrorMessage(error.response))
  }

  

  if(error.message === 'Network Error' && ! error.response) {
    Toast.error('Network error - make sure API is running');
  }

  const {status, data, config} = error.response;
  

  


  if(status === 401) {
    console.log("hello");
    Toast.error(data.message);
  }

  if(status === 404) {
    console.log(error.response.config)
    console.log("Page no found.");
    
    const err = error.response.config

    if (err) {
      let numberIndex = 0
      for (let i = 0; i < err.url.length; ++i) { //FOR LOOP TO FIND THE PART OF THE STRING WHICH IS THE CODE
        if (!isNaN(err.url[i])){ //CHECKS FOR IF THE CHARACTER IS A NUMBER
          numberIndex = i
          break
        }
      }
      const url = err.url.substr(0, numberIndex-1 ) //numberIndex-1 TO REMOVE TRAILING SLASH IN THE PATH
      
      if (url === "/password/find") {
        store.dispatch({
          type:USER_REQUEST_ERROR,
          payload:error.response
        })
      }
    }

    


  }

  if(status === 400 && config.method === 'get' && data.erros.hasOwnProperty('id')) {
    console.log("Page no found.");
  }

  if(status === 500) {
    Toast.error('Server error - check the terminal for more info!');
  }
})

// console.log(localStorage.headers);

if (localStorage.headers) {
  const accessToken = `Bearer ${
    JSON.parse(localStorage.getItem("headers")) &&
    JSON.parse(localStorage.getItem("headers")).data.token
  }`;
  setAuthToken(accessToken);
}

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

noop();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
