import React from 'react';
import Modal from 'react-bootstrap/Modal';
import close from "../../assets/svgs/close.svg";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../../assets/css/modal.css";
import PropTypes from "prop-types";
import "../../assets/css/component.css";
import { set, useForm } from "react-hook-form";
import { Col } from 'react-bootstrap';
import PopUp from './Popup';
import { turnOffButton, turnOnButton } from "./buttonFunctions"
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {updatePassword} from '../../actions/userActions';
import { useSelector } from 'react-redux';


const ChangePassword = (props) => {
    const { register, reset } = useForm()
    const [password_current, setCurrPassword] = useState("") 
    const [password_new, setNewPassword] = useState("")
    const [password_confirm, setCfmPassword] = useState("")
    const [popupTxt, setPopupTxt] = useState("")
    const [lshow, setLshow] = useState(false);
    const [textArray, setTextArray] = useState(Array(props.maxLength))

    const buttonElement = useRef(null)
    if (localStorage.headers) {
      var login = JSON.parse(window.localStorage.getItem('headers'))
      var userid = login.data.user.id
      var usertoken = login.data.token;
    }
    

    useEffect(()=>{

        turnOffButton(buttonElement.current);

        
  
        if (password_current === "" || password_new === "" || password_confirm === "") {
            turnOffButton(buttonElement.current);
        }

        else if (password_new === password_confirm) {

            turnOnButton(buttonElement.current);
        
        } 
    }, [password_new, password_confirm, password_current])

    useEffect(()=>{
      if (props.user.updatePassword) {
        const response = props.user.updatePassword.data
        if(response.message === "Current password does not match!"){
          setPopupTxt("Current password does not match!");
          setLshow(true);
        } else if(response.message === "Password successfully changed!"){
          setCurrPassword("");
          setNewPassword("");
          setCfmPassword("");
          setPopupTxt("Password is successfully updated!!!");
          setLshow(true);
          reset();
          props.closeModal();
        } else if (response.message === "Please use the different password!"){
          setPopupTxt("Please use different password!!!");
          setLshow(true);
        }
      }

    }, [props.user.updatePassword])

    function handleSubmit(event) {
      event.preventDefault();
  
      const data = {
      id: userid,
      current_password: password_current,
      password: password_new,
      };

      props.updatePassword(data)

    // axios.post('/auth/change_customer_password', data)
    //   .then(response => {
    //     // setPopupTxt("Password is successfully updated!!!");
    //     // setLshow(true);
    //     console.log("Status: ", response.status);
    //     console.log("Data: ", response.data);

    //     if(response.data.message === "Current password does not match!"){
    //       setPopupTxt("Current password does not match!");
    //       setLshow(true);
    //     } else if(response.data.message === "Password successfully changed!"){
    //       setCurrPassword("");
    //       setNewPassword("");
    //       setCfmPassword("");
    //       setPopupTxt("Password is successfully updated!!!");
    //       setLshow(true);
    //       reset();
    //       props.closeModal();
    //     } else if (response.data.message === "Please use the different password!"){
    //       setPopupTxt("Please use different password!!!");
    //       setLshow(true);
    //     }

    //   }).catch(error => {
    //     console.log('Something went wrong!', error);
    //   });
      
    }



    // console.log(props.onHide)
    return (
      <>
        <PopUp
        text={popupTxt}
        show={lshow}
        closeModal={() => setLshow(false)}
        />

        <Modal
        show={props.show}
        onHide={props.closeModal}
        dialogClassName="modal-md md"
        contentClassName="md"
        centered
        >
        <form onSubmit={handleSubmit}>
          <Modal.Body className=" px-5 py-4">
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={props.closeModal}
              >
              <img src={close} width="20" alt="close" />
              </button>
              <div style={{marginBottom:'5%'}} className="text-center mt-5">
                <div className="back-heading">Change password</div>
                <div className="mt-5">
                  <div className="input-title mb-3">Current password</div>
                  <div className="mb-2">
                    <input
                      type="password"
                      className="form-control-lg input input-full"
                      minLength="7"
                      {...register("CurrentPassword")}
                      required
                      autoComplete="off"
                      onChange={(event)=>{setCurrPassword(event.target.value)}}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <div className="input-title mb-3">New password</div>
                  <div className="mb-2">
                    <input
                      type="password"
                      minLength="7"
                      className="form-control-lg input input-full"
                      required
                      {...register("NewPassword")}
                      autoComplete="off"
                      onChange={(event)=>{setNewPassword(event.target.value)}}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="input-title mb-3">Confirm password</div>
                  <div className="mb-2">
                    <input
                      type="password"
                      className="form-control-lg input input-full"
                      minLength="7"
                      required
                      {...register("ConfirmPassword")}
                      autoComplete="off"
                      onChange={(event)=>{setCfmPassword(event.target.value)}}
                    />
                  </div>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer no">
            <button
              className="btn btn-block btn-danger font-weight-bold py-3 rounded f18"
            type="submit" ref={buttonElement} disabled style={{opacity:0.5}}
            >
              {" "}
              Submit
              <img src="images/log-out.svg" className="ml-2" alt="" />
            </button>
            <div className="powered my-4 w-100">Powered by Warely</div>
          </Modal.Footer>
        </form>    
      </Modal>
      </>
    )
}

ChangePassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user
})

export default withTranslation()(
  connect(mapStateToProps, {
    updatePassword
  })(ChangePassword)
);