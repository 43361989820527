import React from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import RightSidebar from "../../components/Sidebar/Right-sidebar";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Spinner from "../../common/Spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getBusinessLocation } from "../../actions/productActions";
import { useHistory, useLocation } from "react-router"

const DashboardLayout = (props) => {
 // console.log(props);
  
  const [showRight, setShowRight] = useState(false);
  const [showMobileHeader, setShowMobileHeader] = useState(false);
  const [cartData, setCartData] = useState(null);
  const showMobile = (value) => {
    setShowMobileHeader(value);
  };

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)    
  console.log(location)
  


  const business_id = searchParams.get("business_id")
  const location_id = searchParams.get("location_id")

  useEffect(()=>{
    if (props.loading || props.resetPasswordLoading) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [props.loading, props.resetPasswordLoading])

  useEffect(()=>{
    if (localStorage.getItem("location_id")) {
      props.getBusinessLocation()
    }
    
  },[])
  
 
  return (
    <>
      {(props.loading || props.resetPasswordLoading || props.pageLoading) && <Spinner/>}
      
      <div className="main-body">
        {/* <Sidebar path={props.path} showMobile={() => showMobile(true)} /> */}

        <Col className="right-body">
          <Header
          name={props.name}
          showRightBar={() => setShowRight(true)}
          showMobileHeader={showMobileHeader}
          showMobile={() => showMobile(false)}
          updateCart={props.updateCart}
          // updateCart={JSON.parse(localStorage.getItem("cart"))}
          loginRef={props.loginRef}
          regRef={props.regRef}
          // cartData={props.cartData}
          // cartData={JSON.parse(localStorage.getItem("cart"))}
          />
         {props.children}
        </Col>
      </div>
      <RightSidebar
        show={showRight}
        // cartData={props.cartData}
        // updateCart={props.updateCart}
        cartData={JSON.parse(localStorage.getItem("cart"))}
        updateCart={JSON.parse(localStorage.getItem("cart"))}
        loginRef={props.loginRef}
        regRef={props.regRef}
        closeRightbar={() => setShowRight(false)}
      />
    </>
  );
};


const mapStateToProps = (state) => ({
  loading: state.products.loading,
  resetPasswordLoading: state.user.loading,
  business_location: state.products.business_location,
  pageLoading: state.page.loading
});
export default withTranslation()(
  connect(mapStateToProps, {
    getBusinessLocation
  })(DashboardLayout)
);