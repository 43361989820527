import { Modal } from "react-bootstrap"
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getOutletInformation } from "../../actions/autocompleteActions";
import { getOutlates, getStatusDeliveryStore } from "../../actions/productActions";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Spinner, Toast } from "react-bootstrap";
import { getSelectedOutlet, checkIfRestDay } from "./outletFunctions";
import "../../assets/css/form.css"


const OutletInfo = (props) => {
  
  const [numberOfOutlets, setNumberOfOutlets] = useState(0)
  const [statusOutlet, setStatusOutlet] = useState(false)
  const [statusPopUp, setStatusPopUp] = useState(false)

  const defaultData = {
    id: 0,
    name: ""
  }
  const [currentOutlet, selectOutlet] = useState({ //CURRENT OUTLET DATA USED TO KEEP TRACK WHEN USER CLICKS ON OUTLET
    ...defaultData
  })

  // const getSelectedOutlet = (id) => { //RETURN DELIVERY CHARGE FOR SPECIFIC OUTLET VIA ID
  //   for (let i = 0; i < props?.postal?.outlet?.data.length; ++i){
  //     if (id === props?.postal?.outlet?.data[i].id) {
  //       return props?.postal?.outlet?.data[i].charges
  //     }
  //   }
  // }

  const changeDeliveryCharge = (outlet) => { //UPDATE DELIVERY FEES AND RESPECTIVE OUTLET INFORMATION
    
    if (outlet) {
      props.setDeliveryInformation({ //SET OUTLET INFORMATION USING THE SET STATE FUNCTION(SETOUTLET) FROM RIGHT-SIDEBAR.JS SO IT RENDERS THE NEW VALUE
        ...props.deliveryInformation,
        deliveryOutName: outlet.name,
        deliveryOutLat: outlet.lat,
        deliveryOutLong: outlet.lng,
        deliveryOutAddress: outlet.address,
        deliveryOutId: outlet.id,
        deliveryCharge: outlet.charges?.totalFee,
        deliveryCurrency: outlet.charges?.totalFeeCurrency
      })
    }
  }

  useEffect(()=>{
    setStatusOutlet(props.outlet_status.result)
    console.log(props.outlet_status.result)
    if(props.outlet_status.result){
      props.closeOutlet()
    } else {
      if(props.outlet_status.result != undefined)
      {
        setStatusPopUp(true);
      }
    }
  }, [props.outlet_status])

  const hideStatusOutletModal = () => {
    console.log(statusPopUp)
    setStatusPopUp(false);
  };

  useEffect(()=>{
    // if(props.outlet_status !){
      if ( props?.postal?.outlet?.data[0]?.is_open && !checkIfRestDay(props?.postal?.outlet?.data[0]?.is_open)) {
        console.log(props?.postal?.outlet?.data[0])
        selectOutlet({...props.postal?.outlet?.data[0]}) //AUTOMATICALLY DEFAULT TO THE SMALLEST DELIVERY CHARGE
        // props.setDeliveryInformation({ //SET OUTLET INFORMATION USING THE SET STATE FUNCTION(SETOUTLET) FROM RIGHT-SIDEBAR.JS SO IT RENDERS THE NEW VALUE
        //   ...props.deliveryInformation,
        //   deliveryOutName: props.postal?.outlet?.data[0]?.name,
        //   deliveryOutId: props.postal?.outlet?.data[0]?.id,
        //   deliveryCharge: props.postal.outlet?.data[0]?.charges?.totalFee
        // })
        changeDeliveryCharge(props.postal?.outlet?.data[0])
      }

      let numOfOut = 0 //RESET OUTLET NUMBER EVERYTIME OUTLET CHANGES
      props.postal.outlet?.data?.forEach((outlet)=>{
        console.log(outlet)
        if(outlet.is_open && !checkIfRestDay(outlet)) {
          numOfOut += 1
          setNumberOfOutlets(numOfOut)
        }
      })
    // }
    
  }, [props.postal.outlet]) //USED TO SEE IF USER HAS CHANGED HIS/HER ADDRESS. UPDATE THE DELIVERY CHARGE WITH THE NEW ONE BASED ON CURRENT SELECTED OUTLET

  const renderOutlets = () => {
      
    const outletData = props?.postal?.outlet?.data


    if (outletData) {
      const html = [] //LIST OF ITEMS(OUTLET INFORMATION) TO RENDER
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const today = (new Date()).getDay()
      
      console.log(outletData)
      Object.keys(outletData).map((key, index) => {
        if (outletData[index]?.custom_field3) {
          const selectedOutlet = getSelectedOutlet(outletData[key].id, props.postal.outlet.data)
          html.push(
              <div
                key={index}
                style={(!outletData[index].is_open || checkIfRestDay(outletData[index])) ? {
                  pointerEvents: 'none',
                  'cursor': 'not-allowed',
                  'opacity': '0.4'
                } : {
                  pointerEvents: 'auto',
                  'cursor': 'pointer',
                  'opacity': '1'
                }}
                className={
                  currentOutlet.id === outletData[index].id ? "box fill" : "box outline"
                }
                onClick={(e) =>{

                  //OUTLET OBJECT FROM RIGHT-SIDEBAR LOOKS LIKE 
                  // {
                  //   ...existing_properties,
                  //   deliveryOutName,
                  //   deliveryOutId,
                  //   deliveryCharge
                  // }

                  changeDeliveryCharge(outletData[index])
                  

                  selectOutlet({
                    ...outletData[index],
                    id: outletData[index].id, 
                    name: outletData[index].name
                  })
                }}
              >
                <div className="ins">
                  <ul>
                    <li>
                      {/* <i className="icon-5"></i> */}
                      <span className="txt title">{outletData[key].name}</span>
                    </li>
                    <li>
                      {/* <i className="icon-6"></i> */}
                      <span className="txt">
                        {outletData[key].address}
                      </span>
                    </li>
                    { outletData[key]?.custom_field2 ?
                      <li>
                        <span className="name">Rest day :</span>
                        <span className="txt bold" style={{ 'marginLeft': '10px' }}>  {JSON.parse(outletData[key]?.custom_field2).join(", ")}</span>
                      </li>
                      : ""
                    }
                    <li>
                      <span className="name">Hours :</span>
                      <span className="txt">
                        {" "}
                        {/* <span className="open">Open</span> {outletData[key].start} - {outletData[key].end} */}
                        
                        {/* CHECK IF IS_OPEN OR IF TODAY IS THE REST DAY */}
                        <span className={outletData[key].is_open && !checkIfRestDay(outletData[key]) ? 'open' : 'outletClosed'}>{outletData[key].is_open && !checkIfRestDay(outletData[key]) ? 'Open' : 'Close'}</span> {outletData[key].custom_field1}
                      </span>
                    </li>
                    <li>
                      <span className="name">Phone :</span>
                      <span className="txt bold">  {outletData[key].phone}</span>
                    </li>
                    <li>
                      { outletData[key].is_open && !checkIfRestDay(outletData[key]) // Check if outlet is open, if yes show delivery charge and check if the user has entered an address. If outlet is not open, dont show delivery charge
                        ? 
                        ( <>
                        <span className="name">Delivery charge :</span>
                        {/* {   

                        !props.postal.postalCodes.error ? // Change text to please select address if address has not been filled up
                          props.postal.loading  // Change text to processing if postalcode reducer is loading
                          ? <span className="txt bold"  style={{ 'marginLeft': '10px' }}>Processing</span> : <span className="txt bold"  style={{ 'marginLeft': '10px' }}>  {selectedOutlet?.totalFee} {selectedOutlet?.totalFeeCurrency}</span>
                          : <span className="txt bold"  style={{ 'marginLeft': '10px' }}> Please select address </span>
                        } */}

                        <span className="txt bold"  style={{ 'marginLeft': '10px' }} data-testid="delivery_charge">  {selectedOutlet?.totalFee} {selectedOutlet?.totalFeeCurrency}</span>
                        </> ) : ""
                      }   
                    </li>
                  </ul>
                </div>
              </div>
            )
          }
      });

      return html
    }        
    }
    return (
      <div>
      <Modal
        show={props.showOutlet}
        onHide={props.closeOutlet}
        className="rounded-lg"
        dialogClassName="modal-md xs"
        contentClassName="pickup-modal xs modal_form"
        centered
        backdrop="static"
      >
        <Modal.Header className="no">
          <h4 className="modal-title">Outlets</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.closeOutlet}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="no">
          <div className="scroll">
            <div className="box data">
              <div className="ins">
                <span>{props.postal?.loading ? 0 : numberOfOutlets} Store(s) Available </span>
                {/* <a href={null}>View on Map</a> */}


              </div>
            </div>

          </div>
          {props.postal.loading ? <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}> <Spinner animation="border" /> </div> : props.postal.outlet ? renderOutlets() : ""}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: 0 }} className="modal-footer form-action">
          <button
            type="button"
            className="btn lg primary d-block"
            style={(numberOfOutlets === 0 || props.postal.loading || currentOutlet.id === 0) ?
              {
                pointerEvents: "none",
                opacity: 0.5
              } : {
                pointerEvents: "auto",
                opacity: 1.0
              }}
            onClick={() => {
              props.getStatusDeliveryStore({
                "location_id": currentOutlet.id,
              });
            } }
          >
            Proceed
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={statusPopUp}
        onHide={hideStatusOutletModal}
        className="rounded-lg"
        dialogClassName="modal-md xs"
        contentClassName="pickup-modal xs modal_form status_delivery_modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="no">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hideStatusOutletModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="no">
          <div>
            <div className="box data">
              <div className="ins">
                <span>This store is not available now. </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    )
}

const mapStateToProps = (state) => ({
    postal: state.postal,
    outlets: state.products.outlates,
    outlet_status: state.products.outlet_status
  });
  export default withTranslation()(
    // connect(mapStateToProps, { clearCartSingle, clearCart, placeOrder, getTaxes, submitOrder, updateOrder })(
    connect(mapStateToProps, { getOutletInformation, getOutlates, getStatusDeliveryStore })(
      OutletInfo
    )
  );